import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Tmp from 'assets/image/tmp.png';

export default function BlogElement({
    blogNum, title, date, author, description, img,
}) {
    const navigate = useNavigate();

    return (
        <div
            className="flex-none w-full h-[296px] bg-text-bg-600 p-5
            rounded-[15px] flex flex-col cursor-pointer"
            aria-hidden="true"
            onClick={() => { navigate(`/blog/${blogNum}`); }}
        >
            <img alt="temp" src={img} className="h-[200px] bg-white rounded-[15px] object-cover" />
            <p className="mt-[10px] text-sm font-bold">{title}</p>
            <div className="flex justify-between mt-[10px]">
                <p className="text-[10px] font-bold text-text-bg-300">
                    {date.toLocaleDateString('en-us', {
                        month: 'short',
                        year: 'numeric',
                        day: 'numeric',
                    })}

                </p>
                <p className="text-[10px] font-bold text-text-bg-300">{author}</p>
            </div>
        </div>
    );
}
