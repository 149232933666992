import PropTypes from 'prop-types';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

import SpotifyImg from 'assets/image/chuyenZ.jpg';
import SpotifySmallLogo from 'assets/image/Spotify_Small_Logo.svg';

export default function HomepagePodcastOverlay({
    audioSrc, closeOverlay, title, description, url,
}) {
    return (
        <div
            aria-hidden
            className="flex-none w-[260px] md:w-[360px] lg:w-[480px] 2xl:w-[580px] md:h-[320px] lg:h-[400px] 2xl:h-[460px] rounded-[20px] relative cursor-pointer bg-podcastDemo"
            onClick={closeOverlay}
        >
            <div className="px-[10px] md:px-3 lg:px-2 py-3 lg:py-2 h-full backdrop-blur-xl bg-podcast rounded-[20px]">
                <div className="flex">
                    <img className="w-[110px] lg:w-[160px] 2xl:w-[240px] h-[122px] md:h-[160px] lg:h-[280px] 3xl:h-[362px] rounded-[20px] object-cover mr-5" src={SpotifyImg} alt="Podcast" />
                    <div className="flex flex-col">
                        <p className="text-sm max-w-[280px] md:text-lg lg:text-2xl font-bold lg:leading-[36px] mb-2 lg:mb-[10px] truncate">{title}</p>
                        <p className="text-sm hidden md:block lg:text-base font-medium italic text-text-bg-100 mb-2 lg:mb-[10px] max-w-[280px]">TEDxHCMUSSH/ Chuyện Z</p>
                        <p className="text-sm  max-w-[280px] line-clamp-5 max-h-[120px] lg:text-base font-medium text-text-bg-100 mb-2 lg:mb-[10px] ">{description}</p>
                        <a href={`https://open.spotify.com/episode/${url}`} className="flex items-center justify-center rounded-[48px] w-[110px] md:w-[132px] lg:w-[155px] h-[30px] md:h-[36px] lg:h-[48px] bg-black bg-opacity-40">
                            <p className="text-xs md:text-base font-bold md:font-medium">Nghe Tiếp</p>
                            <img className="ml-[10px] w-6 h-6" src={SpotifySmallLogo} alt="Podcast" />
                        </a>
                    </div>
                </div>
                <ReactAudioPlayer
                    className="w-[240px] md:w-[320px] lg:w-[460px] 2xl:w-[500px] mt-[10px] h-8 md:h-[48px]"
                    src={audioSrc}
                    controls
                />
            </div>
        </div>
    );
}
HomepagePodcastOverlay.propTypes = {
    audioSrc: PropTypes.string.isRequired,
    closeOverlay: PropTypes.func,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};
HomepagePodcastOverlay.defaultProps = {
    closeOverlay: () => {},
};
