import PropTypes from 'prop-types';
import React from 'react';

import MenuIcon from 'assets/icons/menu.svg';
import MenuExitIcon from 'assets/icons/menu_exit.svg';

export default function NavbarMobileMenu({ menuOpen, toggleMenu }) {
    return (
        <div className="z-50 xl:hidden space-x-10 flex items-center aspect-square">
            <button
                className="cursor-pointer self-center flex justify-center items-center border-solid border-2 border-blue-500 rounded-full w-6 md:w-10 aspect-square p-[4px] md:p-[6px] text-white"
                onClick={toggleMenu}
                type="button"
            >
                {menuOpen ? <img className="w-full h-full" src={MenuExitIcon} alt="MenuExitIcon" /> : <img className="w-full h-full" src={MenuIcon} alt="MenuIcon" />}
            </button>
        </div>
    );
}

NavbarMobileMenu.propTypes = {
    menuOpen: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
};

NavbarMobileMenu.defaultProps = {
    menuOpen: false,
};
