/* eslint-disable max-len */
import blogBCLA from '../../../assets/image/blogBCLA.jpg';
import blogBVCN from '../../../assets/image/blogBVCN.jpg';
import blogDGGD from '../../../assets/image/blogDGGD.png';
import blogNVNT from '../../../assets/image/blogNVNT.png';
import blogQHTN from '../../../assets/image/blogQHTN.png';
import blogXHLT from '../../../assets/image/blogXHLT.png';

import blog1 from './blog/1.md';
import blog10 from './blog/10.md';
import blog11 from './blog/11.md';
import blog12 from './blog/12.md';
import blog13 from './blog/13.md';
import blog14 from './blog/14.md';
import blog15 from './blog/15.md';
import blog16 from './blog/16.md';
import blog17 from './blog/17.md';
import blog2 from './blog/2.md';
import blog3 from './blog/3.md';
import blog4 from './blog/4.md';
import blog5 from './blog/5.md';
import blog6 from './blog/6.md';
import blog7 from './blog/7.md';
import blog8 from './blog/8.md';
import blog9 from './blog/9.md';

export const VideoContent = [
    {
        genre: 'Cộp Mác',
        genreId: 'copmac',
        title: 'Cân Bằng Từ Bên Trong: Bàn Về Chánh Niệm',
        date: new Date('2019-02-11'),
        author: 'Bởi TedXUSSH',
        src: 'a7--cJD7k0Q',
    },
    {
        genre: 'Cộp Mác',
        genreId: 'copmac',
        title: 'Đánh Giá Trong Giáo Dục: Khi Kiểm Tra Không Còn Tối Ưu',
        date: new Date('2019-03-17'),
        author: 'Bởi TedXUSSH',
        src: 'Pj6_UkhfRB4',
    },
    {
        genre: 'Cộp Mác',
        genreId: 'copmac',
        title: 'GIỚI THIỆU ĐẠI SỨ CHƯƠNG TRÌNH',
        date: new Date('2019-03-18'),
        author: 'Bởi TedXUSSH',
        src: 'Na7wqlXcVdg',
    },
    {
        genre: 'Cộp Mác',
        genreId: 'copmac',
        title: 'Bạn Chọn Là Ai Trong Trách Nhiệm Đóng Góp Vì Xã Hội?',
        date: new Date('2019-03-31'),
        author: 'Bởi TedXUSSH',
        src: 'vsMKk5P0vVs',
    },
    {
        genre: 'Cộp Mác',
        genreId: 'copmac',
        title: 'Utopia: Các kiến thức kiến tạo xã hội lý tưởng',
        date: new Date('2019-05-23'),
        author: 'Bởi TedXUSSH',
        src: '_l079QZ7jek',
    },
];
export const BlogContent = [
    {
        genre: 'Cộp Mác',
        genreId: 'copmac',
        blogNum: 1,
        blog: blog1,
        title: 'Giới trẻ “hồi sinh” Việt phục: tương lai sáng lên từ quá khứ!',
        date: new Date('2022-07-10'),
        author: 'Bởi TEDxUSSH',
        description: 'Mặc dù những nỗ lực nghiên cứu, phục dựng trang phục cổ và truyền thống Việt Nam đã xuất hiện từ khá lâu, nhưng vài năm gần đây mới nở rộ mạnh mẽ, thu hút nhiều người tham gia, đặc biệt nhất là giới trẻ. Chính họ cũng đã thắp lên một thứ ánh sáng mới cho tương lai của Việt phục, của nền văn hóa nước ta.',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 2,
        blog: blog2,
        title: 'LIỆU QUY TẮC ỨNG XỬ MỚI SẼ LÀM NGƯỜI SƯU TẦM TRỞ NÊN CÓ ĐẠO ĐỨC HƠN?',
        date: new Date('2022-07-10'),
        author: 'Bởi The Art Newspaper',
        description: 'Trong bất kì ngành công nghiệp nào, những thứ bạn mua sẽ thuộc về bạn và bạn có thể sử dụng nó theo ý muốn. Nếu bạn muốn trang trí một ngôi nhà mới, chiếc xe hơi hoặc máy rửa bát bằng những bức vẽ bằng đèn neon, đừng do dự mà hãy làm những gì bạn thích. Điều này có thể làm ảnh hưởng tới giá trị bán lại của chúng, nhưng nó cũng không ngăn cản được việc bạn sẽ mua một ngôi nhà khác, một chiếc xe khác hay một cái máy rửa bát khác trong tương lai.',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 3,
        blog: blog3,
        title: '“Niềm vui của sự lạc lối và tự tìm lấy hướng đi cho chính mình”',
        date: new Date('2022-07-10'),
        author: 'Bởi Alex Williams,',
        description: 'Cũng như bao người khác, tôi luôn sử dụng Google Calendar để nhắc nhở về những sự kiện trong ngày. Hay Google Maps để chỉ đường, và để tránh cho bản thân không bị trễ nải,Google thông báo luôn sát cánh bên mình.',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 4,
        blog: blog4,
        title: 'JOMO - Khi bỏ lỡ lại là hạnh phúc',
        date: new Date('2022-07-10'),
        author: 'Bởi TEDxUSSH',
        description: 'Sau một ngày làm việc mệt mỏi, đứng giữa việc trở về nhà nghỉ ngơi và có một buổi tụ tập với bạn bè, thứ bạn chọn luôn là điều thứ hai. Cũng không hẳn lần nào sự lựa chọn đó cũng là do bạn thật sự mong muốn. Có đôi khi, sự lựa chọn đó là vì bạn không muốn bản thân bị bỏ lỡ. Trong bạn luôn tồn tại suy nghĩ, mình sẽ lỡ mất những trò vui, lỡ mất những điều chỉ xảy ra vào thời khắc đó và rồi, bạn sẽ vô tình tách biệt với mọi người. Bạn chạy theo những cuộc tán gẫu, những tít báo cập nhật thường xuyên, làm những điều bạn bè đang làm, biết những điều bạn bè biết… chỉ với mong muốn sẽ theo kịp số đông.',
    },
    {
        genre: 'Cộp Mác',
        genreId: 'copmac',
        blogNum: 5,
        blog: blog5,
        title: 'Hiểu về FOMO – đâu chỉ có những điều tiêu cực',
        date: new Date('2022-07-10'),
        author: 'Bởi TEDxUSSH',
        description: 'Bài viết tiếp thêm động lực cho các FOMO sapiens – những người luôn trong tình trạng ám ảnh vì sợ bị bỏ lỡ… FOMO là từ viết tắt của Fear Of Missing Out, hiểu nôm na sang tiếng Việt có nghĩa là sợ bị bỏ rơi, mất cơ hội. Những người khi mắc phải hội chứng FOMO thường sợ rằng người khác luôn vui vẻ và đầy đủ hơn mình. FOMO đặc biệt phổ biến ở những người từ 18 đến 33 tuổi. ',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 6,
        blog: blog6,
        title: '“Liệu rong biển có thể giải quyết cuộc khủng hoảng rác thải nhựa trên thế giới?”',
        date: new Date('2022-07-10'),
        author: 'Bởi CNN',
        description: 'Hãy ăn gói sốt tương cà ngay khi bạn vừa hoàn thành món khoai tây chiên khoái khẩu của mình. Hãy bỏ cả túi đựng mì vào nồi ngay khi bạn cho mì ống vào nước sôi.',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 7,
        blog: blog7,
        title: '“Âm nhạc tốt cho tâm trạng của chúng ta như thế nào?”',
        date: new Date('2022-07-10'),
        author: 'Bởi TEDxUSSH',
        description: 'Marilyn Manson đã từng nói rằng “Âm nhạc chính là điều kì diệu mạnh mẽ nhất”. Ông đã lột tả được âm nhạc thông một từ “Ma thuật”. Âm nhạc có sức mạnh và có thể đạt đến nơi sâu xa nhất của trí tưởng tượng - nơi mà chúng ta gọi là thiên đường.',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 8,
        blog: blog8,
        title: 'THIẾT KẾ TƯƠNG LAI: Những trang phục ‘sống’ có thể hấp thụ CO2',
        date: new Date('2022-07-10'),
        author: 'Bởi MATTHEW PONSFORD',
        description: 'Nhà thiết kế người Canada gốc Iran, Roya Aghighi muốn bạn tưởng tượng rằng chiếc áo sơ mi của bạn đang ‘sống’. Chẳng phải kịch bản của một bộ phim kinh dị, Aghighi hy vọng chúng ta có thể phát triển mối quan hệ thân thiết hơn với **thời trang** - bằng cách xem quần áo như những sinh vật cần chúng ta giúp đỡ để tồn tại. "Bạn sẽ không vứt quần áo của mình vào góc tủ hay vào máy giặt", Aghighi trả lời từ Vancover. "Nó sẽ ngay lập tức thay đổi cách bạn nghĩ về quần áo của mình".',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 9,
        blog: blog9,
        title: 'Trong cuộc chiến dài hơi để bảo vệ môi trường, “thời trang bền vững” liệu có chiến thắng?',
        date: new Date('2022-07-10'),
        author: 'Bởi TedXUSSH',
        description: 'Những năm gần đây, tình trạng của môi trường ngày càng khẩn cấp và đạt đến mức báo động nhưng đại đa số người thì chọn tiếp tục bỏ mặc và hành động như thể họ vô can trước những lời kêu cứu từ tự nhiên. Băng ở hai cực luôn tan và rừng thì vẫn cháy, chúng ta đã nói đến điều này rất nhiều lần rồi nhưng đã có bao nhiêu người thực sự đứng lên hành động? Đáng mừng thay, khi ngành công nghiệp thời trang đã có những quan tâm đáng kể về vấn đề này, họ đưa “Thời trang bền vững” trở thành giá trị cốt lõi trong chiến lược hoạt động khoảng vài năm trở lại đây.',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 10,
        blog: blog10,
        title: 'Chuyện làm đẹp trong trại giam của các nữ tù nhân',
        date: new Date('2022-07-10'),
        author: 'Bởi CNN',
        description: 'Năm 2020 quả là một thời điểm đầy khó khăn đối với Joyce Pequeno, một tù nhân 28 tuổi tại Cơ sở cải huấn Coffee Creek ở Wilsonville, Oregon. Phiên điều trần khoan hồng của cô đã bị hoãn lại. Mỗi ngày, Joyce đều thoa phấn nền, đánh mi mắt và kẻ viền mắt bằng phấn rôm. "Nó khiến tôi cảm thấy dễ chịu như một người phụ nữ bình thường vậy”, cô nói qua email.',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 11,
        blog: blog11,
        title: 'Nghệ thuật trộm cắp: khi nào thì tác quyền đi quá xa?',
        date: new Date('2022-07-10'),
        author: 'Bởi TedXUSSH',
        description: 'Nếu bạn muốn tái hiện bức tranh Mona Lisa, hãy cứ làm. Cạo râu ria mép hay cho cô ấy cả một bộ râu nếu bạn thích. Dĩ nhiên, đừng vẽ trực tiếp lên bản gốc; đó là tài sản vật chất của Viện bảo tàng Louvre. Những người bảo quản của bảo tàng có thể sẽ rất tức giận với bạn. Còn nếu không, hãy thoải mái làm điều tốt nhất hay thậm chí tệ nhất với bức chân dung Lady Lisa của Leonardo.',
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 12,
        blog: blog12,
        title: 'CÂN BẰNG TỪ BÊN TRONG - BÀN VỀ CHÁNH NIỆM',
        date: new Date('2019-07-10'),
        author: 'Bởi\n\nNguyễn Lê Phương Huyền,Vũ Lê Minh Thư',
        description: 'Chẳng ai sống ở đời mà không biết về đau khổ. 350 triệu người trên thế giới đang chịu đựng trầm cảm, WHO đã liệt kê trầm cảm vào một trong hai căn bệnh có sức tàn phá một người nặng nề nhất. Thật kỳ lạ là thay vì cố gắng chữa lành nó như những căn bệnh khác, hầu hết mọi người lại nghi ngờ và phủ nhận sự tồn tại của nó.',
        img: blogBVCN,
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 13,
        blog: blog13,
        title: 'NHÌN VỀ NGƯỜI TRẺ - CÂU CHUYỆN TRUYỀN THỐNG - HỘI NHẬP',
        date: new Date('2019-07-10'),
        author: 'Bởi Huỳnh Thanh Thảo Nguyên',
        description: 'Nhắc đến thế hệ trẻ Việt Nam trong bối cảnh toàn cầu hóa, thật không khó để tìm thấy những mảng màu tối mang tính thách thức hơn là cơ hội. Đến từ góc nhìn của nhà nước, Tạp chí Cộng Sản 2013 có bài “Ảnh hưởng của văn hóa nước ngoài đến văn hóa Việt Nam giai đoạn hiện nay” đề cập chủ yếu đến những biểu hiện tiêu cực của giới trẻ khi tiếp thu văn hóa nước ngoài như: “sống thử”, “sống gấp; “tiêu xài hoang phí”, “mưu cầu vật chất” và “theo đuổi những trào lưu quái đản”. Đến từ một góc nhìn khác được đăng tải bởi Trung tâm Văn Hóa Học Lý Luận và Ứng dụng thuộc ĐHQG -TPHCM năm 2015, bài “Một số vấn đề về văn hóa của giới trẻ” đào sâu hơn vào ba mặt tiêu biểu “âm nhạc, thời trang, đời sống” của giới trẻ để đánh giá sự biến chuyển về văn hóa của người trẻ trong thời đại toàn cầu hóa. Tuy nhiên, dễ thấy yếu tố tiêu cực vẫn là nổi trội nhất và cách tiếp cận phổ biến vẫn là làm sao để giải quyết sự biến chuyển này hơn là dành cho nó một cái nhìn khách quan.',
        img: blogNVNT,
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 14,
        blog: blog14,
        title: 'BẠN CHỌN LÀ AI TRONG TRÁCH NHIỆM ĐÓNG GÓP VÌ XÃ HỘI',
        date: new Date('2019-07-10'),
        author: 'Bởi Huỳnh Thanh Thảo Nguyên',
        description: 'Con người sinh ra đã luôn là một phần, là thành quả được tạo nên từ các nguồn lực của xã hội. Do đó, con người sống cần có trách nhiệm “đóng góp” (contribute) cho xã hội. Trách nhiệm này lắm lúc tạo nên những áp lực vô hình lên ta khi truyền thông nước nhà có xu hướng “thổi phồng” những đóng góp của các cá nhân khác. Hướng sự tập trung vào những câu chuyện ấy không làm chúng ta xác định được mình nên làm gì trong trách nhiệm đã gán định. Chỉ khi bản thân chủ động mở rộng tâm trí, lắng nghe và trải nghiệm, ta mới cho phép bản thân hiểu và chọn được cho mình một vị trí đóng góp vừa vặn. Tâm trí sẵn sàng, mời bạn bắt đầu cuộc tìm kiếm.',
        img: blogBCLA,
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 15,
        blog: blog15,
        title: 'ĐÂU LÀ ĐIỂM DỪNG CHO QUYỀN HẠN CỦA CON NGƯỜI ĐỐI VỚI THIÊN NHIÊN?',
        date: new Date('2019-07-10'),
        author: 'Bởi Nguyễn Lê Phương Huyền',
        description: 'Sự sống đã khởi nguồn từ 4,6 tỷ năm trước trên hành tinh này. Từ bao đời, thiên nhiên đã gióng lên tiếng nói của chính nó trong những hạt mầm tách vỏ, như một điều kì diệu vẫn lặng lẽ sinh sôi.',
        img: blogQHTN,
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 16,
        blog: blog16,
        title: 'ĐÁNH GIÁ TRONG GIÁO DỤC - KHI KIỂM TRA KHÔNG CÒN TỐI ƯU',
        date: new Date('2019-07-10'),
        author: 'Bởi Nguyễn Ngọc Quỳnh',
        description: 'Quá trình học tập thường xuyên gắn với sự đánh giá, dù là ở nhà trường chính quy, hay các khóa học miễn phí, trực tuyến. Việc thực hiện đánh giá là cần thiết, đối với cả người học, người dạy hay người thiết kế chương trình. Trong hình dung của đại đa số, sự đánh giá chủ yếu gắn với kiểm tra, thi cử, điểm số, xếp loại. Nói cách khác, việc đánh giá gắn với thước đo, và thường ít nhiều khiến người học cảm thấy áp lực. Nhưng thực tế, đấy chỉ là một phần trong bức tranh chung về việc đánh giá.',
        img: blogDGGD,
    },
    {
        genre: 'Sưu tầm',
        genreId: 'collection',
        blogNum: 17,
        blog: blog17,
        title: 'UTOPIA - CÁC LÝ THUYẾT KIẾN TẠO XÃ HỘI LÝ TƯỞNG',
        date: new Date('2019-07-10'),
        author: 'Bởi Nguyễn Ngọc Quỳnh',
        description: '17 Mục Tiêu Phát Triển Bền Vững do Liên Hợp Quốc đề ra và nỗ lực đạt được vào năm 2030  phản ánh 17 thách thức nghiêm trọng mà cả thế giới đang đối mặt: đói nghèo, bệnh tật, bất bình đẳng, ô nhiễm,...Các vấn đề xã hội này chính là tấm gương soi chiếu những bất cập còn tồn tại trong những mô hình xã hội hiện nay.',
        img: blogXHLT,
    },
];
