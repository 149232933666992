import React, { useState } from 'react';

import FAQuestion from 'components/FAQuestion/FAQuestion';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

export default function FAQ() {
    const [open, setOpen] = useState();

    return (
        <div className="bg-text-bg-500 h-full flex flex-col">
            <div className="mb-[40px]">
                <Navbar />
            </div>
            <h1 className="text-[20px] md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[54px] leading-[30px] text-center font-bold">FAQ</h1>
            <div className="mb-[40px] bg-tranparent py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center">
                {/* {[...Array(6).keys()].map((order) => (
                    <FAQuestion
                        question="contact.faq.0.question"
                        ans="contact.faq.0.ans"
                        open={open}
                        setOpen={setOpen}
                        order={order}
                        key={`contact ${order}`}
                    />
                ))} */}
                Coming Soon ...
            </div>
            <Footer />
        </div>
    );
}
