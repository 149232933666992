import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowDown from 'assets/icons/arrow_down.svg';
import ArrowUp from 'assets/icons/arrow_up.svg';
import CancelIcon from 'assets/icons/cancel.svg';
import FilterIcon from 'assets/icons/filter.svg';
import Tmp from 'assets/image/tmp.png';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { VideoContent as VideoContentEN, BlogContent as BlogContentEN } from 'script/en/learn';
import { VideoContent as VideoContentVN, BlogContent as BlogContentVN } from 'script/vn/learn';

import BlogCarousel from './BlogCarousel';
import BlogElement from './BlogElement';
import VideoCarousel from './VideoCarousel';
import VideoElement from './VideoElement';

export default function LearnPageMobile() {
    const { t, i18n } = useTranslation();
    const [type, setType] = useState('blog');
    const switchToBlog = () => setType('blog');
    const switchToVideo = () => setType('video');
    const [year, setYear] = useState('');
    const [genre, setGenre] = useState('');
    const [isYearOpen, setIsYearOpen] = useState(false);
    const [isTypeOpen, setIsTypeOpen] = useState(false);
    const handleYearFilter = () => {
        setIsYearOpen(!isYearOpen);
    };
    const handleTypeFilter = () => {
        setIsTypeOpen(!isTypeOpen);
    };
    const resetYear = () => {
        setYear('');
    };
    const resetType = () => {
        setGenre('');
    };
    const resetAll = () => {
        setYear('');
        setGenre('');
    };

    const VideoContent = i18n.language === 'en' ? VideoContentEN : VideoContentVN;
    const BlogContent = i18n.language === 'en' ? BlogContentEN : BlogContentVN;

    return (
        <div className="md:hidden bg-text-bg-500 h-full flex flex-col">
            <div className="mb-[40px]">
                <Navbar />
            </div>
            <div className="flex flex-col mb-10 items-center">
                <div className="flex space-x-[15px]">
                    <button type="button" onClick={switchToBlog} className={`h-9 w-[165px] text-xs font-bold rounded-[5px] ${type === 'blog' ? 'bg-blue-500' : 'bg-none'}`}>{t('learn.blog.title')}</button>
                    <button type="button" onClick={switchToVideo} className={`h-9 w-[165px] text-xs font-bold rounded-[5px] ${type === 'video' ? 'bg-blue-500' : 'bg-none'}`}>{t('learn.video.title')}</button>
                </div>
                <div className="flex mt-[15px]">
                    <div className="rounded-full h-6 w-6 border-[1.5px] border-blue-500 flex items-center justify-center">
                        <img src={FilterIcon} alt="Filter" />
                    </div>
                    <p className="ml-[15px] font-bold text-sm">{t('learn.filterTitle')}</p>
                    <button
                        type="button"
                        className={`z-10 ml-[48px] w-[105px] h-6 bg-text-bg-400 px-[23px] text-sm font-bold ${
                            isYearOpen ? 'rounded-t-[5px]' : 'rounded-[5px]'
                        }`}
                        aria-haspopup="listbox"
                        aria-expanded="true"
                        aria-labelledby="listbox-label"
                        onClick={handleYearFilter}
                    >
                        <span className="flex items-center justify-between">
                            <span className="inline">{t('learn.filter.0.title')}</span>
                            {(year === '' || !isYearOpen) ? <img src={ArrowDown} alt="Arrow down" /> : <img src={ArrowUp} alt="Arrow up" /> }
                        </span>
                        <div
                            className={`relative right-[23px] top-1 w-[105px] h-7 bg-text-bg-400 ${
                                isYearOpen ? '' : 'hidden'
                            }`}
                        >
                            {['2022', '2019']
                                .map((el) => (
                                    <div
                                        aria-hidden="true"
                                        key={el}
                                        className="w-[105px] h-6 bg-text-bg-400 text-text-bg-300 hover:bg-text-bg-50"
                                        onClick={() => {
                                            setIsYearOpen(false);
                                            setYear(el);
                                        }}
                                    >
                                        {el}
                                    </div>
                                ))}
                        </div>
                    </button>
                    <button
                        type="button"
                        className={`z-10 ml-[15px] w-[105px] h-6 bg-text-bg-400 px-[11.5px] text-sm font-bold ${
                            isTypeOpen ? 'rounded-t-[5px]' : 'rounded-[5px]'
                        }`}
                        aria-haspopup="listbox"
                        aria-expanded="true"
                        aria-labelledby="listbox-label"
                        onClick={handleTypeFilter}
                    >
                        <span className="flex items-center justify-between">
                            <span className="inline">{t('learn.filter.1.title')}</span>
                            {(genre === '' || !isTypeOpen) ? <img src={ArrowDown} alt="Arrow down" /> : <img src={ArrowUp} alt="Arrow up" /> }
                        </span>
                        <div
                            className={`relative right-[11px] top-1 w-[105px] h-7 bg-text-bg-400 ${
                                isTypeOpen ? '' : 'hidden'
                            }`}
                        >
                            {['copmac', 'collection']
                                .map((el) => (
                                    <div
                                        aria-hidden="true"
                                        key={el}
                                        className="w-[105px] h-6 bg-text-bg-400 text-text-bg-300 hover:bg-text-bg-50"
                                        onClick={() => {
                                            setIsTypeOpen(false);
                                            setGenre(el);
                                        }}
                                    >
                                        {el === 'copmac' ? t('learn.filter.1.options.0') : t('learn.filter.1.options.1')}
                                    </div>
                                ))}
                        </div>
                    </button>
                </div>
                <div className="mt-[15px] px-[15px] w-full flex flex-col">
                    {year === '' && genre === '' && (<h1 className="text-lg font-medium mb-[15px] mt-[15px]">{t('learn.video.featured')}</h1>)}
                    <div className="flex space-x-[15px]">
                        {year !== '' && (
                            <div className="flex bg-text-bg-50 w-[107px] h-[30px] mb-[30px] justify-center items-center space-x-[10px] rounded-[5px]">
                                <p className="text-xs font-bold text-text-bg-500">
                                    Năm
                                    {' '}
                                    {year}
                                </p>
                                <button type="button" onClick={resetYear}>
                                    <img src={CancelIcon} alt="Cancel" />
                                </button>
                            </div>
                        )}
                        {genre !== '' && (
                            <div className="flex bg-text-bg-50 w-[107px] h-[30px] mb-[30px] justify-center items-center space-x-[10px] rounded-[5px]">
                                <p className="text-xs font-bold text-text-bg-500">{genre}</p>
                                <button type="button" onClick={resetType}>
                                    <img src={CancelIcon} alt="Cancel" />
                                </button>
                            </div>
                        )}
                        {(year !== '' || genre !== '') && (<button type="button" onClick={resetAll} className="text-xs font-bold mb-[30px] underline self-center">Xóa lựa chọn</button>)}
                    </div>
                    {!year && !genre && (
                        <div className="w-full relative flex items-center">
                            {type === 'blog' ? (
                                <div className="flex overflow-x-auto no-scrollbar space-x-[15px]">
                                    {BlogContent.slice(BlogContent.length - 3, BlogContent.length).reverse().map((el, idx) => <BlogCarousel id={BlogContent.length - idx} key={el.title} title={el.title} date={el.date} author={el.author} img={el.img || Tmp} />)}
                                </div>
                            ) : (
                                <div className="flex overflow-x-auto no-scrollbar space-x-[15px]">
                                    {VideoContent.slice(VideoContent.length - 3, VideoContent.length).map((el, idx) => <VideoCarousel key={el.src} embedId={el.src} title={el.title} date={el.date} author={el.author} />)}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="mt-[30px] px-[15px] w-full flex flex-col">
                    <h1 className="text-lg font-medium mb-[15px]">{t('learn.all')}</h1>
                    {type === 'blog' ? (
                        <div className="w-full flex flex-col items-center space-y-[15px]">
                            {BlogContent.filter((el) => {
                                if ((!year || year === el.date.getFullYear().toString()) && (!genre || genre === el.genreId || genre === 'All')) { return true; }
                                return false;
                            }).map((el, idx) => (
                                <BlogElement
                                    blogNum={idx + 1}
                                    title={el.title}
                                    date={el.date}
                                    author={el.author}
                                    description={el.description}
                                    img={el.img || Tmp}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="w-full flex flex-col items-center space-y-[15px]">
                            {VideoContent.filter((el) => {
                                if ((!year || year === el.date.getFullYear().toString()) && (!genre || genre === el.genreId || genre === 'All')) { return true; }
                                return false;
                            }).map((el) => (
                                <VideoElement
                                    key={el.src}
                                    embedId={el.src}
                                    title={el.title}
                                    date={el.date}
                                    author={el.author}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}
