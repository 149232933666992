import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from './Title';

export default function Vision() {
    const { t } = useTranslation();

    return (
        <div
            className="vision w-screen h-full flex flex-col xl:space-x-0 justify-around items-center align-start pt-0 px-[15px] md:px-[40px] lg:px-[120px] xl:px-[160px] 3xl:px-[240px]"
        >
            <div className="intro h-full flex w-full space-x-5 z-20 mt-[30px]">
                <div className="h-[320px] lg:h-[360px] w-full flex flex-row flex-wrap items-center">
                    <div className="flex flex-col h-full rounded-[15px] shadow-lg items-center justify-items-center py-[32px] border-2 border-white">
                        <a href="#!">
                            {/* <img className="rounded-full w-[116px] xl:w-[144px] 2xl:w-[180px] aspect-square" src={Connect} alt="asdas" /> */}
                        </a>
                        <h5 className="text-white text-lg lg:text-xl 2xl:text-2xl font-bold mb-5 mt-10">{t('home.2.0.title')}</h5>
                        <p className="text-text-bg-100 text-base xl:text-lg mb-4 font-medium px-5 pr-4">
                            {t('home.2.0.content')}
                        </p>
                    </div>
                </div>
                <div className="h-[320px] lg:h-[360px] w-full flex flex-row flex-wrap items-center">
                    <div className="flex flex-col h-full rounded-[15px] shadow-lg items-center justify-items-center py-[32px] border-2 border-white">
                        <a href="#!">
                            {/* <img className="rounded-full w-[116px] xl:w-[144px] 2xl:w-[180px] aspect-square" src={VisionImg} alt="asdas" /> */}
                        </a>
                        <h5 className="text-white text-lg lg:text-xl 2xl:text-2xl font-bold mb-5 mt-10">{t('home.2.1.title')}</h5>
                        <p className="text-text-bg-100 text-base xl:text-lg mb-4 font-medium px-5">
                            {t('home.2.1.content')}
                        </p>
                    </div>
                </div>
                <div className="h-[320px] lg:h-[360px] w-full flex flex-row flex-wrap items-center">
                    <div className="flex flex-col h-full rounded-[15px] shadow-lg items-center justify-items-center py-[32px] border-2 border-white">
                        <a href="#!">
                            {/* <img className="rounded-full w-[116px] xl:w-[144px] 2xl:w-[180px] aspect-square" src={KhaiPhong} alt="asdas" /> */}
                        </a>
                        <h5 className="text-white text-lg lg:text-xl 2xl:text-2xl font-bold mb-5 mt-10">{t('home.2.2.title')}</h5>
                        <p className="text-text-bg-100 text-base xl:text-lg mb-4 font-medium px-5 overflow-y-hidden">
                            {t('home.2.2.content')}
                        </p>
                    </div>
                </div>
            </div>
            <Title>
                <p className="text-2xl 13i:text-3xl 27i:text-[44px] font-bold tracking-wide">{t('title.vision')}</p>
            </Title>
        </div>
    );
}
