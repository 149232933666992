import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTransition, animated, useSpring } from 'react-spring';

import Title from './Title';

export default function Speaker() {
    const { t } = useTranslation();

    const fadeIn = useSpring({
        from: { transform: 'translateY(0%)' },
        to: [{ transform: 'translateY(100%)' }, { transform: 'translateY(0%)' }],
        config: { duration: '1500' },
    });
    return (
        <>
            <div className="intro w-screen flex flex-col space-x-5 xl:space-x-0 justify-around align-start m-auto bg-[#1D1C1C] mt-0 pt-0 px-[15px] md:px-[40px] lg:px-[120px] xl:px-[160px] 3xl:px-[240px] z-20">
                <div className="max-w-[1420px] mx-auto mt-[30px] flex justify-between space-x-[60px] align-start">
                    <div className="grow shrink basis-[0]">
                        <div className="title 3xl:text-[32px] xl:text-3xl text-2xl font-bold ">{t('home.1.0.title')}</div>
                        <div className="description mt-4 2xl:text-lg md:text-base 3xl:text-xl font-light justify">{t('home.1.0.content')}</div>
                    </div>
                    <div className="grow shrink basis-[0]">
                        <div className="title 3xl:text-[32px] xl:text-3xl text-2xl font-bold ">{t('home.1.1.title')}</div>
                        <div className="description mt-4 2xl:text-lg md:text-base 3xl:text-xl font-light justify">{t('home.1.1.content')}</div>
                    </div>
                    <div className="grow shrink basis-[0]">
                        <div className="title 2xl:text-[32px] xl:text-3xl text-2xl font-bold ">{t('home.1.2.title')}</div>
                        <div className="description mt-4 2xl:text-lg md:text-base 3xl:text-xl font-light justify">{t('home.1.2.content')}</div>
                    </div>
                </div>
            </div>
            <Title>
                <p className="text-2xl 13i:text-3xl 27i:text-[44px] font-bold tracking-wide">{t('title.introduction')}</p>
            </Title>
        </>
    );
}
