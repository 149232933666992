import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { ReactComponent as Close } from 'assets/image/clear.svg';

export default function CarouselModal({
    show, onClose, title, shortDescription, description, img,
}) {
    const closeOnEscapeKeyDown = (e) => {
        if ((e.charCode || e.keyCode) === 27) {
            onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
        };
    }, []);

    return (
        <div
            aria-hidden="true"
            className={`fixed z-50 inset-0 bg-black bg-opacity-50 lg:bg-opacity-100 flex flex-col justify-center items-center opacity-0 transition-all duration-300 ease-out pointer-events-none ${
                show ? 'opacity-100 pointer-events-auto' : ''
            }`}
            onKeyDown={closeOnEscapeKeyDown}
        >
            <div
                aria-hidden="true"
                className={`bg-text-bg-500 relative flex flex-col lg:flex-row lg:space-x-5 p-5 lg:p-0 items-center 
                border-2 border-white lg:border-0 rounded-[15px] ${show ? 'animate-modal-move-up' : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    type="button"
                    className="lg:hidden w-full absolute flex flex-row justify-end top-1 right-1"
                    onClick={onClose}
                >
                    <Close className="fill-white h-4 w-4" />
                </button>
                <div className="p-[10px] md:p-5 bg-text-bg-50 w-[260px] h-[136px] md:w-[528px] lg:w-[360px] xl:w-[400px] 2xl:w-[460px] md:h-[280px] lg:h-[480px] rounded-[15px] flex lg:flex-col items-center">
                    <img alt={title} className="w-full lg:h-full max-h-[116px] md:max-h-[240px] lg:max-h-full max-w-full lg:max-w-[360px] bg-white rounded-[15px] object-cover overflow-hidden" src={img} />
                    {/* <div className="flex flex-col w-[100px] md:w-[214px] lg:w-full ml-[10px]">
                        <h1 className="max-h-[260px] overflow-y-hidden text-ellipsis mt-[10px] lg:mt-5 text-xs md:text-2xl font-bold text-text-bg-900">{title}</h1>
                        <p className="lg:mt-[10px] text-[10px] md:text-base font-medium text-text-bg-800 md:text-text-bg-400">
                            {shortDescription}
                        </p>
                    </div> */}
                </div>
                <div className="relative flex flex-col mt-[10px] lg:mt-0 w-[260px] h-[180px] md:w-[528px] lg:w-[600px] xl:w-[800px] 2xl:w-[940px] md:h-[360px] lg:h-[480px] rounded-[15px] p-[12px] md:p-5 border-2 border-text-bg-300 bg-text-bg-500 text-white text-sm md:text-2xl font-normal md:font-medium ">
                    <button
                        type="button"
                        className="hidden lg:block absolute right-[20px] z-50"
                        onClick={onClose}
                    >
                        <Close className="fill-white h-10 w-10" />
                    </button>
                    <div className="flex flex-col w-full h-[120px] mt-[12px] relative">
                        <h1 className="w-full text-[16px] md:text-[24px] lg:text-[32px] font-bold leading-normal relative overflow-y-hidden">{title}</h1>
                        <p className="mt-[12px] lg:mt-[16px] text-[12px] md:text-[16px] lg:text-[20px] font-medium relative">
                            {shortDescription}
                        </p>
                    </div>
                    <p
                        className="relative overflow-y-auto no-scrollbar mt-[20px] lg:mt-[24px]"
                        style={{ whiteSpace: 'pre-wrap' }}
                        dangerouslySetInnerHTML={{ __html: description || '' }}
                    />
                </div>
            </div>
        </div>
    );
}

CarouselModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};
CarouselModal.defaultProps = {
    show: false,
    onClose: () => {},
};
