import React, { useEffect, useRef, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { useTransition, animated, useSpring } from 'react-spring';

import TRAIN from '../../image/train.svg';
import Navbar from '../Navbar';
import './index.css';

import About from './About';
import Events from './Events';
import Footer from './Footer';
import Par from './Par';
import Podcast from './Podcast';
import Speaker from './Speaker';
import Video from './Video';
import Vision from './Vision';

function onWheel(apiObj, ev, isScroll, setIsScroll) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
    if (isScroll) return;
    if (isThouchpad) {
        if (ev.deltaX > 5) {
            apiObj.scrollNext();
            setIsScroll(true);
            setTimeout(setIsScroll(false), 300);
        } else if (ev.deltaX < -5) {
            apiObj.scrollPrev(); setIsScroll(true);
            setTimeout(setIsScroll(false), 300);
        } else { ev.stopPropagation(); }

        return;
    }

    if (ev.deltaY < 5) {
        apiObj.scrollPrev();
        setIsScroll(true);
        setTimeout(setIsScroll(false), 300);
    } else if (ev.deltaY > 5) {
        apiObj.scrollNext();
        setIsScroll(true);
        setTimeout(setIsScroll(false), 300);
    }
}

function TrainVisible() {
    const n = useRef(1);

    const styles = useSpring({
        loop: { n },
        from: {
            x: 1000,
            opacity: 0,
        },
        to: {
            x: 0,
            opacity: 1,
        },
        config: { duration: 1000 },
    });
    return (
        <div
            className="train h-[348px] md:h-[200px] absolute md:left-[50%] lg:bottom-[5%] md:bottom-[2%] z-50"
            style={{
                width: '100%',
                backgroundSize: 'contain',
                backgroundImage: `url(${TRAIN})`,
                backgroundRepeat: 'no-repeat',
                ...styles,
            }}
            alt="un"
        />
    );
}

export default function Test() {
    const [isScroll, setIsScroll] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const scrollContainer = document.querySelector('.react-horizontal-scrolling-menu--scroll-container');
        const train = document.querySelector('.train');

        scrollContainer.addEventListener('scroll', (ev) => {
            train.current = requestAnimationFrame(() => {
                train.style.left = `${train.scrollWidth * 0.5 - scrollContainer.scrollLeft * 2}px`;
            });
        });
    }, []);

    return (
        <div className="relative bg-[#1D1C1C] overflow-x-clip w-screen">
            <Navbar className="h-[10%]" />
            {TrainVisible()}
            <ScrollMenu onWheel={(apiObj, event) => onWheel(apiObj, event, isScroll, setIsScroll)} transitionDuration={1000}>
                {/* <div className="w-[200vw] absolute"> */}
                {/* <div className="sticky">
                        <Par />
                    </div> */}
                {/* </div> */}
                <Par />
                {/* <div className="w-screen" />
                <div className="w-screen" /> */}
                <About />
                <Vision />
                <Events />
                <Video />
                <Podcast />
                <Speaker />
                <Footer />
            </ScrollMenu>
            <div className="absolute z-70 bottom-0 px-[15px] md:px-[40px] lg:px-[120px] xl:px-[160px] 3xl:px-[240px]  w-full flex flex-col justify-items-end items-end">
                <p className="text-base 27i:text-[18px] font-sm tracking-wide lg:mt-2 xl:mt-4 2xl:mt-10 text-text-bg-200">
                    {t('title.rights')}
                    <a href="https://ted.com" className="text-TEDred font-medium">
                        {' '}
                        TED
                    </a>
                </p>
            </div>
        </div>
    );
}
