import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { DEFAULT_TITLE } from 'config';
import { PodcastContent } from 'db/podcast';
import useTitle from 'hooks/useTitle';
import i18n from 'i18n';

import PodcastDetail from './PodcastDetail';

export default function PodcastPage() {
    const { t } = useTranslation();
    const PCcontent = PodcastContent();
    const [podcast, setPodcast] = useState([
        true, false, false, false, false, false, false, false,
    ]);
    const [cur, setCur] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [podcastContent, setPodcastContent] = useState({
        key: 0,
        podcastUrl: PCcontent.ChuyenZ_LaThu1.podcastUrl,
        title: PCcontent.ChuyenZ_LaThu1.title,
        audio: PCcontent.ChuyenZ_LaThu1.audio,
        url: PCcontent.ChuyenZ_LaThu1.pictureUrl,
        host: PCcontent.ChuyenZ_LaThu1.host,
        content: PCcontent.ChuyenZ_LaThu1.content,
    });
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    useTitle(t('nav.podcast'), DEFAULT_TITLE);
    const showPodcastDetail = (num) => {
        const newState = podcast;
        newState[num] = true;
        newState[cur] = false;
        setPodcast(newState);
        setCur(num);
    };
    useEffect(() => {
        setPodcastContent({
            key: 0,
            podcastUrl: PCcontent.ChuyenZ_LaThu1.podcastUrl,
            title: PCcontent.ChuyenZ_LaThu1.title,
            audio: PCcontent.ChuyenZ_LaThu1.audio,
            url: PCcontent.ChuyenZ_LaThu1.pictureUrl,
            host: PCcontent.ChuyenZ_LaThu1.host,
            content: PCcontent.ChuyenZ_LaThu1.content,
        });
    }, [i18n.resolvedLanguage]);

    return (
        <div className="bg-text-bg-500 h-full flex flex-col">
            <div className="mb-[40px]">
                <Navbar />
            </div>
            <div className="px-[15px] md:px-[40px] lg:px-[116px] xl:px-[200px] 3xl:px-[250px] mb-[90px] md:mb-[220px] flex flex-col-reverse md:flex-row md:space-x-5 lg:space-x-1 xl:space-x-3 3xl:space-x-1 justify-center items-center md:items-start">
                <div className="w-[345px] md:w-[200px] lg:w-[330px] xl:w-[410px] 3xl:w-[700px] md:h-[440px] xl:h-[550px] 3xl:h-[794px] overflow-y-auto grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 3xl:grid-cols-2 gap-y-[15px] md:gap-y-5 no-scrollbar mt-[15px] md:mt-0 bg-text-bg-500">
                    {Object.keys(PCcontent).map((el) => (
                        <div
                            className={`w-[165px] h-[165px] md:w-[200px] md:h-[200px] lg:w-[160px] lg:h-[160px] xl:w-[200px] xl:h-[200px] 3xl:h-[340px] 3xl:w-[340px] cursor-pointer ${podcast[PCcontent[el].order] ? 'opacity-100' : 'bg-text-bg-500 opacity-50'} hover:opacity-100 relative`}
                            aria-hidden
                            key={el}
                            onClick={() => {
                                showPodcastDetail(PCcontent[el].order);
                                // movePodcastElement(PodcastContent[el].order);
                                setPodcastContent({
                                    key: el,
                                    podcastUrl: PCcontent[el].podcastUrl,
                                    title: PCcontent[el].title,
                                    audio: PCcontent[el].audio,
                                    url: PCcontent[el].pictureUrl,
                                    host: PCcontent[el].host,
                                    content: PCcontent[el].content,
                                });
                            }}
                        >
                            <img src={PCcontent[el].pictureUrl} className="w-[165px] h-[165px] md:w-[200px] md:h-[200px] lg:w-[160px] lg:h-[160px] xl:w-[200px] xl:h-[200px] 3xl:h-[340px] 3xl:w-[340px] bg-text-bg-500 rounded-[15px]" alt="Podcast Illustartion" />
                            <div className="absolute bottom-[5px] right-[5px] 3xl:bottom-5 3xl:right-5 w-[64px] h-[35px] 3xl:w-[94px] 3xl:h-10 rounded-[15px] text-sm 3xl:text-2xl font-normal 3xl:font-medium backdrop-blur-md flex justify-center items-center">02:18</div>
                        </div>
                    ))}
                </div>
                <div id="podcast_detail" className="flex w-[345px] md:w-[459px] 3xl:w-[700px] overflow-x-hidden no-scrollbar space-x-[7px] md:space-x-[21px] 3xl:space-x-5">
                    <PodcastDetail
                        key={podcastContent.key}
                        podcastUrl={podcastContent.podcastUrl}
                        title={podcastContent.title}
                        audio={podcastContent.audio}
                        url={podcastContent.url}
                        host={podcastContent.host}
                        content={podcastContent.content}
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}
