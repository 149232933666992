import React from 'react';
import { useTranslation } from 'react-i18next';

import QuotationIcon from 'assets/icons/double_quotation.svg';
import BuiThiThuyDoan from 'assets/image/BuiThiThuyDoan.jpg';
import TuyetNhi from 'assets/image/duong_doan_tuyet_nhi.JPG';
import LeTuAnh from 'assets/image/le_tu_anh.jpg';
import TranDucTri from 'assets/image/tran_duc_tri.png';

import Title from './Title';

function SpeakerBox({ name, quote, img }) {
    return (
        <div className="grow basis-0 h-[420px] flex flex-row flex-wrap items-center">
            <div className="flex flex-col h-full rounded-[15px] shadow-lg items-center justify-items-center py-[24px] px-4 border-2 border-white">
                <a href="#!">
                    <img className="rounded-full w-[116px] xl:w-[144px] 3xl:w-[180px] aspect-square object-contain" src={img} alt="asdas" />
                </a>
                <p className="text-white text-base lg:text-[17px] 3xl:text-xl font-bold mb-4 mt-10">{name}</p>
                <p className="text-text-bg-100 text-[12px] xl:text-[12.5px] mb-4 font-medium">
                    {quote}
                </p>
            </div>
        </div>
    );
}

export default function Speaker() {
    const { t } = useTranslation();
    return (
        <div
            className="vision w-screen h-full flex flex-col xl:space-x-0 justify-around items-center align-start mt-0 pt-0 px-[15px] md:px-[40px] lg:px-[120px] xl:px-[160px] 3xl:px-[240px]"
        >
            <div className="intro h-full flex w-full space-x-5 align-start z-20">
                <SpeakerBox name={t('home.5.0.name')} quote={t('home.5.0.speech')} img={LeTuAnh} />
                <SpeakerBox name={t('home.5.1.name')} quote={t('home.5.1.speech')} img={TranDucTri} />
                <SpeakerBox name={t('home.5.2.name')} quote={t('home.5.2.speech')} img={TuyetNhi} />
                <SpeakerBox name={t('home.5.3.name')} quote={t('home.5.3.speech')} img={BuiThiThuyDoan} />
            </div>
            <Title>
                <p className="text-2xl 13i:text-3xl 27i:text-[44px] font-bold tracking-wide">
                    {t('title.speaker')}
                </p>
            </Title>
        </div>
    );
}
