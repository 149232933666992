import { t } from 'i18next';
import React from 'react';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

export default function Contact() {
    useTitle(t('nav.contact'), DEFAULT_TITLE);
    return (
        <div className="bg-text-bg-500 h-full flex flex-col">
            <div className="mb-[40px]">
                <Navbar />
            </div>
            <Footer />
        </div>
    );
}
