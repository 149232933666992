import { t } from 'i18next';
import React, { useState } from 'react';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow_right.svg';
import PauseIcon from 'assets/icons/pause_icon.svg';
import PlayIcon from 'assets/icons/play_icon.svg';
import PodcastImg from 'assets/image/chuyenZ.jpg';
import SpotifyDemo from 'assets/image/podcast_demo.png';
import SpotifyLogo from 'assets/image/Spotify_Logo.svg';
import { PodcastAudio } from 'db/podcast';

import HomepagePodcastOverlay from './HomepagePodcastOverlay';

export default function HomepagePodcast() {
    let sound;
    sound = new Audio(PodcastAudio[0]);
    const [position, setPosition] = useState('left');
    const [play, setPlay] = useState({
        one: false, two: false, three: false, four: false, five: false,
    });
    const [overlay, setOverlay] = useState({
        one: false, two: false, three: false, five: false,
    });
    const [audio, setAudio] = useState(sound);

    const handleScroll = (e) => {
        const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
        if (scrollLeft === 0) setPosition('left');
        else if (scrollLeft + clientWidth === scrollWidth) {
            setPosition('right');
        } else setPosition('mid');
    };
    const moveRight = () => {
        let scrollAmount = 0;
        const element = document.getElementById('podcast_element_mobile');
        const slideTimer = setInterval(() => {
            element.scrollLeft += 52.5;
            scrollAmount += 52.5;
            if (scrollAmount >= 315) {
                window.clearInterval(slideTimer);
            }
        }, 10);
        if (element.scrollLeft + element.clientWidth === element.scrollWidth) {
            setPosition('right');
        } else setPosition('mid');
    };
    const moveLeft = () => {
        let scrollAmount = 0;
        const element = document.getElementById('podcast_element_mobile');
        const slideTimer = setInterval(() => {
            element.scrollLeft -= 52.5;
            scrollAmount += 52.5;
            if (scrollAmount >= 315) {
                window.clearInterval(slideTimer);
            }
        }, 10);
        if (element.scrollLeft === 0) setPosition('left');
        else setPosition('mid');
    };

    return (
        <div className="px-[15px] mt-[60px] flex flex-col">
            <h1 className="text-xl font-bold leading-[30px]">
                <span className="text-TEDred">Podcast</span>
                {' '}
                {t('title.podcast.listen')}
            </h1>
            <a href="https://tedx-gdsc.herokuapp.com/event" className="text-sm font-bold text-epink-500">{t('title.more')}</a>
            <div className="mt-[30px] rounded-[15px] border-2 border-text-bg-300 flex px-[34px] py-[38.5px] w-[345px] self-center">
                <p className="text-lg font-medium leading-[27px]">{t('title.podcast.follow')}</p>
                <img src={SpotifyLogo} alt="Spotify Logo" />
            </div>
            <div className="flex items-center relative mt-[30px]">
                {position !== 'left' && (
                    <button
                        type="button"
                        className="hidden md:flex z-10 absolute left-0 w-10 h-10 bg-black rounded-full border-2 border-blue-500 justify-center items-center"
                        onClick={moveLeft}
                    >
                        <LeftArrowIcon />
                    </button>
                )}
                {(position !== 'right') && (
                    <button
                        type="button"
                        className="hidden md:flex z-10 absolute right-0 w-10 h-10
                    bg-black rounded-full border-2 border-blue-500 justify-center items-center"
                        onClick={moveRight}
                    >
                        <RightArrowIcon />
                    </button>
                )}
                <div className="h-[200px] flex overflow-x-auto no-scrollbar space-x-[15px]" id="podcast_element_mobile" onScroll={handleScroll}>
                    {!overlay.one ? (
                        <div
                            aria-hidden
                            className="flex-none w-[260px] rounded-[20px] relative cursor-pointer"
                            onClick={() => setOverlay({ ...overlay, one: true })}
                        >
                            <img className="w-[260px] h-[198px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                            <div className="flex items-center w-[260px] h-[77px] md:h-[80px] p-[10px] backdrop-blur-xl bg-podcast absolute bottom-0 rounded-b-[20px]">
                                <div className="flex flex-col">
                                    <p className="text-sm md:text-2xl font-bold leading-[21px] w-[200px]">{t('home.4.0.title')}</p>
                                    <p className="text-[10px] font-medium md:text-xl text-text-bg-100">{t('title.podcast.origin')}</p>
                                </div>
                                {play.one
                                    ? (
                                        <img
                                            src={PauseIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                setPlay({ ...play, one: false });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Pause"
                                        />
                                    )
                                    : (
                                        <img
                                            src={PlayIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                sound = new Audio(PodcastAudio[0]);
                                                sound.play();
                                                setAudio(sound);
                                                setPlay({ one: true, two: false, three: false });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Play"
                                        />
                                    )}
                            </div>
                        </div>
                    ) : (
                        <HomepagePodcastOverlay
                            closeOverlay={() => setOverlay({ ...overlay, one: false })}
                            title={t('home.4.0.title')}
                            url="6ffu4H99cC8OglGSoYGnxf"
                            audioSrc={PodcastAudio[0]}
                        />
                    )}
                    {!overlay.two ? (
                        <div
                            aria-hidden
                            className="flex-none w-[260px] rounded-[20px] relative cursor-pointer"
                            onClick={() => setOverlay({ ...overlay, two: true })}
                        >
                            <img className="w-[260px] h-[198px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                            <div className="flex items-center w-[260px] h-[77px] md:h-[80px] p-[10px] backdrop-blur-xl bg-podcast absolute bottom-0 rounded-b-[20px]">
                                <div className="flex flex-col">
                                    <p className="text-sm md:text-2xl font-bold leading-[21px] w-[200px]">{t('home.4.1.title')}</p>
                                    <p className="text-[10px] font-medium md:text-xl text-text-bg-100">{t('title.podcast.origin')}</p>
                                </div>
                                {play.two
                                    ? (
                                        <img
                                            src={PauseIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                setPlay({ ...play, two: false });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Pause"
                                        />
                                    )
                                    : (
                                        <img
                                            src={PlayIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                sound = new Audio(PodcastAudio[1]);
                                                sound.play();
                                                setAudio(sound);
                                                setPlay({ one: false, two: true, three: false });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Play"
                                        />
                                    )}
                            </div>
                        </div>
                    ) : (
                        <HomepagePodcastOverlay
                            closeOverlay={() => setOverlay({ ...overlay, two: false })}
                            title={t('home.4.1.title')}
                            url="23QqjWS4iYRV6zLJ5uZyUD"
                            audioSrc={PodcastAudio[1]}
                        />
                    )}
                    {!overlay.three ? (
                        <div
                            aria-hidden
                            className="flex-none w-[260px] rounded-[20px] relative cursor-pointer"
                            onClick={() => setOverlay({ ...overlay, three: true })}
                        >
                            <img className="w-[260px] h-[198px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                            <div className="flex items-center w-[260px] h-[77px] md:h-[80px] p-[10px] backdrop-blur-xl bg-podcast absolute bottom-0 rounded-b-[20px]">
                                <div className="flex flex-col">
                                    <p className="text-sm md:text-2xl font-bold leading-[21px] w-[200px]">{t('home.4.2.title')}</p>
                                    <p className="text-[10px] font-medium md:text-xl text-text-bg-100">{t('title.podcast.origin')}</p>
                                </div>
                                {play.three
                                    ? (
                                        <img
                                            src={PauseIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                setPlay({ ...play, three: false });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Pause"
                                        />
                                    )
                                    : (
                                        <img
                                            src={PlayIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                sound = new Audio(PodcastAudio[2]);
                                                sound.play();
                                                setAudio(sound);
                                                setPlay({ one: false, two: false, three: true });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Play"
                                        />
                                    )}
                            </div>
                        </div>
                    ) : (
                        <HomepagePodcastOverlay
                            closeOverlay={() => setOverlay({ ...overlay, three: false })}
                            title={t('home.4.1.title')}
                            url="5aJa0cz9f06tVSKxwOBVCy"
                            audioSrc={PodcastAudio[2]}
                        />
                    )}
                    {!overlay.four ? (
                        <div
                            aria-hidden
                            className="flex-none w-[260px] rounded-[20px] relative cursor-pointer"
                            onClick={() => setOverlay({ ...overlay, four: true })}
                        >
                            <img className="w-[260px] h-[198px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                            <div className="flex items-center w-[260px] h-[77px] md:h-[80px] p-[10px] backdrop-blur-xl bg-podcast absolute bottom-0 rounded-b-[20px]">
                                <div className="flex flex-col">
                                    <p className="text-sm md:text-2xl font-bold leading-[21px] w-[200px]">{t('home.4.3.title')}</p>
                                    <p className="text-[10px] font-medium md:text-xl text-text-bg-100">{t('title.podcast.origin')}</p>
                                </div>
                                {play.four
                                    ? (
                                        <img
                                            src={PauseIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                setPlay({ ...play, four: false });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Pause"
                                        />
                                    )
                                    : (
                                        <img
                                            src={PlayIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                sound = new Audio(PodcastAudio[3]);
                                                sound.play();
                                                setAudio(sound);
                                                setPlay({
                                                    one: false, two: false, three: false, four: true, five: false,
                                                });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Play"
                                        />
                                    )}
                            </div>
                        </div>
                    ) : (
                        <HomepagePodcastOverlay
                            closeOverlay={() => setOverlay({ ...overlay, four: false })}
                            title={t('home.4.3.title')}
                            url="3rzErb3ER9m9j3LNQjBVRg"
                            audioSrc={PodcastAudio[3]}
                        />
                    )}
                    {!overlay.five ? (
                        <div
                            aria-hidden
                            className="flex-none w-[260px] rounded-[20px] relative cursor-pointer"
                            onClick={() => setOverlay({ ...overlay, five: true })}
                        >
                            <img className="w-[260px] h-[198px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                            <div className="flex items-center w-[260px] h-[77px] md:h-[80px] p-[10px] backdrop-blur-xl bg-podcast absolute bottom-0 rounded-b-[20px]">
                                <div className="flex flex-col">
                                    <p className="text-sm md:text-2xl font-bold leading-[21px] w-[200px]">{t('home.4.4.title')}</p>
                                    <p className="text-[10px] font-medium md:text-xl text-text-bg-100">{t('title.podcast.origin')}</p>
                                </div>
                                {play.five
                                    ? (
                                        <img
                                            src={PauseIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                setPlay({ ...play, five: false });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Pause"
                                        />
                                    )
                                    : (
                                        <img
                                            src={PlayIcon}
                                            aria-hidden
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                audio.pause();
                                                sound = new Audio(PodcastAudio[4]);
                                                sound.play();
                                                setAudio(sound);
                                                setPlay({
                                                    one: false, two: false, three: false, four: false, five: true,
                                                });
                                            }}
                                            className="h-4 ml-5"
                                            alt="Play"
                                        />
                                    )}
                            </div>
                        </div>
                    ) : (
                        <HomepagePodcastOverlay
                            closeOverlay={() => setOverlay({ ...overlay, five: false })}
                            title={t('home.4.4.title')}
                            url="76Icul1TTZnYUhygOE2LJm"
                            audioSrc={PodcastAudio[4]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
