import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Zichuyen from '../../assets/image/Zichuyen.png';

import Title from './Title';

export default function Events() {
    // const [event, setEvent] = useState({ one: true, two: false, three: false });
    // const firstEvent = () => setEvent({ one: true, two: false, three: false });
    // const secondEvent = () => setEvent({ one: false, two: true, three: false });
    // const thirdEvent = () => setEvent({ one: false, two: false, three: true });
    const { t } = useTranslation();
    return (
        <div
            className="intro w-screen h-4/5 flex flex-row justify-around align-start mx-auto pt-0 z-20"
        >
            <div className="mt-[20px] flex flex-row max-w-[1920px] px-[15px] md:px-[40px] lg:px-[120px] xl:px-[160px] 2xl:px-[250px] gap-5">
                {/* <div className="flex w-full space-x-[5px] mt-[30px] justify-center justify-items-stretch">
                 <div
                        aria-hidden
                        onClick={firstEvent}
                        className={`bg-homepageEvent1 grow shrink basis-0 aspect-[460/225.24] ${event.one ? 'opacity-100' : 'opacity-40'} rounded-[10px] bg-center bg-no-repeat bg-contain hover:opacity-100 cursor-pointer hover:transition-opacity hover:delay-100`}
                    />
                    <div
                        aria-hidden
                        onClick={secondEvent}
                        className={`bg-homepageEvent2 grow shrink basis-0 aspect-[460/225.24] ${event.two ? 'opacity-100' : 'opacity-40'} rounded-[10px] bg-center bg-no-repeat bg-contain hover:opacity-100 cursor-pointer hover:transition-opacity hover:delay-100`}
                    />
                    <div
                        aria-hidden
                        onClick={thirdEvent}
                        className={`bg-homepageEvent3 grow shrink basis-0 aspect-[460/225.24] ${event.three ? 'opacity-100' : 'opacity-40'} rounded-[10px] bg-center bg-no-repeat bg-contain hover:opacity-100 cursor-pointer hover:transition-opacity hover:delay-100`}
                    />
                </div>
                {event.one && (
                    <div className="mt-2 13i:mt-5 13i:mx-auto 27i:mt-[30px]">
                        <h1 className="2xl:text-[36px] xl:text-3xl lg:text-2xl text-xl font-bold">{t('home.3.0.title')}</h1>
                        <p className="mt-1.5 text-base 27i:text-[18px] 13i:text-[16px] font-normal leading-relaxed text-text-bg-100 whitespace-pre-line">
                            {t('home.3.0.content')}
                        </p>
                    </div>
                )}
                {event.two && (
                    <div className="mt-2 13i:mt-5 13i:mx-auto 27i:mt-[30px]">
                        <h1 className="2xl:text-[36px] xl:text-3xl lg:text-2xl text-xl font-bold">{t('home.3.1.title')}</h1>
                        <p className="mt-1.5 text-base 27i:text-[18px] 13i:text-[16px] font-normal leading-relaxed text-text-bg-100 whitespace-pre-line">
                            {t('home.3.1.content')}
                        </p>
                    </div>
                )}
                {event.three && (
                    <div className="mt-2 13i:mt-5 13i:mx-auto 27i:mt-[30px]">
                        <h1 className="2xl:text-[36px] xl:text-3xl lg:text-2xl text-xl font-bold">{t('home.3.2.title')}</h1>
                        <p className="mt-1.5 text-base 27i:text-[18px] 13i:text-[16px] font-normal leading-relaxed text-text-bg-100 whitespace-pre-line">
                            {t('home.3.2.content')}
                        </p>
                    </div>
                )} */}
                <img src={Zichuyen} alt="Zichuyen" className="w-[280px] h-[280px] relative object-contain" />
                <div className="mt-4 13i:mt-5 13i:mx-auto 27i:mt-[30px]">
                    <h1 className="3xl:text-[40px] xl:text-3xl lg:text-2xl text-xl font-bold">{t('home.3.0.title')}</h1>
                    <p className="mt-4 text-base 27i:text-[18px] 13i:text-[16px] font-normal leading-relaxed text-text-bg-100 whitespace-pre-line">
                        {t('home.3.0.content')}
                    </p>
                    <p className=" text-base 27i:text-[18px] 13i:text-[16px] font-normal leading-relaxed text-text-bg-100 whitespace-pre-line">
                        {t('home.3.0.redirect1')}
                        <span><Link to="/event" className="z-50 cursor-pointer text-base 27i:text-[18px] 13i:text-[16px] leading-relaxed  whitespace-pre-line text-[#EB33FF] font-semibold">{t('home.3.0.redirect2')}</Link></span>
                    </p>
                </div>
            </div>
            <Title>
                <p className="text-2xl 13i:text-3xl 27i:text-[44px] font-bold tracking-wide">
                    {t('title.event.1')}
                    {' '}
                    <span className="text-red-500">{t('title.event.2')}</span>
                    {' '}
                    {t('title.event.3')}
                </p>
                <Link to="/event" className="z-50 cursor-pointer text-base text-[#EB33FF] font-semibold">{t('title.more')}</Link>
            </Title>
        </div>
    );
}
