import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import BlogDetail from 'components/LearnPageMobile/BlogDetail';
import Contact from 'pages/Contact';
import BTC from 'pages/Contact/BTC';
import FAQ from 'pages/Contact/FAQ';
import EventPage1 from 'pages/EventPage/page1';
import EventPage2 from 'pages/EventPage/page2';
import EventPage3 from 'pages/EventPage/page3';
import GalleryPage from 'pages/Gallery';
import HomePage from 'pages/HomePage';
import LearnPage from 'pages/LearnPage';
import PodcastPage from 'pages/Podcast';
import PodcastDetail from 'pages/Podcast/PodcastDetail';
import Sponsor from 'pages/Sponsor';
import ScrollToTop from 'utils/ScrollToTop';

import store from './store';

export default function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" exact element={<HomePage />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/blog/:id" element={<BlogDetail />} />
                    <Route path="/learn" element={<LearnPage />} />
                    <Route path="event">
                        <Route path="" element={<EventPage1 />} />
                        <Route path="1" element={<EventPage1 />} />
                        <Route path="2" element={<EventPage2 />} />
                        <Route path="3" element={<EventPage3 />} />
                    </Route>
                    <Route path="contact">
                        <Route path="" element={<Contact />} />
                        <Route path="btc" element={<BTC />} />
                        <Route path="faq" element={<FAQ />} />
                    </Route>
                    <Route path="/gallery" element={<GalleryPage />} />
                    <Route path="/podcast" element={<PodcastPage />} />
                    <Route path="/sponsor" element={<Sponsor />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}
