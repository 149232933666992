import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export default function EventTimeLine({ numPage }) {
    return (
        <div className="relative mt-[73.6px] 3xl:mt-[92px] w-full">
            <div className="hidden md:block absolute bg-text-bg-50 w-full h-[14.4px] 3xl:h-4 rounded-full top-[8px] 3xl:top-[10px]">{}</div>
            <div className="md:hidden absolute bg-text-bg-50 w-[8px] h-[calc(100%+2rem)] rounded-full left-[5px]">{}</div>
            <div className="md:ml-[96px] 3xl:ml-[120px] flex flex-col md:flex-row md:space-x-5 space-y-10 md:space-y-0">
                <Link to="/event/1" className="mt-[23px] md:mt-0 relative flex flex-row md:flex-col items-center md:items-start w-fit md:w-[340px] h-full space-x-8 md:space-x-0">
                    <div className={`md:h-[28.8px] h-[18px] 3xl:h-9 aspect-square rounded-full ${numPage === 1 ? 'bg-red-500' : 'bg-text-bg-50'}`} />
                    <span className={`md:mt-[30px] md:font-bold text-lg 3xl:text-2xl ${numPage === 1 ? 'text-red-500' : 'text-text-bg-50'}`}>
                        {t('event.2.title')}
                    </span>
                </Link>
                <Link to="/event/2" className="relative flex flex-row md:flex-col items-center md:items-start w-fit md:w-[340px] h-fit space-x-8 md:space-x-0">
                    <div className={`md:h-[28.8px] h-[18px] 3xl:h-9 aspect-square rounded-full ${numPage === 2 ? 'bg-red-500' : 'bg-text-bg-50'}`} />
                    <span className={`md:mt-[30px] md:font-bold text-lg 3xl:text-2xl ${numPage === 2 ? 'text-red-500' : 'text-text-bg-50'}`}>
                        {t('event.1.title')}
                    </span>
                </Link>
                <Link to="/event/3" className="relative flex flex-row md:flex-col items-center md:items-start w-fit md:w-[340px] h-fit space-x-8 md:space-x-0">
                    <div className={`md:h-[28.8px] h-[18px] 3xl:h-9 aspect-square rounded-full ${numPage === 3 ? 'bg-red-500' : 'bg-text-bg-50'}`} />
                    <span className={`md:mt-[30px] md:font-bold text-lg 3xl:text-2xl ${numPage === 3 ? 'text-red-500' : 'text-text-bg-50'}`}>
                        {t('event.0.title')}
                    </span>
                </Link>
            </div>
        </div>
    );
}

EventTimeLine.propTypes = {
    numPage: PropTypes.number,
};

EventTimeLine.defaultProps = {
    numPage: 1,
};
