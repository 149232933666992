import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import SecondEvent from 'assets/image/tuan2modal.svg';

export default function Week2Detail({ show, onClose }) {
    const closeOnEscapeKeyDown = (e) => {
        if ((e.charCode || e.keyCode) === 27) {
            onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
        };
    }, []);

    return (
        <div
            aria-hidden="true"
            className={`fixed z-50 hidden inset-0 bg-black md:flex flex-col justify-center items-center opacity-0 transition-all duration-300 ease-out pointer-events-none ${
                show ? 'opacity-100 pointer-events-auto' : ''
            }`}
            onKeyDown={closeOnEscapeKeyDown}
        >
            <div
                aria-hidden="true"
                className={`relative flex space-x-5 items-center rounded ${show ? 'animate-modal-move-up' : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex-none w-[360px] 2xl:w-[460px] rounded-[15px] relative">
                    <img src={SecondEvent} alt="First Event" className="w-[360px] 2xl:w-[460px]" />
                    <div className="w-[360px] 2xl:w-[460px] h-[148px] flex py-5 px-2 bg-text-bg-500 absolute bottom-0 border-yellow-500 border-2 rounded-b-[15px]">
                        <p className="self-center lg:self-end w-[83px] text-lg 2xl:text-xl font-bold text-red-500">{t('event.2.section.4.content.1.week')}</p>
                        <p className="ml-[10px] w-[340px] 2xl:w-[340px] text-xl 2xl:text-2xl font-bold
                        self-center lg:self-end"
                        >
                            {t('event.2.section.4.content.1.title')}
                        </p>
                    </div>
                </div>
                <div className="w-[600px] xl:w-[800px] 2xl:w-[940px] h-[460px] 2xl:h-[588px] rounded-[15px]
                p-5 border-2 border-yellow-500 bg-text-bg-500 text-white text-xl xl:text-2xl font-medium overflow-y-auto no-scrollbar"
                >
                    {/* <p className="text-epink-500 mb-5 text-lg xl:text-xl">https://www.facebook.com/tedxhcmussh/posts/1213709655834230</p> */}
                    <p className="whitespace-pre-line">
                        {t('event.2.section.4.content.1.content')}
                    </p>
                </div>
            </div>
            <button
                type="button"
                className="hidden lg:block mt-10 underline font-bold text-base md:text-xl text-text-bg-300"
                onClick={onClose}
            >
                CLOSE

            </button>
        </div>
    );
}

Week2Detail.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};
Week2Detail.defaultProps = {
    show: true,
    onClose: () => {},
};
