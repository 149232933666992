// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyBXAVPjLLe2yU4BKJH1HFftTcrawiD6X-s',
    authDomain: 'tedx-dsc.firebaseapp.com',
    projectId: 'tedx-dsc',
    storageBucket: 'tedx-dsc.appspot.com',
    messagingSenderId: '527465289930',
    appId: '1:527465289930:web:fdba25fbb4a91647323c33',
    measurementId: 'G-3H1J24WV5B',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const storage = getStorage(app);

export default storage;
