import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CancelIcon from 'assets/icons/cancel.svg';
import Tmp from 'assets/image/tmp.png';
import BlogElement from 'components/LearnPageDesktop/BlogElement';
import { BlogContent as BlogContentEN } from 'script/en/learn/index';
import { BlogContent as BlogContentVN } from 'script/vn/learn/index';

export default function BlogSection({
    year, genre, sortType, resetYear, resetType, resetAll,
}) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const BlogContent = i18n.language === 'en' ? BlogContentEN : BlogContentVN;

    const BlogLength = BlogContent.length;

    return (
        <>
            {!year && !genre && !sortType && (
                <section>
                    <h1 className="font-bold text-lg md:text-[36px] 2xl:text-[54px] leading-[120%] text-end md:mb-[25.6px] xl:mb-8 2xl:mb-10">{t('learn.blog.featured')}</h1>
                    <div className="grid grid-cols-2 grid-rows-2 gap-2 xl:gap-4 2xl:gap-5">
                        <div
                            className="row-span-2 w-full h-full bg-text-bg-600 p-5 rounded-[20px] flex flex-col cursor-pointer brightness-100 hover:brightness-50 transition-all duration-150"
                            aria-hidden="true"
                            onClick={() => navigate(`/blog/${BlogContent[BlogLength - 1].blogNum}`)}
                        >
                            <img alt="temp" src={BlogContent[BlogLength - 1].img} className="aspect-[3/2] bg-white rounded-[20px] object-cover" />
                            <p className="mt-[10px] md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold">{BlogContent[BlogLength - 1].title}</p>
                            <p className="text-base font-bold text-text-bg-300">{BlogContent[BlogLength - 1].description}</p>
                            <div className="flex justify-between mt-[10px]">
                                <p className="text-base font-bold text-text-bg-300">
                                    {BlogContent[BlogLength - 1].date.toLocaleDateString('en-us', {
                                        month: 'short',
                                        year: 'numeric',
                                        day: 'numeric',
                                    })}

                                </p>
                                <p className="text-base font-bold text-text-bg-300">{BlogContent[BlogLength - 1].author}</p>
                            </div>
                        </div>
                        <div className="space-y-[10px] h-full relative brightness-100 hover:brightness-50 transition-all duration-150 cursor-pointer" aria-hidden="true" onClick={() => navigate(`/blog/${BlogContent[BlogLength - 2].blogNum}`)}>
                            <p className="mt-[10px] md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold">{BlogContent[BlogLength - 2].title}</p>
                            <p className="text-base font-bold text-text-bg-300">{BlogContent[BlogLength - 2].description}</p>
                            <div className="flex justify-between mt-[10px]">
                                <p className="text-base font-bold text-text-bg-300">
                                    {BlogContent[BlogLength - 2].date.toLocaleDateString('en-us', {
                                        month: 'short',
                                        year: 'numeric',
                                        day: 'numeric',
                                    })}

                                </p>
                                <p className="text-base font-bold text-text-bg-300">{BlogContent[BlogLength - 2].author}</p>
                            </div>
                            <div className="absolute bottom-0 w-full h-[5px] bg-text-bg-300 rounded-full" />
                        </div>
                        <div className="space-y-[10px] h-full relative brightness-100 hover:brightness-50 transition-all duration-150 cursor-pointer" aria-hidden="true" onClick={() => navigate(`/blog/${BlogContent[BlogLength - 3].blogNum}`)}>
                            <p className="mt-[10px] md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold">{BlogContent[BlogLength - 3].title}</p>
                            <p className="text-base font-bold text-text-bg-300">{BlogContent[BlogLength - 3].description}</p>
                            <div className="flex justify-between mt-[10px]">
                                <p className="text-base font-bold text-text-bg-300">
                                    {BlogContent[BlogLength - 3].date.toLocaleDateString('en-us', {
                                        month: 'short',
                                        year: 'numeric',
                                        day: 'numeric',
                                    })}
                                </p>
                                <p className="text-base font-bold text-text-bg-300">{BlogContent[BlogLength - 3].author}</p>
                            </div>
                            <div className="absolute bottom-0 w-full h-[5px] bg-text-bg-300 rounded-full" />
                        </div>
                    </div>
                </section>
            )}
            {(year !== '' || genre) && (
                <div className="flex space-x-[15px]">
                    {year !== '' && (
                        <div className="flex bg-text-bg-50 w-[203px] h-[60px] mb-[30px] justify-center items-center space-x-[10px] rounded-[10px]">
                            <p className="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold text-text-bg-500">
                                {t('learn.year')}
                                {' '}
                                {year}
                            </p>
                            <button type="button" onClick={resetYear}>
                                <img className="w-4 h-4" src={CancelIcon} alt="Cancel" />
                            </button>
                        </div>
                    )}
                    {genre !== '' && (
                        <div className="flex bg-text-bg-50 w-[203px] h-[60px] mb-[30px] justify-center items-center space-x-[10px] rounded-[10px]">
                            <p className="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold text-text-bg-500">
                                {
                                    genre === 'copmac' ? t('learn.filter.1.options.0') : t('learn.filter.1.options.1')
                                }

                            </p>
                            <button type="button" onClick={resetType}>
                                <img src={CancelIcon} alt="Cancel" />
                            </button>
                        </div>
                    )}
                    {(year !== '' || genre !== '') && (<button type="button" onClick={resetAll} className="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl  font-bold mb-[30px] underline self-center">{t('learn.deleteChoice')}</button>)}
                </div>
            )}
            <section>
                <h1 className="font-bold text-lg md:text-[36px] 2xl:text-[54px] text-end leading-[120%] md:mb-[25.6px] xl:mb-8 2xl:mb-10">{t('learn.all')}</h1>
                <div className="grid grid-cols-2 xl:grid-cols-3 md:gap-2 xl:gap-3 2xl:gap-5">
                    {(sortType === 'asc' || !sortType ? BlogContent : BlogContent.reverse()).filter((el) => {
                        if ((!year || year === el.date.getFullYear().toString()) && (!genre || genre === el.genreId || genre === 'All')) { return true; }
                        return false;
                    }).map((el, idx) => (
                        <BlogElement
                            blogNum={el.blogNum}
                            title={el.title}
                            date={el.date}
                            author={el.author}
                            description={el.description}
                            img={el.img || Tmp}
                        />
                    ))}
                </div>
            </section>
        </>
    );
}
