import './style.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { BlogContent as BlogContentEN } from 'script/en/learn';
import { BlogContent as BlogContentVN } from 'script/vn/learn';

import StyledMarkdownComponent from './StyledMarkdownComponent';

export default function BlogDetail() {
    const [content, setContent] = useState('');
    const [cover, setCover] = useState();
    const { id } = useParams();
    const navigate = useNavigate();

    const { i18n } = useTranslation();

    const BlogContent = i18n.language === 'en' ? BlogContentEN : BlogContentVN;

    useEffect(() => {
        if (id < 1 || id > BlogContent.length) navigate('/learn');
        fetch(BlogContent[id - 1].blog)
            .then((res) => res.text())
            .then((texts) => setContent(texts))
            .catch(() => navigate('/learn'));
        setCover(BlogContent[id - 1].img);
    }, [id, BlogContent]);

    const markDownComponent = {
        img: StyledMarkdownComponent.StyledImage,
        h1: StyledMarkdownComponent.StyledHeading1,
        h2: StyledMarkdownComponent.StyledHeading2,
        h3: StyledMarkdownComponent.StyledHeading3,
        p: StyledMarkdownComponent.StyledParagraph,
        strong: StyledMarkdownComponent.StyledStrong,
    };
    return (
        <div className="bg-text-bg-500 h-full flex flex-col">
            <div className="mb-[40px]">
                <Navbar />
            </div>
            <div className="px-[15px] md:px-[40px] lg:px-[116px] xl:px-[200px] 3xl:px-[250px] mb-[168px]">
                {cover && <img src={cover} alt="" className="w-full h-[320px] mb-[40px] object-cover rounded-[15px] md:rounded-[20px]" /> }
                <ReactMarkdown
                    components={markDownComponent}
                    className="reactMarkdown"
                >
                    {content}
                </ReactMarkdown>
            </div>
            <Footer />
        </div>
    );
}
