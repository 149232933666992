import PropTypes from 'prop-types';
import React from 'react';

export default function FilterOption({
    value, text, currentValue,
}) {
    console.log(value, currentValue);
    return (
        <label htmlFor={`filter-${value}`} className="flex justify-between items-center cursor-pointer">
            <span className="font-bold md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-text-bg-300 text-center h-fit leading-[150%]">{text}</span>
            <input id={`filter-${value}`} checked={currentValue === value} type="checkbox" name="filter" value={value} className="appearance-none w-4 aspect-square ring-offset-2 ring-offset-text-bg-500 ring-2 rounded-full ring-epink-500 bg-transparent checked:bg-epink-500 mr-4 md:mr-2 3xl:mr-4" />
        </label>
    );
}

FilterOption.propTypes = {
    value: PropTypes.string.isRequired,
};
