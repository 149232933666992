export const SHOW_ADVENTURE_CAROUSEL = 'SHOW_ADVENTURE_CAROUSEL';
export const SHOW_SPEAKER_CAROUSEL = 'SHOW_SPEAKER_CAROUSEL';
export const SHOW_WEEK1_MODAL = 'SHOW_WEEK1_MODAL';
export const SHOW_WEEK2_MODAL = 'SHOW_WEEK2_MODAL';
export const SHOW_WEEK3_MODAL = 'SHOW_WEEK3_MODAL';
export const SHOW_WEEK4_MODAL = 'SHOW_WEEK4_MODAL';
export const SHOW_WEEK5_MODAL = 'SHOW_WEEK5_MODAL';
export const SHOW_WEEK6_MODAL = 'SHOW_WEEK6_MODAL';
export const SHOW_WEEK7_MODAL = 'SHOW_WEEK7_MODAL';
export const SHOW_WEEK8_MODAL = 'SHOW_WEEK8_MODAL';
export const SHOW_WEEK1_MODAL_MOBILE = 'SHOW_WEEK1_MODAL_MOBILE';
export const SHOW_WEEK2_MODAL_MOBILE = 'SHOW_WEEK2_MODAL_MOBILE';
export const SHOW_WEEK3_MODAL_MOBILE = 'SHOW_WEEK3_MODAL_MOBILE';
export const SHOW_WEEK4_MODAL_MOBILE = 'SHOW_WEEK4_MODAL_MOBILE';
export const SHOW_WEEK5_MODAL_MOBILE = 'SHOW_WEEK5_MODAL_MOBILE';
