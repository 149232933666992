import { useTranslation } from 'react-i18next';

import Podcast1 from 'assets/image/chuyenZ.jpg';
import Podcast2 from 'assets/image/Podcast2.png';
import Podcast3 from 'assets/image/Podcast3.png';
import Podcast4 from 'assets/image/Podcast4.png';
import Podcast5 from 'assets/image/Podcast5.png';
import Podcast6 from 'assets/image/Podcast6.png';
import Podcast7 from 'assets/image/Podcast7.png';
import Podcast8 from 'assets/image/Podcast8.png';

export const PodcastUrl = [
    'episode/6ffu4H99cC8OglGSoYGnxf',
    'show/5IyxYnPv0905XdTUxeDQkj',
    'show/5KuVFavG72i7fNOZ9tEX3a',
    'show/3J16TsZjA8LYpl2KHypHry',
    'show/4Z9mC6MMUcxaNRuwH39882',
    'show/524qliZmwyXLOClyk8gV9T',
    'show/4JhofrAosoZPrp8OY68yZ7',
    'show/1KCKmwzycA9VOBDJCQ4S7Y',
];

export function PodcastContent() {
    const { t } = useTranslation();
    const Content = {
        ChuyenZ_LaThu1: {
            order: 0,
            podcastUrl: 'episode/6ffu4H99cC8OglGSoYGnxf',
            pictureUrl: Podcast1,
            title: t('podcast.0.title'),
            host: 'Chuyện Z',
            audio: 'https://p.scdn.co/mp3-preview/787db31cc9ecf53af1a6a92383f81bb3433cd2b3',
            content: t('podcast.0.description'),
        },
        // Podcast2: {
        //     order: 1,
        //     podcastUrl: 'show/5IyxYnPv0905XdTUxeDQkj',
        //     pictureUrl: Podcast2,
        //     title: 'Conversations with People Who Hate Me',
        //     host: 'Dylan Marron',
        //     audio: 'http://p.scdn.co/mp3-preview/b38178df3d45f2640f4a15d38ecf517e746af725',
        //     content: 'In an internet era characterized by comment section wars, devastating clapbacks, and anonymous vitriol, Dylan Marron explores what happens when online feuders step out from behind the keyboard and get to know the human on the other side of the screen.',
        // },
        ChuyenZ_LaThu2: {
            order: 2,
            podcastUrl: 'episode/23QqjWS4iYRV6zLJ5uZyUD',
            pictureUrl: Podcast1,
            title: t('podcast.1.title'),
            audio: 'https://p.scdn.co/mp3-preview/dece48ffafdae65850493b39e9a218a4623ecd83',
            host: 'Chuyện Z',
            content: t('podcast.1.description'),
        },
        // Podcast4: {
        //     order: 3,
        //     podcastUrl: 'show/3J16TsZjA8LYpl2KHypHry',
        //     pictureUrl: Podcast4,
        //     title: 'Checking In with Susan David',
        //     host: 'Susan David',
        //     audio: 'http://p.scdn.co/mp3-preview/873b6bea76b21f3922aee2787be20c0577699a87',
        //     content: "Fear, boredom, grief, confusion—we're all feeling... a lot right now. How do we cope with our heightened emotions during this global pandemic? Susan David—a psychologist at Harvard Medical School—is here to offer us strategies.",
        // },
        ChuyenZ_LaThu3: {
            order: 4,
            podcastUrl: 'episode/5aJa0cz9f06tVSKxwOBVCy',
            pictureUrl: Podcast1,
            title: t('podcast.2.title'),
            audio: 'http://p.scdn.co/mp3-preview/8cbf6406e082f6c9c21328ba2d942007192bd402',
            host: 'Chuyện Z',
            content: t('podcast.2.description'),
        },
        // Podcast6: {
        //     order: 5,
        //     podcastUrl: 'show/5KuVFavG72i7fNOZ9tEX3a',
        //     pictureUrl: Podcast3,
        //     title: 'How to Be a Better Human',
        //     host: 'TED and PRX',
        //     audio: 'http://p.scdn.co/mp3-preview/d3a49e144ca085babcca77d90644445485b19f97',
        //     content: 'How to Be a Better Human isn’t your average self improvement podcast. Each week join comedian Chris Duffy in conversation with guests and past speakers as they uncover sharp insights and give clear takeaways on how YOU can be a better human.',
        // },
        ChuyenZ_LaThu4: {
            order: 6,
            podcastUrl: 'episode/3rzErb3ER9m9j3LNQjBVRg',
            pictureUrl: Podcast1,
            title: t('podcast.3.title'),
            audio: 'https://p.scdn.co/mp3-preview/7942c9ed360bd3205917475d6df524f16478cb68',
            host: 'Chuyện Z',
            content: t('podcast.3.description'),
        },
        // Podcast8: {
        //     order: 7,
        //     podcastUrl: 'show/1KCKmwzycA9VOBDJCQ4S7Y',
        //     pictureUrl: Podcast8,
        //     title: 'TEDxSHORTS',
        //     host: 'TED and PRX',
        //     audio: 'http://p.scdn.co/mp3-preview/bb4319e96a5d1407bc1988d4e568459271923a37',
        //     content: "Start each day with short, eye-opening ideas from some of the world's greatest TEDx speakers. Hosted by Atossa Leoni, TEDx SHORTS will immerse you in surprising knowledge, fresh perspectives, and moving stories from some of our most compelling talks. Less than 10 minutes a day, everyday.",
        // },
        ChuyenZ_LaThu5: {
            order: 8,
            podcastUrl: 'episode/76Icul1TTZnYUhygOE2LJm',
            pictureUrl: Podcast1,
            title: t('podcast.4.title'),
            audio: 'http://p.scdn.co/mp3-preview/9094541f10da45c139eb75d4669df23224a8761d',
            host: 'Chuyện Z',
            content: t('podcast.4.description'),
        },
        // Podcast9: {
        //     order: 7,
        //     podcastUrl: 'show/4JhofrAosoZPrp8OY68yZ7',
        //     pictureUrl: Podcast7,
        //     title: 'Body Stuff with Dr.Jen Gunter',
        //     host: 'Dr.Jen Gunter',
        //     audio: 'http://p.scdn.co/mp3-preview/70ed5e0e222dbcd0c7a44b6bc4700084d22eed48',
        //     content: 'Should I do a juice cleanse? Do I really need 8 glasses of water a day? Is it actually possible to "boost" my immune system? You’re constantly bombarded with news stories, ads, and social media posts telling you how to optimize your health—but a lot of these sources that pretend to be backed by science have another agenda.',
        // },
    };

    return Content;
}

export const DemoAudio = 'https://p.scdn.co/mp3-preview/787db31cc9ecf53af1a6a92383f81bb3433cd2b3';
export const PodcastAudio = [
    'https://p.scdn.co/mp3-preview/787db31cc9ecf53af1a6a92383f81bb3433cd2b3',
    'https://p.scdn.co/mp3-preview/dece48ffafdae65850493b39e9a218a4623ecd83',
    'http://p.scdn.co/mp3-preview/8cbf6406e082f6c9c21328ba2d942007192bd402',
    'https://p.scdn.co/mp3-preview/7942c9ed360bd3205917475d6df524f16478cb68',
    'http://p.scdn.co/mp3-preview/9094541f10da45c139eb75d4669df23224a8761d',
];
