import React from 'react';
import { useNavigate } from 'react-router-dom';

import Tmp from 'assets/image/tmp.png';

export default function BlogElement({
    blogNum, title, date, author, description, img,
}) {
    const navigate = useNavigate();
    return (
        <div
            className="w-full aspect-[4/5] bg-text-bg-600 p-5 rounded-[15px] flex flex-col cursor-pointer brightness-100 hover:brightness-50 transition-all duration-150"
            aria-hidden="true"
            onClick={() => navigate(`/blog/${blogNum}`)}
        >
            <img alt="temp" src={img} className="aspect-[3/2] rounded-[20px] object-cover" />
            <p className="max-h-16 mt-[10px] text-2xl font-bold text-ellipsis overflow-hidden">{title}</p>
            <p className="inline-block mt-[10px] text-base font-bold text-text-bg-300 max-h-[72px] line-clamp">
                {description}
            </p>
            <div className="flex justify-self-end justify-between mt-[10px]">
                <p className="text-[12px] font-bold text-text-bg-300">
                    {date.toLocaleDateString('en-us', {
                        month: 'short',
                        year: 'numeric',
                        day: 'numeric',
                    })}

                </p>
                <p className="text-[12px] font-bold text-text-bg-300">{author}</p>
            </div>
        </div>
    );
}
