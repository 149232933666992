import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Logo from 'assets/image/logo.png';
import i18n from 'i18n';

import NavbarDesktopMenu from './NavbarDesktopMenu';
import NavbarMobileMenu from './NavbarMobileMenu';

export default function Navbar() {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => setMenuOpen(!menuOpen);
    const location = useLocation();
    const mainRoute = location.pathname.split('/')[1];

    return (

        <nav>
            <div className={`z-50 relative flex items-center justify-center py-[7px] md:py-5 3xl:py-11 px-[15px] md:px-[116px] lg:px-[120px] 3xl:px-[240px] ${menuOpen && 'bg-text-bg-500'} transition-all duration-300 ease-out mb-[2%]`}>
                <div className="max-w-[1420px] w-full flex items-center justify-between xl:text-sm 2xl:text-base">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = '/';
                        }}
                        className="3xl:w-[328px] md:w-[220px] w-[170px] aspect-[329/60] mr-[20px]"
                        type="button"
                    >
                        <img className="w-[328px] aspect-[329/60]" src={Logo} alt="TedxLogo" />
                    </button>
                    <NavbarDesktopMenu mainRoute={mainRoute} />
                    <NavbarMobileMenu menuOpen={menuOpen} toggleMenu={toggleMenu} />
                </div>
            </div>
            <div id="menubar" className={`z-50 xl:hidden ${menuOpen ? 'h-[484px] visible opacity-100' : 'h-0 invisible opacity-0'} absolute top-[45px] md:top-[80.12px] bg-text-bg-500 w-screen py-[30px] flex flex-col gap-10 transition-all duration-300 ease-out`}>
                <Link to="/event" className={`block w-full text-center text-white hover:text-blue-500 ${mainRoute === 'event' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.event')}</Link>
                <Link to="/learn" className={`block w-full text-center text-white hover:text-blue-500 ${mainRoute === 'learn' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.learn')}</Link>
                <a href="/podcast" className={`block w-full text-center text-white hover:text-blue-500 ${mainRoute === 'podcast' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.podcast')}</a>
                <a href="/sponsor" className={`block w-full text-center text-white hover:text-blue-500 ${mainRoute === 'sponsor' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.sponsor')}</a>
                <Link to="/contact" className={`block w-full text-center text-white hover:text-blue-500 ${mainRoute === 'contact' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.contact')}</Link>
                <Link to="/gallery" className={`block w-full text-center text-white hover:text-blue-500 ${mainRoute === 'gallery' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.gallery')}</Link>
                {/* <a href="/" className={`block w-full text-center text-white hover:text-blue-500 ${mainRoute === 'booking' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.book')}</a> */}
                <div className="flex w-full justify-center md:gap-[20px]">
                    <button
                        className={`cursor-pointer flex justify-center items-center md:border-solid md:border-2 md:border-white rounded-full w-10 h-10 text-white ${
                            i18n.resolvedLanguage !== 'vn' && 'opacity-40'
                        }`}
                        onClick={() => i18n.changeLanguage('vn')}
                        type="button"
                    >
                        vn
                    </button>
                    <button
                        className={`cursor-pointer flex justify-center items-center md:border-solid md:border-2 md:border-white rounded-full w-10 h-10 text-white ${
                            i18n.resolvedLanguage !== 'en' && 'opacity-40'
                        }`}
                        onClick={() => i18n.changeLanguage('en')}
                        type="button"
                    >
                        en
                    </button>
                </div>
            </div>
        </nav>
    );
}
