import React, { useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow_right.svg';
import PauseIcon from 'assets/icons/pause_icon.svg';
import PlayIcon from 'assets/icons/play_icon.svg';
import PodcastImg from 'assets/image/chuyenZ.jpg';
import SpotifyDemo from 'assets/image/podcast_demo.png';
import SpotifyLogo from 'assets/image/Spotify_Logo.svg';
import { PodcastAudio } from 'db/podcast';

import HomepagePodcastOverlay from '../Homepage/HomepagePodcastOverlay';

import Title from './Title';

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

    const { t } = useTranslation();

    return (
        <button type="button" className={`${isFirstItemVisible ? 'hidden' : 'flex'} absolute z-10 left-0 top-[45%] w-10 h-10 bg-black rounded-full border-2 border-blue-500 justify-center items-center`} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <LeftArrowIcon />
        </button>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    const { t } = useTranslation();

    return (
        <button
            type="button"
            className={`${isLastItemVisible ? 'hidden' : 'flex'} absolute z-10 right-0 top-[45%] w-10 h-10 bg-black rounded-full border-2 border-blue-500 justify-center items-center`}
            disabled={isLastItemVisible}
            onClick={() => scrollNext()}
        >
            <RightArrowIcon />
        </button>
    );
}
export default function Podcast() {
    let sound;
    sound = new Audio(PodcastAudio[0]);
    const [play, setPlay] = useState({
        one: false, two: false, three: false, four: false, five: false,
    });
    const [overlay, setOverlay] = useState({
        one: false, two: false, three: false, four: false, five: false,
    });
    const [audio, setAudio] = useState(sound);
    const { t } = useTranslation();

    return (
        <div>
            <div className="intro w-screen h-content flex flex-col justify-between align-start m-auto mt-0 pt-0 bg-[#1D1C1C] md:px-[20px] lg:px-[96px] xl:px-[160px] 3xl:px-[240px]">
                <div className="w-full h-[360px] h-content flex flex-row space-x-8 align-start  bg-[#1D1C1C] pt-0 z-20">
                    <a href="https://open.spotify.com/show/3OOyHkhtOv8mVXWb6sjjuk" className="rounded-[20px] border-2 border-text-bg-300 flex flex-col min-w-[200px] lg:min-w-[280px] 3xl:min-w-[340px] h-[320px] lg:h-[360px] 3xl:h-[460px] self-center justify-center items-center space-y-5">
                        <p className="text-lg lg:text-2xl font-bold leading-[27px] text-center">{t('title.podcast.follow')}</p>
                        <img src={SpotifyLogo} alt="Spotify Logo" />
                    </a>
                    <div className="podcast w-full overflow-x-auto no-scrollbar relative px-5">
                        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} className="podcastScroll h-[320px] lg:h-[400px] 3xl:h-[460px] space-x-[15px]" id="podcast_element_mobile">
                            {!overlay.one ? (
                                <div aria-hidden className="flex-none w-[360px] lg:w-[480px] 3xl:w-[580px] rounded-[20px] relative cursor-pointer" onClick={() => setOverlay({ ...overlay, one: true })}>
                                    <img className="w-[360px] lg:w-[480px] 3xl:w-[580px] h-[320px] lg:h-[360px] 3xl:h-[460px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                                    <div className="flex items-center justify-between w-[360px] lg:w-[480px] 3xl:w-[580px] h-[80px] lg:h-[100px] px-5 lg:px-10 py-5 bg-black opacity-80 absolute bottom-0 rounded-b-[20px]">
                                        <div className="flex flex-col">
                                            <p className="text-lg lg:text-xl 3xl:text-2xl font-bold leading-[21px]">{t('home.4.0.title')}</p>
                                            <p className="md:text-base font-medium text-text-bg-100">{t('title.podcast.origin')}</p>
                                        </div>
                                        {play.one ? (
                                            <img
                                                src={PauseIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    setPlay({ ...play, one: false });
                                                }}
                                                className="h-4"
                                                alt="Pause"
                                            />
                                        ) : (
                                            <img
                                                src={PlayIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    sound = new Audio(PodcastAudio[0]);
                                                    sound.play();
                                                    setAudio(sound);
                                                    setPlay({
                                                        one: true, two: false, three: false, four: false, five: false,
                                                    });
                                                }}
                                                className="h-4"
                                                alt="Play"
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <HomepagePodcastOverlay title={t('home.4.0.title')} description={t('home.4.0.description')} url="6ffu4H99cC8OglGSoYGnxf" closeOverlay={() => setOverlay({ ...overlay, one: false })} audioSrc={PodcastAudio[0]} />
                            )}
                            {!overlay.two ? (
                                <div aria-hidden className="flex-none w-[360px] lg:w-[480px] 3xl:w-[580px] rounded-[20px] relative cursor-pointer" onClick={() => setOverlay({ ...overlay, two: true })}>
                                    <img className="w-[360px] lg:w-[480px] 3xl:w-[580px] h-[320px] lg:h-[360px] 3xl:h-[460px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                                    <div className="flex items-center justify-between w-[360px] lg:w-[480px] 3xl:w-[580px] h-[80px] lg:h-[100px] px-5 lg:px-10 py-5  bg-black opacity-80 absolute bottom-0 rounded-b-[20px]">
                                        <div className="flex flex-col">
                                            <p className="text-lg lg:text-xl 3xl:text-2xl font-bold leading-[21px]">{t('home.4.1.title')}</p>
                                            <p className="font-medium md:text-base text-text-bg-100">{t('title.podcast.origin')}</p>
                                        </div>
                                        {play.two ? (
                                            <img
                                                src={PauseIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    setPlay({ ...play, two: false });
                                                }}
                                                className="h-4"
                                                alt="Pause"
                                            />
                                        ) : (
                                            <img
                                                src={PlayIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    sound = new Audio(PodcastAudio[1]);
                                                    sound.play();
                                                    setAudio(sound);
                                                    setPlay({
                                                        one: false, two: true, three: false, four: false, five: false,
                                                    });
                                                }}
                                                className="h-4"
                                                alt="Play"
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <HomepagePodcastOverlay title={t('home.4.1.title')} description={t('home.4.1.description')} url="23QqjWS4iYRV6zLJ5uZyUD" closeOverlay={() => setOverlay({ ...overlay, two: false })} audioSrc={PodcastAudio[1]} />
                            )}
                            {!overlay.three ? (
                                <div aria-hidden className="flex-none w-[360px] lg:w-[480px] 3xl:w-[580px] rounded-[20px] relative cursor-pointer" onClick={() => setOverlay({ ...overlay, three: true })}>
                                    <img className="w-[360px] lg:w-[480px] 3xl:w-[580px] h-[320px] lg:h-[360px] 3xl:h-[460px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                                    <div className="flex items-center justify-between w-[360px] lg:w-[480px] 3xl:w-[580px] h-[80px] lg:h-[100px] px-5 lg:px-10 py-5  bg-black opacity-80 absolute bottom-0 rounded-b-[20px]">
                                        <div className="flex flex-col">
                                            <p className="text-lg lg:text-xl 3xl:text-2xl font-bold leading-[21px]">{t('home.4.2.title')}</p>
                                            <p className="font-medium md:text-base text-text-bg-100">{t('title.podcast.origin')}</p>
                                        </div>
                                        {play.three ? (
                                            <img
                                                src={PauseIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    setPlay({ ...play, three: false });
                                                }}
                                                className="h-4"
                                                alt="Pause"
                                            />
                                        ) : (
                                            <img
                                                src={PlayIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    sound = new Audio(PodcastAudio[2]);
                                                    sound.play();
                                                    setAudio(sound);
                                                    setPlay({
                                                        one: false, two: false, three: true, four: false, five: false,
                                                    });
                                                }}
                                                className="h-4"
                                                alt="Play"
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <HomepagePodcastOverlay title={t('home.4.2.title')} description={t('home.4.2.description')} url="5aJa0cz9f06tVSKxwOBVCy" closeOverlay={() => setOverlay({ ...overlay, three: false })} audioSrc={PodcastAudio[2]} />
                            )}
                            {!overlay.four ? (
                                <div aria-hidden className="flex-none w-[360px] lg:w-[480px] 3xl:w-[580px] rounded-[20px] relative cursor-pointer" onClick={() => setOverlay({ ...overlay, four: true })}>
                                    <img className="w-[360px] lg:w-[480px] 3xl:w-[580px] h-[320px] lg:h-[360px] 3xl:h-[460px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                                    <div className="flex items-center justify-between w-[360px] lg:w-[480px] 3xl:w-[580px] h-[80px] lg:h-[100px] px-5 lg:px-10 py-5  bg-black opacity-80 absolute bottom-0 rounded-b-[20px]">
                                        <div className="flex flex-col">
                                            <p className="text-lg lg:text-xl 3xl:text-2xl font-bold leading-[21px]">{t('home.4.3.title')}</p>
                                            <p className="font-medium md:text-base text-text-bg-100">{t('title.podcast.origin')}</p>
                                        </div>
                                        {play.four ? (
                                            <img
                                                src={PauseIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    setPlay({ ...play, four: false });
                                                }}
                                                className="h-4"
                                                alt="Pause"
                                            />
                                        ) : (
                                            <img
                                                src={PlayIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    sound = new Audio(PodcastAudio[3]);
                                                    sound.play();
                                                    setAudio(sound);
                                                    setPlay({
                                                        one: false, two: false, three: false, four: true, five: false,
                                                    });
                                                }}
                                                className="h-4"
                                                alt="Play"
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <HomepagePodcastOverlay title={t('home.4.3.title')} description={t('home.4.3.description')} url="3rzErb3ER9m9j3LNQjBVRg" closeOverlay={() => setOverlay({ ...overlay, four: false })} audioSrc={PodcastAudio[3]} />
                            )}
                            {!overlay.five ? (
                                <div aria-hidden className="flex-none w-[360px] lg:w-[480px] 3xl:w-[580px] rounded-[20px] relative cursor-pointer" onClick={() => setOverlay({ ...overlay, five: true })}>
                                    <img className="w-[360px] lg:w-[480px] 3xl:w-[580px] h-[320px] lg:h-[360px] 3xl:h-[460px] rounded-[20px]" src={PodcastImg} alt="First Podcast" />
                                    <div className="flex items-center justify-between w-[360px] lg:w-[480px] 3xl:w-[580px] h-[80px] lg:h-[100px] px-5 lg:px-10 py-5  bg-black opacity-80 absolute bottom-0 rounded-b-[20px]">
                                        <div className="flex flex-col">
                                            <p className="text-lg lg:text-xl 3xl:text-2xl font-bold leading-[21px]">{t('home.4.4.title')}</p>
                                            <p className="font-medium md:text-base text-text-bg-100">{t('title.podcast.origin')}</p>
                                        </div>
                                        {play.five ? (
                                            <img
                                                src={PauseIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    setPlay({ ...play, five: false });
                                                }}
                                                className="h-4"
                                                alt="Pause"
                                            />
                                        ) : (
                                            <img
                                                src={PlayIcon}
                                                aria-hidden
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    audio.pause();
                                                    sound = new Audio(PodcastAudio[4]);
                                                    sound.play();
                                                    setAudio(sound);
                                                    setPlay({
                                                        one: false, two: false, three: false, four: false, five: true,
                                                    });
                                                }}
                                                className="h-4"
                                                alt="Play"
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <HomepagePodcastOverlay title={t('home.4.4.title')} description={t('home.4.4.description')} url="76Icul1TTZnYUhygOE2LJm" closeOverlay={() => setOverlay({ ...overlay, five: false })} audioSrc={PodcastAudio[4]} />
                            )}
                        </ScrollMenu>

                    </div>
                </div>
            </div>
            <Title>
                <p className="text-2xl 13i:text-3xl 27i:text-[44px] font-bold tracking-wide">
                    <span className="text-red-500">Podcast</span>
                    {' '}
                    {t('title.podcast.listen')}
                </p>
                <Link to="/podcast" className="z-50 cursor-pointer text-base text-[#EB33FF] font-semibold">{t('title.more')}</Link>
            </Title>
        </div>
    );
}
