import PropTypes from 'prop-types';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

import SpotifySmallLogo from 'assets/image/Spotify_Small_Logo.svg';
import 'index.css';
import { DemoAudio } from 'db/podcast';

export default function PodcastDetail({
    title, host, content, podcastUrl, url, audio,
}) {
    return (
        <div className="p-[15px] md:p-3 3xl:p-2 flex flex-row space-x-[15px] md:space-x-5 border-2 md:border-[5px] border-text-bg-300 bg-text-bg-500 rounded-[15px] w-[345px] md:w-[459px] 3xl:w-[700px] 3xl:h-[660px]">
            <div className="w-[135px] md:w-[240px] 3xl:w-[300px] flex flex-col">
                <h1 className="text-sm md:text-lg lg:text-2xl font-bold leading-[21px] md:leading-9 mb-[5px]">{title}</h1>
                <p className="text-[10px] md:text-sm lg:text-base font-medium text-text-bg-100 mb-[5px] md:mb-5">{host}</p>
                <p className="text-[10px] md:text-lg 3xl:text-2xl font-medium text-text-bg-100 line-clamp-5">{content}</p>
            </div>
            <div className="flex flex-col items-center">
                <img src={url} className="w-[165px] h-[165px] 3xl:w-[340px] 3xl:h-[340px] rounded-[15px]" alt="Podcast1" />
                {/* <div className="relative overflow-y-hidden rounded-[10px] w-[165px] xl:w-[340px]">
                    <iframe title="Podcast" src={`https://open.spotify.com/embed/${podcastUrl}?utm_source=generator`} className="h-[165px] md:h-[232px] w-80% xl:w-[340px]" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
                </div> */}
                <ReactAudioPlayer
                    className="w-[165px] 3xl:w-[300px] h-7 3xl:h-[48px] mt-[5px] md:mt-5"
                    src={audio}
                    controls
                />
                <a href={`https://open.spotify.com/${podcastUrl}`} className="mt-[15px] 3xl:mt-[44px] flex items-center justify-center rounded-[48px] w-[165px] 3xl:w-[340px] h-[34px] md:h-[48px] bg-white">
                    <p className="text-text-bg-500 text-[10px] md:text-base font-medium">Nghe Tiếp</p>
                    <img className="ml-[10px] w-6 h-6" src={SpotifySmallLogo} alt="Podcast" />
                </a>
            </div>
        </div>
    );
}
PodcastDetail.propTypes = {
    title: PropTypes.string,
    host: PropTypes.string,
    content: PropTypes.string,
    url: PropTypes.string,
    podcastUrl: PropTypes.string,
    audio: PropTypes.string,
};
PodcastDetail.defaultProps = {
    title: '',
    host: '',
    content: '',
    url: '',
    podcastUrl: '',
    audio: '',
};
