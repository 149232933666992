import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FilterIcon from 'assets/icons/filter.svg';

import FilterOption from './FilterOption';

export default function FilterBar({
    year, genre, sortType, setYear, setGenre, setSortType,
}) {
    const { t } = useTranslation();
    return (
        <div className="sticky top-0 min-w-[220px] h-fit p-5 space-y-10 mb-[168]">
            <div className="flex space-x-5 items-center">
                <div className="self-center flex justify-center items-center border-solid border-2 border-blue-500 rounded-full md:w-[48px] aspect-square p-[4px] md:p-[6px] text-white">
                    <img className="w-4 aspect-square" src={FilterIcon} alt="FilterIcon" />
                </div>
                <span className="font-bold md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-center h-fit leading-[150%]">{t('learn.filterTitle')}</span>
            </div>
            <form className="space-y-[10px]" onChange={(e) => setYear(e.target.value)} onSubmit={(e) => e.preventDefault()}>
                <span className="font-bold md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-center h-fit leading-[150%]">{t('learn.filter.0.title')}</span>
                <FilterOption value="2022" text="2022" currentValue={year} />
                <FilterOption value="2019" text="2019" currentValue={year} />
            </form>
            <form className="space-y-[10px]" onChange={(e) => setGenre(e.target.value)} onSubmit={(e) => e.preventDefault()}>
                <span className="font-bold md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-center h-fit leading-[150%]">{t('learn.filter.1.title')}</span>
                <FilterOption value="copmac" text={t('learn.filter.1.options.0')} currentValue={genre} />
                <FilterOption value="collection" text={t('learn.filter.1.options.1')} currentValue={genre} />
            </form>
            <form className="space-y-[10px]" onChange={(e) => setSortType(e.target.value)} onSubmit={(e) => e.preventDefault()}>
                <span className="font-bold md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-center h-fit leading-[150%]">{t('learn.filter.2.title')}</span>
                <FilterOption value="newest" text={t('learn.filter.2.options.0')} currentValue={sortType} />
                <FilterOption value="oldest" text={t('learn.filter.2.options.1')} currentValue={sortType} />
            </form>
        </div>
    );
}
