import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelIcon from 'assets/icons/cancel.svg';
import VideoElement from 'components/LearnPageDesktop/VideoElement';
import { VideoContent as VideoContentEN } from 'script/en/learn';
import { VideoContent as VideoContentVN } from 'script/vn/learn';

export default function VideoSection({
    year, genre, sortType, resetYear, resetType, resetAll,
}) {
    const { t, i18n } = useTranslation();

    const VideoContent = i18n.language === 'en' ? VideoContentEN : VideoContentVN;

    return (
        <>
            {!year && !genre && !sortType && (
                <section>
                    <h1 className="font-bold text-lg md:text-[36px] 2xl:text-[54px] leading-[120%] text-end md:mb-[25.6px] xl:mb-8 2xl:mb-10">{t('learn.video.featured')}</h1>
                    <div className="grid grid-cols-3 grid-rows-2 gap-2 xl:gap-4 2xl:gap-5">
                        <a
                            href={`https://www.youtube.com/watch?v=${VideoContent[0].src}`}
                            className="col-span-2 row-span-2 w-full aspect-[700/546] rounded-[20px] cursor-pointer brightness-100 hover:brightness-50 transition-all duration-150"
                            aria-hidden="true"
                        >
                            <img id="img" draggable="false" className="aspect-[700/546] object-cover rounded-[20px]" alt="yt-video" src={`https://img.youtube.com/vi/${VideoContent[0].src}/maxresdefault.jpg`} />
                        </a>
                        <a
                            href={`https://www.youtube.com/watch?v=${VideoContent[1].src}`}
                            className="w-full aspect-[700/546] rounded-[20px] cursor-pointer brightness-100 hover:brightness-50 transition-all duration-150"
                            aria-hidden="true"
                        >
                            <img id="img" draggable="false" className="aspect-[700/546] object-cover rounded-[20px]" alt="yt-video" src={`https://img.youtube.com/vi/${VideoContent[1].src}/maxresdefault.jpg`} />
                        </a>
                        <a
                            href={`https://www.youtube.com/watch?v=${VideoContent[2].src}`}
                            className="w-full aspect-[700/546] rounded-[20px] cursor-pointer brightness-100 hover:brightness-50 transition-all duration-150"
                            aria-hidden="true"
                        >
                            <img id="img" draggable="false" className="aspect-[700/546] object-cover rounded-[20px]" alt="yt-video" src={`https://img.youtube.com/vi/${VideoContent[2].src}/maxresdefault.jpg`} />
                        </a>
                    </div>
                </section>
            )}
            {(year !== '' || genre) && (
                <div className="flex space-x-[15px]">
                    {year !== '' && (
                        <div className="flex bg-text-bg-50 w-[203px] h-[60px] mb-[30px] justify-center items-center space-x-[10px] rounded-[10px]">
                            <p className="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold text-text-bg-500">
                                {t('learn.year')}
                                {' '}
                                {year}
                            </p>
                            <button type="button" onClick={resetYear}>
                                <img className="w-4 h-4" src={CancelIcon} alt="Cancel" />
                            </button>
                        </div>
                    )}
                    {genre !== '' && (
                        <div className="flex bg-text-bg-50 w-[203px] h-[60px] mb-[30px] justify-center items-center space-x-[10px] rounded-[10px]">
                            <p className="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold text-text-bg-500">
                                {
                                    genre === 'copmac' ? t('learn.filter.1.options.0') : t('learn.filter.1.options.1')
                                }

                            </p>
                            <button type="button" onClick={resetType}>
                                <img src={CancelIcon} alt="Cancel" />
                            </button>
                        </div>
                    )}
                    {(year !== '' || genre !== '') && (<button type="button" onClick={resetAll} className="md:text-md lg:text-lg xl:text-xl 2xl:text-2xl  font-bold mb-[30px] underline self-center">{t('learn.deleteChoice')}</button>)}
                </div>
            )}
            <section>
                <h1 className="font-bold text-lg md:text-[36px] 2xl:text-[54px] text-end leading-[120%] md:mb-[25.6px] xl:mb-8 2xl:mb-10">{t('learn.all')}</h1>
                <div className="grid grid-cols-2 xl:grid-cols-3 gap-2 xl:gap-3 2xl:gap-5">
                    {(sortType === 'asc' || !sortType ? VideoContent : VideoContent.reverse()).filter((el) => {
                        if ((!year || year === el.date.getFullYear().toString()) && (!genre || genre === el.genreId || genre === 'All')) { return true; }
                        return false;
                    }).map((el) => <VideoElement key={el.src} videoId={el.src} title={el.title} author={el.author} date={el.date} />)}
                </div>
            </section>
        </>
    );
}
