import { t } from 'i18next';
import React from 'react';

import LearnPageMobile from 'components/LearnPageMobile';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

import LearnPageDesktop from './LearnPageDesktop';

export default function LearnPage() {
    useTitle(t('nav.learn'), DEFAULT_TITLE);
    return (
        <div>
            <LearnPageDesktop />
            <LearnPageMobile />
        </div>
    );
}
