import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Form() {
    const formRef = useRef(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [thamdu, setThamdu] = useState(false);
    const [taitro, setTaitro] = useState(false);
    const [ctv, setCtv] = useState(false);
    const [other, setOther] = useState(false);

    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            Name: name,
            Email: email,
            Message: message,
            ThamDu: thamdu,
            TaiTro: taitro,
            CongTacVien: ctv,
            Other: other,
        };
        axios.post('https://sheet.best/api/sheets/bc298ee4-b5b6-4659-941a-ddaa73c28714', data).then((response) => {
            setEmail('');
            setName('');
            setMessage('');
            setThamdu(false);
            setTaitro(false);
            setCtv(false);
            setOther(false);
        });
    };
    return (
        <form method="post" ref={formRef} onSubmit={handleSubmit} name="google-sheet" className="right-container w-full md:w-[320px] lg:w-[420px] xl:w-[510px] 3xl:w-[700px] max-h-[640px] p-2 lg:p-[24px] border-solid border-2 border-text-bg-300 rounded-[15px] flex flex-col justify-between gap-1 items-stretch contact-form">
            <p className="form-title text-center text-sm lg:text-base xl:text-xl text-white font-bold w-full mb-1 lg:mb-2">{t('home.6.0.header')}</p>
            <input onChange={(e) => setName(e.target.value)} value={name} className="shadow bg-transparent appearance-none border border-text-bg-300 rounded w-full py-2 px-3 text-sm lg:text-base text-text-bg-100 focus:outline-none focus:shadow-outline " id="username" type="text" name="name" placeholder={t('home.6.0.name')} />

            <input onChange={(e) => setEmail(e.target.value)} value={email} className="shadow bg-transparent appearance-none border border-text-bg-300 rounded w-full py-2 px-3 text-sm lg:text-base text-text-bg-100 focus:outline-none focus:shadow-outline " id="email" type="email" name="email" placeholder={t('home.6.0.email')} />

            <div className="checkbox-lst flex flex-col md:flex-row items-center xl:items-start justify-evenly w-full">
                {/* <div className="flex items-center">
                    <label htmlFor="joinSelector" className="md:ml-1 text-xs lg:text-sm 3xl:text-base font-medium text-white dark:text-text-bg-300 flex items-center cursor-pointer">
                        <input id="joinSelector" type="checkbox" onChange={() => setThamdu(!thamdu)} value={thamdu} name="role" className="appearance-none w-2 xl:w-4 aspect-square ring-offset-2 ring-offset-text-bg-500 ring-1 rounded-full ring-text-bg-300 bg-transparent checked:bg-text-bg-300 mr-4 md:mr-2 3xl:mr-4" />
                        Tham dự
                    </label>
                </div> */}
                <div className="flex mt-[15px] md:mt-0 items-center h-5">
                    <label htmlFor="sponsorSelector" className="md:ml-1 xl:ml-1 3xl:ml-2 text-xs lg:text-sm 3xl:text-base font-medium text-white dark:text-text-bg-300 flex items-center cursor-pointer">
                        <input id="sponsorSelector" type="checkbox" onChange={() => setTaitro(!taitro)} value={taitro} className="appearance-none w-2 xl:w-4 aspect-square ring-offset-2 ring-offset-text-bg-500 ring-1 rounded-full ring-text-bg-300 bg-transparent checked:bg-text-bg-300 mr-4 md:mr-2 3xl:mr-4" />
                        {t('home.6.1.sponsor')}
                    </label>
                </div>
                <div className="flex mt-[15px] md:mt-0 items-center h-5">
                    <label htmlFor="collabSelector" className="md:ml-1 text-xs lg:text-sm 3xl:text-base font-medium text-white dark:text-text-bg-300 flex items-center cursor-pointer">
                        <input id="collabSelector" type="checkbox" onChange={() => setCtv(!ctv)} value={ctv} className="appearance-none w-2 xl:w-4 aspect-square ring-offset-2 ring-offset-text-bg-500 ring-1 rounded-full ring-text-bg-300 bg-transparent checked:bg-text-bg-300 mr-4 md:mr-2 3xl:mr-4" />
                        {t('home.6.1.collaborate')}
                    </label>
                </div>
                <div className="flex mt-[15px] md:mt-0 items-center h-5">
                    <label htmlFor="otherSelector" className="md:ml-1 text-xs lg:text-sm 3xl:text-base font-medium text-white dark:text-text-bg-300 flex items-center cursor-pointer">
                        <input id="otherSelector" type="checkbox" onChange={() => setOther(!other)} value={other} className="appearance-none w-2 xl:w-4 aspect-square ring-offset-2 ring-offset-text-bg-500 ring-1 rounded-full ring-text-bg-300 bg-transparent checked:bg-text-bg-300 mr-4 md:mr-2 3xl:mr-4" />
                        {t('home.6.1.others')}
                    </label>
                </div>
            </div>
            <label htmlFor="message">
                <textarea onChange={(e) => setMessage(e.target.value)} value={message} id="message" name="message" rows={6} className=" h-[148px] resize-none shadow bg-transparent appearance-none border border-text-bg-300 w-full py-2 px-3 text-text-bg-100 focus:outline-none focus:shadow-outline no-scrollbar rounded-[5px]" placeholder={t('home.6.0.content')} />
            </label>
            <button type="submit" className="text-blue-500 hover:bg-blue-700 w-full bg-white 2xl:text-2xl font-bold py-2 px-4 3xl:rounded-[48px] md:rounded">
                {t('home.6.0.send')}
            </button>
        </form>
    );
}
