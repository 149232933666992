import { collection } from '@firebase/firestore';
import {
    getStorage, ref, listAll, getDownloadURL,
} from 'firebase/storage';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import ArenaLogo from 'assets/image/arena_logo.png';
import { ReactComponent as BigQuotaion } from 'assets/image/bigquotation.svg';
import BrandsLogo from 'assets/image/brands_vn_logo.png';
import BrowniesLogo from 'assets/image/brownies_logo.png';
import CanonLogo from 'assets/image/canon_logo.png';
import Edu2ReviewLogo from 'assets/image/edu2review_logo.png';
import GDSCLogo from 'assets/image/gdsc_logo.png';
import ImmplifyLogo from 'assets/image/immplify_event.jpeg';
import IVolunteerLogo from 'assets/image/ivolunteer_logo.png';
import KoikeyaLogo from 'assets/image/koikeya_logo.png';
import ZotLogo from 'assets/image/logo_zot.png';
import MrBrownLogo from 'assets/image/mrbrown_logo.png';
import RomanoLogo from 'assets/image/romano_logo.png';
import TraqueLogo from 'assets/image/Traque_logo.png';
import UniformLogo from 'assets/image/uniform_logo.png';
import UsshLogo from 'assets/image/ussh_logo.png';
import VedetteLogo from 'assets/image/vedette_logo.png';
import YBoxLogo from 'assets/image/ybox_logo.png';
import BTCMember from 'components/BTC/BTCMember';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

import storage1 from '../../firebase';

export default function Sponsor() {
    const [imgList, setImgList] = useState([]);
    const storage = getStorage();
    const img = ref(storage, '/btc');

    useEffect(() => {
        listAll(img).then((response) => {
            console.log(Object.values(response));
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImgList((prev) => [...prev, url]);
                });
            });
        });
    }, []);

    useTitle(t('nav.sponsor'), DEFAULT_TITLE);

    return (
        <div className="bg-text-bg-500 h-full flex flex-col">
            <div className="mb-[40px]">
                <Navbar />
            </div>
            <h1 className="text-[20px] md:text-4xl leading-[30px] md:leading-[54px] text-center font-bold md:font-normal text-red-500 uppercase">Supporting sponsor</h1>
            <div className="bg-tranparent py-[15px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] flex flex-col items-center">
                <img src={UsshLogo} alt="Ussh Logo" className="w-[142.5px] md:w-[340px]" />
            </div>
            <div className="pb-[30px] pt-[45px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] flex flex-col items-center">
                <h2 className="text-xl md:text-4xl leading-[30px] md:leading-[54px] text-center font-bold md:font-normal mb-[15px] md:mb-10 text-red-500 uppercase">Gold sponsor</h2>
                <div className="flex">
                    <img src={ArenaLogo} alt="Arena Logo" className="w-[142px] md:w-[340px]" />
                    <img src={RomanoLogo} alt="Romano Logo" className="w-[142px] md:w-[340px]" />
                </div>
            </div>
            <div className="py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] flex flex-col items-center">
                <h2 className="text-xl md:text-4xl leading-[30px] md:leading-[54px] text-center font-bold md:font-normal mb-[15px] md:mb-10 text-red-500 uppercase">Silver sponsor</h2>
                <img src={BrowniesLogo} alt="Brownies Logo" className="w-[120px] md:w-[340px]" />
            </div>
            <div className="py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] flex flex-col items-center">
                <h2 className="text-xl md:text-4xl leading-[30px] md:leading-[54px] text-center font-bold md:font-normal mb-[15px] md:mb-10 text-red-500 uppercase">In-kind sponsor</h2>
                <div className="flex flex-col space-y-[15px] items-center md:hidden">
                    <img src={MrBrownLogo} alt="MrBrown Logo" className="w-[50px]" />
                    <img src={CanonLogo} alt="Canon Logo" className="w-[180px]" />
                    <img src={KoikeyaLogo} alt="Koikeya Logo" className="w-[50px]" />
                    <img src={VedetteLogo} alt="Vedette VN Logo" className="w-[80px]" />
                    <img src={GDSCLogo} alt="GDSC Logo" className="w-[180px]" />
                </div>
                <div className="hidden md:flex flex-col space-y-10 items-center">
                    <div className="flex space-x-10">
                        <img src={MrBrownLogo} alt="MrBrown Logo" className="w-[126px]" />
                        <img src={CanonLogo} alt="Canon Logo" className="w-[280px]" />
                        <img src={KoikeyaLogo} alt="Koikeya Logo" className="w-[126px]" />
                    </div>
                    <div className="flex lg:space-x-10">
                        <img src={VedetteLogo} alt="Vedette VN Logo" className="w-[214px]" />
                        <img src={GDSCLogo} alt="GDSC Logo" className="w-[538px]" />
                    </div>
                    <div className="flex lg:space-x-10">
                        <img src={ZotLogo} alt="Zot Logo Logo" className="w-[180px] sm:w-[140px] sm:h-[140px] object-fill" />
                        <img src={TraqueLogo} alt="Traque Logo" className="w-[160px] sm:w-[140px] sm:h-[140px] h-[160px] object-contain bg-white" />
                    </div>
                    <div className="flex lg:space-x-10">
                        <img src={ImmplifyLogo} alt="w-[214px] Immplify Logo" className="w-[214px] bg-transparent object-fill" />
                    </div>
                </div>
            </div>
            <div className="py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] flex flex-col items-center">
                <h2 className="text-xl md:text-4xl leading-[30px] md:leading-[54px] text-center font-bold md:font-normal mb-[15px] md:mb-10 text-red-500 uppercase">Media sponsor</h2>
                <div className="flex md:hidden 2xl:flex flex-col items-center 2xl:flex-row space-y-[30px] xl:space-y-0 2xl:space-x-5 3xl:space-x-10">
                    <img src={IVolunteerLogo} alt="iVolunteer Logo" className="w-[120px] md:w-[310px]" />
                    <img src={YBoxLogo} alt="YBox Logo" className="w-[120px] md:w-[340px]" />
                    <img src={Edu2ReviewLogo} alt="Edu2Review Logo" className="w-[120px] md:w-[460px]" />
                    <img src={BrandsLogo} alt="Brands VN Logo" className="w-[120px] md:w-[340px]" />
                </div>
                <div className="hidden md:flex 2xl:hidden flex-col items-center space-y-[30px]">
                    <div className="flex space-x-10">
                        <img src={IVolunteerLogo} alt="iVolunteer Logo" className="w-[120px] md:w-[310px]" />
                        <img src={YBoxLogo} alt="YBox Logo" className="w-[120px] md:w-[340px]" />
                    </div>
                    <div className="flex space-x-10">
                        <img src={Edu2ReviewLogo} alt="Edu2Review Logo" className="w-[120px] md:w-[460px]" />
                        <img src={BrandsLogo} alt="Brands VN Logo" className="w-[120px] md:w-[340px]" />
                    </div>
                </div>
            </div>
            <div className="mb-10 lg:mb-[280px] py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] flex flex-col items-center">
                <h2 className="text-xl md:text-4xl leading-[30px] md:leading-[54px] text-center font-bold md:font-normal mb-[15px] md:mb-10 text-red-500 uppercase">Uniform sponsor</h2>
                <img src={UniformLogo} alt="Uniform Logo" className="w-[92px] md:w-[347px]" />
            </div>
            <Footer />
        </div>
    );
}
