import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Title from './Title';

export default function Video() {
    const { t } = useTranslation();
    const [embedId, setEmbedId] = useState('_l079QZ7jek');
    return (
        <div className="intro w-screen flex flex-row align-center justify-center wrap m-auto pt-0 px-[15px] md:px-[40px] lg:px-[120px] xl:px-[160px] 2xl:px-[240px] z-20">
            <div className="w-screen mt-[8px] flex space-x-5 wrap ">
                <div className="grow shrink basis-0 aspect-video p-5 rounded-[15px] bg-text-bg-600 h-[360px] overflow-y-scroll">
                    <div aria-hidden onClick={() => setEmbedId('_l079QZ7jek')}>
                        <h3 className="cursor-pointer font-bold text-lg lg:text-xl xl:text-2xl leading-[21px]">{t('homepage.videoContent.0.title')}</h3>
                        <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] max-h-[60px] overflow-y-hidden">{t('homepage.videoContent.0.description')}</p>
                    </div>
                    <div className="mt-[15px] mb-[30px] w-full h-[2px] bg-text-bg-300 rounded-full" />
                    <div aria-hidden onClick={() => setEmbedId('vsMKk5P0vVs')}>
                        <h3 className="cursor-pointer font-bold text-lg lg:text-xl xl:text-2xl leading-[21px]">{t('homepage.videoContent.1.title')}</h3>
                        <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] max-h-[60px] overflow-y-hidden">{t('homepage.videoContent.1.description')}</p>
                    </div>
                    <div className="mt-[15px] mb-[30px] w-full h-[2px] bg-text-bg-300 rounded-full" />
                    <div aria-hidden onClick={() => setEmbedId('Na7wqlXcVdg')}>
                        <h3 className="cursor-pointer font-bold text-lg lg:text-xl xl:text-2xl leading-[21px]">{t('homepage.videoContent.2.title')}</h3>
                        <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] max-h-[60px] overflow-y-hidden">{t('homepage.videoContent.2.description')}</p>
                    </div>
                    <div className="mt-[15px] mb-[30px] w-full h-[2px] bg-text-bg-300 rounded-full" />
                    <div aria-hidden onClick={() => setEmbedId('Pj6_UkhfRB4')}>
                        <h3 className="cursor-pointer font-bold text-lg lg:text-xl xl:text-2xl leading-[21px]">{t('homepage.videoContent.3.title')}</h3>
                        <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] max-h-[60px] overflow-y-hidden">{t('homepage.videoContent.3.description')}</p>
                    </div>
                    <div className="mt-[15px] mb-[30px] w-full h-[2px] bg-text-bg-300 rounded-full" />
                    <div aria-hidden onClick={() => setEmbedId('a7--cJD7k0Q')}>
                        <h3 className="cursor-pointer font-bold text-lg lg:text-xl xl:text-2xl leading-[21px]">{t('homepage.videoContent.4.title')}</h3>
                        <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] max-h-[60px] overflow-y-hidden">{t('homepage.videoContent.4.description')}</p>
                    </div>
                </div>
                <iframe className="grow shrink basis-0 aspect-video rounded-[15px] border-2 border-text-bg-300" src={`https://www.youtube.com/embed/${embedId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Embedded youtube" />
            </div>
            <Title>
                <p className="text-2xl 13i:text-3xl 27i:text-[44px] font-bold tracking-wide">
                    {t('title.video.1')}
                    {' '}
                    <span className="text-red-500">{t('title.video.2')}</span>
                    {' '}
                    {t('title.video.3')}
                </p>
                <a href="https://www.youtube.com/channel/UC0mHjlw596C1xswOyvGvhjg" className="z-50 cursor-pointer text-base text-[#EB33FF] font-semibold">{t('title.more')}</a>
            </Title>
        </div>
    );
}
