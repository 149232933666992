import { t } from 'i18next';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Zichuyen from '../../assets/image/Zichuyen.png';

export default function HomepageEvent() {
    const [event, setEvent] = useState({ one: true, two: false, three: false });
    const firstEvent = () => setEvent({ one: true, two: false, three: false });
    const secondEvent = () => setEvent({ one: false, two: true, three: false });
    const thirdEvent = () => setEvent({ one: false, two: false, three: true });
    return (
        <div className="px-[15px] mt-[60px]">
            <h1 className="text-xl font-bold leading-[30px]">
                {t('title.event.1')}
                {' '}
                <span className="text-TEDred">{t('title.event.2')}</span>
                {' '}
                {t('title.event.3')}
            </h1>
            <a href="https://tedx-gdsc.herokuapp.com/event" className="text-sm font-bold text-epink-500">{t('title.more')}</a>
            <div className="flex justify-center space-x-[5px] mt-[30px]">
                {/* <div
                    aria-hidden
                    onClick={firstEvent}
                    className={`bg-homepageEvent1 h-[140px] ${event.one ? 'w-[280px] opacity-100' : 'w-[27.5px] opacity-40'} rounded-[10px] bg-center transition-all duration-300`}
                />
                <div
                    aria-hidden
                    onClick={secondEvent}
                    className={`bg-homepageEvent2 h-[140px] ${event.two ? 'w-[280px] opacity-100' : 'w-[27.5px] opacity-40'} rounded-[10px] bg-center transition-all duration-300`}
                />
                <div
                    aria-hidden
                    onClick={thirdEvent}
                    className={`bg-homepageEvent3 h-[140px] ${event.three ? 'w-[280px] opacity-100' : 'w-[27.5px] opacity-40'} rounded-[10px] bg-center transition-all duration-300`}
                /> */}
                <img src={Zichuyen} alt="Zichuyen" className="w-[280px] h-[280px] relative object-contain" />
            </div>
            {event.one && (
                <div className="mt-[30px]">
                    <h1 className="text-lg font-medium">{t('home.3.0.title')}</h1>
                    <p className="mt-4 text-sm font-normal leading-relaxed text-text-bg-100 whitespace-pre-line">
                        {t('home.3.0.content')}
                    </p>
                    <div className="flex flex-row gap-[6px]">
                        <p className=" text-sm font-normal leading-relaxed text-text-bg-100 whitespace-pre-line">
                            {t('home.3.0.redirect1')}
                            <span><Link to="/event" className="z-50 cursor-pointer text-sm leading-relaxed  whitespace-pre-line text-[#EB33FF] font-semibold">{t('home.3.0.redirect2')}</Link></span>
                        </p>
                    </div>
                </div>
            )}
            {/* {event.two && (
                <div className="mt-[30px]">
                    <h1 className="text-lg font-medium">Sự kiện 2022: Idea Worth Spreading</h1>
                    <p className="mt-1.5 text-sm font-normal leading-[21px] text-text-bg-100">
                        2022, TEDx HCMUSSH quay trở lại với chủ đề The Fulcrum: Điểm tựa cùng phương châm &quot;Independent yet interdependent&quot;.
                        Liên hệ đến vai trò của điểm tựa trong đòn bẩy, đây là vị trí chịu nhiều áp lực nhất vì vậy nếu con người chọn những điểm tựa sai lầm, lung lay,
                        chúng ta sẽ dễ mất đi những chỗ dựa then chốt trong cuộc sống tạo thêm áp lực lên bản thân, thách thức sự chịu đựng vượt quá ngưỡng giới hạn,
                        dẫn đến toàn bộ &rdquot;đòn bẩy&quot; cuộc đời sụp đổ. Ngược lại, nếu tìm đúng điểm tựa phù hợp, kết hợp với nỗ lực sẽ tạo ra một “đòn bẩy” hiệu quả và vững mạnh.
                        <br />
                        Thông qua chủ đề “Điểm tựa”, TEDx HCMUSSH tập trung vào việc tháo gỡ những cách hiểu rằng  điểm tựa chỉ là một công cụ để đạt các mục tiêu cụ thể và ngắn hạn. Song, điểm tựa nên được xem là một phần thuộc về đòn bẩy như cách để mỗi cá nhân tồn tại độc lập nhưng liên lập trong thời đại cá nhân hóa.
                    </p>
                </div>
            )}
            {event.three && (
                <div className="mt-[30px]">
                    <h1 className="text-lg font-medium">Sự Kiện 2018: Equilibrium</h1>
                    <p className="mt-1.5 text-sm font-normal leading-[21px] text-text-bg-100">
                        Lấy đề tài Equilibrium với ý nghĩa “Điểm cân bằng”, chương trình hướng đến việc tìm kiếm và vẽ nên giao điểm giữa các luồng tư tưởng bằng cách kết nối và dung hoà các giá trị truyền thống với trào lưu toàn cầu, giữa cá nhân và cộng đồng, giữa phụng sự và tự do. Quay về điểm cân bằng để hiểu chính mình và sự vận hành của cuộc sống, mỗi người sẽ tìm được cho bản thân sự bình an, hạnh phúc, và ý nghĩa cuộc đời mình.
                    </p>
                </div>
            )} */}
        </div>
    );
}
