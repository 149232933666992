import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Tmp from 'assets/image/tmp.png';

export default function BlogCarousel({
    id, title, date, author, img, key,
}) {
    const navigate = useNavigate();
    function getMonthName(month) {
        const d = new Date();
        d.setMonth(month);
        const monthName = d.toLocaleString('default', { month: 'long' });
        return monthName;
    }
    return (
        <div
            key={key}
            className="flex-none w-[225px] bg-text-bg-600 p-5
            rounded-[15px] flex flex-col cursor-pointer"
            aria-hidden="true"
            onClick={() => { navigate(`/blog/${id}`); }}
        >
            <img alt={title} src={img} className="h-[200px] bg-white rounded-[15px] object-contain" />
            <p className="mt-[10px] text-sm font-bold">{title}</p>
            <div className="flex justify-between mt-[10px]">
                <p className="text-[10px] font-bold text-text-bg-300">
                    {date.getDate()}
                    {' '}
                    {getMonthName(date.getMonth())}
                    {' '}
                    {date.getFullYear()}
                </p>
                <p className="text-[10px] font-bold text-text-bg-300">{author}</p>
            </div>
        </div>
    );
}
BlogCarousel.propTypes = {
    title: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    author: PropTypes.string,
};
BlogCarousel.defaultProps = {
    title: '',
    date: new Date('2022-07-10'),
    author: '',
};
