import React from 'react';
import { useTranslation } from 'react-i18next';

import TrainMobile from 'assets/image/train_mobile.png';

export default function MainIntroMobile() {
    const { t } = useTranslation();
    return (
        <div className="px-[15px]">
            <h1 className="text-xl font-bold mt-10 leading-[30px] max-w-[320px] sm:max-w-[520px] md:max-w-[840px]">
                {t('home.0.title')}
            </h1>
            <p className="mt-[15px] text-sm font-normal leading-[21px] text-text-bg-100">
                {t('home.0.content')}
            </p>
            <div className="mt-[15px] flex justify-between">
                <button type="button" className="bg-blue-500 h-9 py-3 px-6 rounded-[48px] font-bold text-xs leading-3">{t('title.par')}</button>
                <img src={TrainMobile} alt="Train" />
            </div>
        </div>
    );
}
