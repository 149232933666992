import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FbImg } from 'assets/icons/facebook.svg';
// import fbImgColor from 'assets/icons/facebookColor.svg';
import gmailImg from 'assets/icons/gmail.svg';
import gmailImgColor from 'assets/icons/gmailColor.svg';
import insImg from 'assets/icons/instagram.svg';
// import insImgColor from 'assets/icons/instagramColor.svg';
import { ReactComponent as LinkedinImg } from 'assets/icons/linkedin.svg';
// import likedinImgColor from 'assets/icons/linkedinColor.svg';
import { ReactComponent as SpotifyImg } from 'assets/icons/spotify.svg';
// import spotifyImgColor from 'assets/icons/spotifyColor.svg';
import { ReactComponent as YoutubeImg } from 'assets/icons/youtube.svg';

import Form from './Form';
// import youtubeImgColor from 'assets/icons/youtubeColor.svg';

export default function Footer() {
    const { t } = useTranslation();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBzKWzv05BesJzTrMWaXCVsX6iH6LXLTF4',
    });
    const center = useMemo(() => ({ lat: 10.7858607, lng: 106.7027086 }), []);
    if (!isLoaded) return <div>Loading...</div>;
    // const key = 'AIzaSyBzKWzv05BesJzTrMWaXCVsX6iH6LXLTF4';
    return (
        <div className=" z-[80] w-[100vw] px-[15px] lg:px-[120px] 3xl:px-[240px] flex flex-col justify-center mt-0 pt-0">
            <div className="flex w-[652px] lg:w-fit xl:w-[1036px] 3xl:w-[1420px] mx-auto w-max-screen flex-1 justify-center space-x-2 xl:space-x-4">
                <div className="left-container xl:w-fit h-auto max-h-[617px] flex flex-col relative">
                    {/* <GoogleMap zoom={15} center={center} mapContainerClassName="border-solid border-2 border-white h-[145px] md:h-[220px] mb-4 lg:mb-8 rounded-[15px] w-[320px] lg:w-[400px] xl:w-[510px] 3xl:w-[700px]">
                        <Marker position={center} />
                    </GoogleMap> */}
                    <div className="border-solid border-2 border-white h-[145px] md:h-[220px] mb-4 lg:mb-8 rounded-[15px] w-[320px] lg:w-[400px] xl:w-[510px] 3xl:w-[700px]">
                        <iframe
                            title="HCMUSSH"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31355.27562600261!2d106.66556857017943!3d10.779918958417483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f4baa197517%3A0x8872da8526a26f7c!2zVHLGsOG7nW5nIMSQ4bqhaSBo4buNYyBLaG9hIGjhu41jIFjDoyBo4buZaSB2w6AgTmjDom4gdsSDbiAoSENNVVNTSCkgLSDEkOG6oWkgaOG7jWMgUXXhu5FjIGdpYSBUUC5IQ00!5e0!3m2!1svi!2s!4v1664166875388!5m2!1svi!2s"
                            className="border-none rounded-[15px] w-[320px] lg:w-[400px] xl:w-[510px] 3xl:w-[700px] h-[145px] md:h-[220px]"
                            allowFullScreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        />
                    </div>
                    <div className="media-lst h-[40px] lg:h-[60px] xl:h-[88px] w-full md:w-[320px] lg:w-[400px] xl:w-[510px] 3xl:w-[700px] rounded-[15px] flex space-x-2 lg:space-x-3 justify-center md:justify-evenly items-center px-10 xl:px-[80px] border-solid border-2 border-white md:mb-5 mb-5">
                        <a href="https://open.spotify.com/show/3OOyHkhtOv8mVXWb6sjjuk?si=f4434db94a75444f" className="w-6 h-6 lg:h-10 lg:w-10 xl:h-[48px] xl:w-[48px]">
                            <SpotifyImg className="fill-white hover:fill-[#1ed760] transition-all duration-300 ease-out" />
                        </a>
                        <a href="https://www.linkedin.com/company/tedx-hcmussh/" className="w-6 h-6 lg:h-10 lg:w-10 xl:h-[48px] xl:w-[48px]">
                            <LinkedinImg className="rounded-full fill-white hover:fill-[#0077b5] hover:bg-white transition-all duration-300 ease-out" />
                        </a>
                        <a href="https://www.facebook.com/tedxhcmussh/" className="w-6 h-6 lg:h-10 lg:w-10 xl:h-[48px] xl:w-[48px]">
                            <FbImg className="rounded-full fill-white hover:fill-[#1877f2] hover:bg-white transition-all duration-300 ease-out" />
                        </a>
                        <a href="https://www.youtube.com/channel/UC0mHjlw596C1xswOyvGvhjg" className="w-6 h-6 lg:h-10 lg:w-10 xl:h-[48px] xl:w-[48px]">
                            <YoutubeImg className="rounded-full fill-white hover:fill-[#ff0000] hover:bg-white transition-all duration-300 ease-out" />
                        </a>
                        <a href="https://www.instagram.com/tedxhcmussh/" className="w-6 h-6 lg:h-10 lg:w-10 xl:h-[48px] xl:w-[48px] bg-cover bg-[url('assets/icons/instagram.svg')] hover:bg-[url('assets/icons/instagramColor.svg')] transition-all duration-300 ease-out">
                            {/* <img className="object-contain" alt="insImgColor" src={insImg} /> */}
                        </a>
                        <a href="mailto:external.relations.tedxussh@gmail.com" className="w-6 h-6 lg:h-10 lg:w-10 xl:h-[48px] xl:w-[48px] bg-cover bg-[url('assets/icons/gmail.svg')] hover:bg-[url('assets/icons/gmailColor.svg')] transition-all duration-300 ease-out">
                            {/* {kkk} */}
                        </a>
                        {/* <a href="/" className="w-6 h-6 lg:h-10 lg:w-10 xl:h-[48px] xl:w-[48px] relative">
                            <img className="object-contain absolute hover:opacity-0 transition-all duration-300 ease-out" alt="tiktokImgColor" src={tiktokImg} />
                            <img className="object-contain" alt="tiktokImgColor" src={tiktokImgColor} />
                        </a> */}
                    </div>
                    <div className="hidden description md:flex justify-evenly flex-col w-full md:w-[320px] lg:w-[400px] xl:w-[510px] 3xl:w-[700px] mt-4 text-epink-500">
                        <div>
                            <a href="/contact/btc" className="font-bold text-2xl hover:underline  leading-2">{t('title.footer')}</a>
                        </div>
                        {/* <p className="text-white mt-[15px] text-sm lg:text-base"> Liên Hệ: external.relations.tedxussh@gmail.com - 0901632729</p>
                        <p className="text-white mt-[15px] text-sm lg:text-base">
                            TEDxHCMUSSH là chương trình được cấp phép tổ chức một cách độc lập từ
                            {' '}
                            <a href="https://ted.com" className="text-TEDred">
                                {' '}
                                TED
                            </a>
                            .
                        </p> */}
                    </div>
                </div>
                <Form />
            </div>
            <p className="w-full lg:w-fit text-sm mt-2 3xl:mt-10 lg:text-lg 3xl:text-2xl font-medium text-white self-center text-center mb-[20px]">Copyright © 2022 TEDxHCMUSSH - 10-12 Đinh Tiên Hoàng, Phường Bến Nghé, Quận 1, TPHCM</p>
        </div>
    );
}
