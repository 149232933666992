import PropTypes from 'prop-types';
import React from 'react';

export default function Title({ children }) {
    return (
        <div className="absolute bottom-[24px] 2xl:bottom-[60px] w-full md:px-[40px] lg:px-[120px] xl:px-[160px] 2xl:px-[250px] flex flex-col justify-items-end items-end">
            {children}
        </div>
    );
}

Title.propTypes = {
    children: PropTypes.element.isRequired,
};
