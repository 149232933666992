import PropTypes from 'prop-types';
import React from 'react';

export default function VideoElement({
    title, date, author, embedId,
}) {
    function getMonthName(month) {
        const d = new Date();
        d.setMonth(month);
        const monthName = d.toLocaleString('default', { month: 'long' });
        return monthName;
    }
    return (
        <a
            href={`https://www.youtube.com/watch?v=${embedId}`}
            className="flex-none w-full h-[296px] bg-text-bg-600 p-5
            rounded-[15px] flex flex-col cursor-pointer"
            aria-hidden="true"
        >
            <img id="img" draggable="false" className="aspect-video object-cover w-full h-[200px] rounded-[15px]" alt="yt-video" src={`https://img.youtube.com/vi/${embedId}/hqdefault.jpg`} />
            <p className="mt-[10px] text-sm font-bold">{title}</p>
            <div className="flex justify-between mt-[10px]">
                <p className="text-[10px] font-bold text-text-bg-300">
                    {date.getDate()}
                    {' '}
                    {getMonthName(date.getMonth())}
                    {' '}
                    {date.getFullYear()}
                </p>
                <p className="text-[10px] font-bold text-text-bg-300">{author}</p>
            </div>
        </a>
    );
}
VideoElement.propTypes = {
    title: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    author: PropTypes.string,
    embedId: PropTypes.string,
};
VideoElement.defaultProps = {
    title: '',
    date: new Date('2022-07-10'),
    author: '',
    embedId: '',
};
