import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FbImg } from 'assets/icons/facebook.svg';
// import fbImgColor from 'assets/icons/facebookColor.svg';
import insImg from 'assets/icons/instagram.svg';
import insImgColor from 'assets/icons/instagramColor.svg';
import { ReactComponent as LinkedinImg } from 'assets/icons/linkedin.svg';
// import likedinImgColor from 'assets/icons/linkedinColor.svg';
import { ReactComponent as SpotifyImg } from 'assets/icons/spotify.svg';
// import spotifyImgColor from 'assets/icons/spotifyColor.svg';
import tiktokImg from 'assets/icons/tiktok.svg';
import tiktokImgColor from 'assets/icons/tiktokColor.svg';
import { ReactComponent as YoutubeImg } from 'assets/icons/youtube.svg';
// import youtubeImgColor from 'assets/icons/youtubeColor.svg';

import Form from './Form';
import Map from './Map';

export default function Footer() {
    const { t } = useTranslation();

    return (
        <>
            <div className="bg-transparent w-full px-[15px] md:px-[40px] lg:px-[116px] xl:px-[200px] 2xl:px-[250px] mb-[120px]">
                <div className="flex w-fit xl:w-[1036px] 3xl:w-[1420px] mx-auto w-max-screen flex-col xl:flex-row  flex-1 justify-around xl:space-x-4">
                    <div className="left-container xl:w-fit h-auto max-h-[617px] flex flex-col relative">
                        <Map />
                        <div className="media-lst h-[54px] md:h-[88px] w-full md:w-[688px] lg:w-[624px] xl:w-[510px] 3xl:w-[700px] rounded-[15px] flex space-x-3 justify-center md:justify-evenly items-center md:px-[80px] border-solid border-2 border-white md:mb-8 mb-10">
                            <a href="https://open.spotify.com/show/3OOyHkhtOv8mVXWb6sjjuk?si=f4434db94a75444f" className="w-6 h-6 md:h-[48px] md:w-[48px]">
                                <SpotifyImg className="fill-white hover:fill-[#1ed760] transition-all duration-300 ease-out" />
                            </a>
                            <a href="https://www.linkedin.com/company/tedx-hcmussh/" className="w-6 h-6 md:h-[48px] md:w-[48px]">
                                <LinkedinImg className="rounded-full fill-white hover:fill-[#0077b5] hover:bg-white transition-all duration-300 ease-out" />
                            </a>
                            <a href="https://www.facebook.com/tedxhcmussh/" className="w-6 h-6 md:h-[48px] md:w-[48px]">
                                <FbImg className="rounded-full fill-white hover:fill-[#1877f2] hover:bg-white transition-all duration-300 ease-out" />
                            </a>
                            <a href="https://www.youtube.com/channel/UC0mHjlw596C1xswOyvGvhjg" className="w-6 h-6 md:h-[48px] md:w-[48px]">
                                <YoutubeImg className="rounded-full fill-white hover:fill-[#ff0000] hover:bg-white transition-all duration-300 ease-out" />
                            </a>
                            <a href="https://www.instagram.com/tedxhcmussh/" className="w-6 h-6 md:h-[48px] md:w-[48px] bg-cover bg-[url('assets/icons/instagram.svg')] hover:bg-[url('assets/icons/instagramColor.svg')] transition-all duration-300 ease-out">
                                {}
                            </a>
                            <a href="mailto:external.relations.tedxussh@gmail.com" className="w-6 h-6 lg:h-10 lg:w-10 xl:h-[48px] xl:w-[48px] bg-cover bg-[url('assets/icons/gmail.svg')] hover:bg-[url('assets/icons/gmailColor.svg')] transition-all duration-300 ease-out">
                                {/* {kkk} */}
                            </a>
                            {/* <a href="/" className="w-6 h-6 md:h-[48px] md:w-[48px] relative">
                                <img className="object-contain absolute hover:opacity-0 transition-all duration-300 ease-out" alt="tiktokImgColor" src={tiktokImg} />
                                <img className="object-contain" alt="tiktokImgColor" src={tiktokImgColor} />
                            </a> */}
                        </div>
                        <div className="hidden description xl:flex justify-evenly flex-col w-full md:w-[688px] lg:w-[624px] xl:w-[510px] 3xl:w-[700px] absolute mt-[400px] text-epink-500">
                            <div>
                                <a href="/contact/btc" className="font-bold text-2xl hover:underline leading-2">{t('title.footer')}</a>
                            </div>
                            {/* <p className="text-white mt-[15px]"> Liên Hệ: external.relations.tedxussh@gmail.com - 0901632729</p> */}
                        </div>
                    </div>
                    <Form />
                    <div className="description xl:hidden justify-evenly flex-col mt-10 text-xs md:text-base text-epink-500">
                        <div>
                            <a href="/contact/btc" className="font-bold text-2xl hover:underline leading-2">{t('title.footer')}</a>
                            {/* {' '}
                            ●
                            {' '}
                            <a href="/contact/faq" className="font-semibold leading-2">FAQ</a> */}
                        </div>
                        {/* <p className="text-white mt-[15px]"> Liên Hệ: external.relations.tedxussh@gmail.com - 0901632729</p> */}
                        <p className="text-white mt-[15px]">
                            {t('title.rights')}
                            {' '}
                            <a href="https://ted.com" className="text-red-500">TED</a>
                            .
                        </p>
                        <p className="text-white md:mt-[120px] mt-[30px]">Copyright © 2022 TEDxHCMUSSH - 10-12 Đinh Tiên Hoàng, Phường Bến Nghé, Quận 1, TPHCM</p>
                    </div>
                </div>
            </div>
            <p className="text-white mt-[15px] hidden xl:inline-block lg:text-lg 2xl:text-2x mx-auto text-center mb-[20px]">
                {t('title.rights')}
                {' '}
                <a href="https://ted.com" className="text-TEDred">
                    {' '}
                    TED
                </a>
                .
            </p>
            <p className="hidden xl:inline-block lg:text-lg 2xl:text-2xl text-white mx-auto text-center mb-[40px]">Copyright © 2022 TEDxHCMUSSH - 10-12 Đinh Tiên Hoàng, Phường Bến Nghé, Quận 1, TPHCM</p>
        </>
    );
}
