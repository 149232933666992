import PropTypes from 'prop-types';
import React from 'react';

export default function VideoElement({
    videoId, title, author, date,
}) {
    return (
        <a
            href={`https://www.youtube.com/watch?v=${videoId}`}
            className="bg-text-bg-600 p-5 rounded-[20px] flex flex-col cursor-pointer brightness-100 hover:brightness-50 transition-all duration-150"
            aria-hidden="true"
        >
            <img id="img" draggable="false" className="aspect-video object-cover rounded-[20px]" alt="yt-video" src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`} />
            <p className="mt-[10px] text-2xl font-bold max-h-[96px] overflow-clip">{title}</p>
            <div className="flex justify-between mt-[10px]">
                <p className="text-base font-bold text-text-bg-300">
                    {date.toLocaleDateString('en-us', {
                        month: 'short',
                        year: 'numeric',
                        day: 'numeric',
                    })}

                </p>
                <p className="text-base font-bold text-text-bg-300">{author}</p>
            </div>
        </a>
    );
}

VideoElement.propTypes = {
    videoId: PropTypes.string.isRequired,
};
