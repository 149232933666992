/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/no-children-prop */
import React from 'react';

function StyledImage({ src, alt, title }) {
    return (
        <div className="my-[30px] md:my-[40px] lg:my-[52px] xl:my-[64px] 2xl:my-[80px]">
            <img src={src} alt={alt} className="md:w-[560px] 2xl:w-[700px] mx-auto mb-[15px] object-cover rounded-[15px] md:rounded-[20px]" />
            <p
                className="text-text-bg-200
                text-[14px] mb-[30px]
                md:text-md
                lg:text-lg
                xl:text-xl
                2xl:text-2xl text-center"
            >
                {title}
            </p>
        </div>

    );
}

function StyledBullets(props) {
    return (
        <ul>
            {props}
        </ul>
    );
}

function StyledHeading1(props) {
    return (
        <h1
            className="font-bold leading-5
            text-[18px] mb-[30px]
            md:text-2xl md:mb-[40px]
            lg:text-3xl lg:mb-[52px]
            xl:text-4xl xl:mb-[64px]
            2xl:text-[54px] 2xl:mb-[80px]"
            {...props}
        />
    );
}
function StyledHeading2(props) {
    return (
        <h2
            className="
            text-xl
            lg:text-2xl
            xl:text-3xl
            2xl:text-4xl"
            {...props}
        />
    );
}
function StyledHeading3(props) {
    return <h3 className="text-text-bg-50 text-lg lg:text-xl xl:text-2xl 2xl:text-3xl" {...props} />;
}

function StyledParagraph(props) {
    return (
        <p
            className="text-text-bg-50
            text-[14px] mb-[30px]
            md:text-lg
            xl:text-xl
            2xl:text-2xl"
            {...props}
        />
    );
}
function StyledStrong(props) {
    return (
        <strong
            className="text-white font-bold
            text-[14px] mb-[30px]
            md:text-md
            lg:text-lg
            xl:text-xl
            2xl:text-2xl"
            {...props}
        />
    );
}

export default {
    StyledHeading1,
    StyledHeading2,
    StyledHeading3,
    StyledImage,
    StyledParagraph,
    StyledStrong,
    StyledBullets,
};
