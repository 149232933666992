import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import DiscussionImage from 'assets/image/tuan2mobile.svg';

export default function Week2Detail({ show, onClose, num }) {
    const closeOnEscapeKeyDown = (e) => {
        if ((e.charCode || e.keyCode) === 27) {
            onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
        };
    }, []);

    return (
        <div
            aria-hidden="true"
            className={`fixed lg:hidden z-50 inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center opacity-0 transition-all duration-300 ease-out pointer-events-none ${
                show ? 'opacity-100 pointer-events-auto' : ''
            }`}
            onKeyDown={closeOnEscapeKeyDown}
        >
            <div
                aria-hidden="true"
                className={`bg-text-bg-500 relative flex flex-col p-5 items-center 
                border-2 border-white rounded-[15px] ${show ? 'animate-modal-move-up' : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <img src={DiscussionImage} className="w-full h-[160px] md:w-[520px] md:h-[400px] object-cover border-2 border-[#FACC14]" alt="Discussion Review" />
                <div className="mt-[10px] w-[260px] h-[180px] md:w-[520px] md:h-[360px] rounded-[15px]
                p-[10px] md:p-5 border-2 border-yellow-500 bg-text-bg-500 overflow-y-auto no-scrollbar"
                >
                    {/* <p className="md:hidden text-[10px] md:text-lg font-medium text-epink-500 mb-[10px]">
                        https://www.facebook.com/tedxhcmussh/
                        posts/1213709655834230
                    </p>
                    <p className="hidden md:block text-[10px] md:text-lg font-medium text-epink-500 mb-[10px]">
                        https://www.facebook.com/tedxhcmussh/posts/
                        1213709655834230
                    </p> */}
                    <p className="text-white text-sm md:text-2xl font-normal md:font-medium">
                        {t('event.2.section.4.content.1.content')}
                    </p>
                </div>
                <button
                    type="button"
                    className="lg:hidden mt-[10px] font-bold text-base md:text-xl text-text-bg-300"
                    onClick={onClose}
                >
                    CLOSE
                </button>
            </div>
        </div>
    );
}

Week2Detail.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    num: PropTypes.number.isRequired,
};
Week2Detail.defaultProps = {
    show: true,
    onClose: () => {},
};
