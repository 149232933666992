import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enTranslation from './script/en/translation.json';
import vnTranslation from './script/vn/translation.json';

i18n
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'vn',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: enTranslation,
            },
            vn: {
                translation: vnTranslation,
            },
        },
    });

export default i18n;
