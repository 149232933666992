import React from 'react';
import Helmet from 'react-helmet';

// import ogImage from 'assets/image/og-image.png';
import HomepageMobile from 'components/Homepage/HomepageMobile';
import HomepageParallax from 'components/Parallax';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

import Footer from '../components/Footer';
import HostInroduction from '../components/HostIntroduction';

export default function HomePage() {
    useTitle(DEFAULT_TITLE);
    return (
        <div>
            <Helmet>
                <title>TEDx USSH</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="description" key="description" content="TEDx USSH is an idea-disruptive platform and cultural hub, aiming at nurturing and sharing the human" />
                <meta name="title" key="title" content="TEDx USSH" />
                <meta property="og:title" key="og:title" content="TEDxHCMUSSH" />
                <meta property="og:locale" key="og:locale" content="en_US" />
                <meta charSet="utf-8" />
                <meta property="og:type" key="og:type" content="website" />
                <meta property="og:description" key="og:description" content="TEDx USSH is an idea-disruptive platform and cultural hub, aiming at nurturing and sharing the human" />
                <meta property="og:image" key="og:image" content="%PUBLIC_URL%/ussh.png" />
                <meta property="og:url" content="https://tedxhcmussh.com/" />
            </Helmet>
            <div className="hidden md:block">
                <HomepageParallax />
            </div>
            <div className="md:hidden">
                <HomepageMobile />
            </div>
        </div>
    );
}
