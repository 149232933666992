import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function HomepageVideoMobile() {
    const { t } = useTranslation();
    const [embedId, setEmbedId] = useState('_l079QZ7jek');
    return (
        <div className="flex flex-col items-center px-[15px] mt-[60px] w-full">
            <h1 className="text-xl font-bold leading-[30px] self-start">
                {t('title.video.1')}
                {' '}
                <span className="text-TEDred">{t('title.video.2')}</span>
                {' '}
                {t('title.video.3')}
            </h1>
            <a href="https://www.youtube.com/channel/UC0mHjlw596C1xswOyvGvhjg" className="text-sm font-bold text-epink-500 self-start">
                {t('title.more')}
            </a>
            <div className="h-full w-full lg:overflow-y-scroll no-scrollbar mt-[50px] rounded-[15px] bg-text-bg-600">
                <div
                    aria-hidden
                    onClick={() => setEmbedId('_l079QZ7jek')}
                >
                    <h3 className="cursor-pointer font-bold text-sm leading-[21px]">{t('homepage.videoContent.0.title')}</h3>
                    <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] min-h-fit">{t('homepage.videoContent.0.description')}</p>
                </div>
                <div className="mt-[10px] flex justify-between">
                    <p className="text-[10px] font-bold text-text-bg-300">23 May 2019</p>
                    <p className="text-[10px] font-bold text-text-bg-300">TEDxHCMUSSH</p>
                </div>
                <div className="mt-[15px] mb-[30px] w-full h-[2px] bg-text-bg-300 rounded-full" />
                <div
                    aria-hidden
                    onClick={() => setEmbedId('vsMKk5P0vVs')}
                >
                    <h3 className="cursor-pointer font-bold text-sm leading-[21px]">{t('homepage.videoContent.1.title')}</h3>
                    <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] min-h-fit">{t('homepage.videoContent.1.description')}</p>
                </div>
                <div className="mt-[10px] flex justify-between">
                    <p className="text-[10px] font-bold text-text-bg-300">31 March 2019</p>
                    <p className="text-[10px] font-bold text-text-bg-300">TEDxHCMUSSH</p>
                </div>
                <div className="mt-[15px] mb-[30px] w-full h-[2px] bg-text-bg-300 rounded-full" />
                <div
                    aria-hidden
                    onClick={() => setEmbedId('Na7wqlXcVdg')}
                >
                    <h3 className="cursor-pointer font-bold text-sm leading-[21px]">{t('homepage.videoContent.2.title')}</h3>
                    <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] min-h-fit">{t('homepage.videoContent.2.description')}</p>
                </div>
                <div className="mt-[10px] flex justify-between">
                    <p className="text-[10px] font-bold text-text-bg-300">18 March 2019</p>
                    <p className="text-[10px] font-bold text-text-bg-300">TEDxHCMUSSH</p>
                </div>
                <div className="mt-[15px] mb-[30px] w-full h-[2px] bg-text-bg-300 rounded-full" />
                <div
                    aria-hidden
                    onClick={() => setEmbedId('Pj6_UkhfRB4')}
                >
                    <h3 className="cursor-pointer font-bold text-sm leading-[21px]">{t('homepage.videoContent.3.title')}</h3>
                    <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] min-h-fit">{t('homepage.videoContent.3.description')}</p>
                </div>
                <div className="mt-[10px] flex justify-between">
                    <p className="text-[10px] font-bold text-text-bg-300">17 March 2019</p>
                    <p className="text-[10px] font-bold text-text-bg-300">TEDxHCMUSSH</p>
                </div>
                <div className="mt-[15px] mb-[30px] w-full h-[2px] bg-text-bg-300 rounded-full" />
                <div
                    aria-hidden
                    onClick={() => setEmbedId('a7--cJD7k0Q')}
                >
                    <h3 className="cursor-pointer font-bold text-sm leading-[21px]">{t('homepage.videoContent.4.title')}</h3>
                    <p className="font-normal text-sm leading-[21px] text-text-bg-300 mt-[10px] min-h-fit">{t('homepage.videoContent.4.description')}</p>
                </div>
                <div className="mt-[10px] flex justify-between">
                    <p className="text-[10px] font-bold text-text-bg-300">11 February 2019</p>
                    <p className="text-[10px] font-bold text-text-bg-300">TEDxHCMUSSH</p>
                </div>
            </div>
            <iframe
                className="w-[345px] h-[226px] rounded-[15px] mt-[30px] border-2 border-text-bg-300"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );
}
