import { t } from 'i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    SHOW_WEEK1_MODAL_MOBILE,
    SHOW_WEEK2_MODAL_MOBILE,
    SHOW_WEEK3_MODAL_MOBILE,
    SHOW_WEEK4_MODAL_MOBILE,
    SHOW_WEEK5_MODAL_MOBILE,
} from 'action-types';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow_right.svg';
import FirstEvent from 'assets/image/tuan1mobile.svg';
import SecondEvent from 'assets/image/tuan2mobile.svg';
import ThirdEvent from 'assets/image/tuan3mobile.svg';
import FourthEvent from 'assets/image/tuan4mobile.svg';
import FifthEvent from 'assets/image/tuan5mobile.svg';
import Week1DetailMobile from 'components/CarouselModal/Week1ModalMobile';
import Week2DetailMobile from 'components/CarouselModal/Week2ModalMobile';
import Week3DetailMobile from 'components/CarouselModal/Week3ModalMobile';
import Week4DetailMobile from 'components/CarouselModal/Week4ModalMobile';
import Week5DetailMobile from 'components/CarouselModal/Week5ModalMobile';

export default function DiscussionCarouselMobile() {
    const [position, setPosition] = useState('left');
    const {
        Week1CarouselModalMobile,
        Week2CarouselModalMobile,
        Week3CarouselModalMobile,
        Week4CarouselModalMobile,
        Week5CarouselModalMobile,
    } = useSelector((state) => state.carouselModal);
    const dispatch = useDispatch();

    const handleScroll = (e) => {
        const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
        if (scrollLeft === 0) setPosition('left');
        else if (scrollLeft + clientWidth === scrollWidth) {
            setPosition('right');
        } else setPosition('mid');
    };
    const moveRight = () => {
        let scrollAmount = 0;
        const element = document.getElementById('carousel_element_mobile');
        const slideTimer = setInterval(() => {
            element.scrollLeft += 52.5;
            scrollAmount += 52.5;
            if (scrollAmount >= 315) {
                window.clearInterval(slideTimer);
            }
        }, 10);
        if (element.scrollLeft + element.clientWidth === element.scrollWidth) {
            setPosition('right');
        } else setPosition('mid');
    };
    const moveLeft = () => {
        let scrollAmount = 0;
        const element = document.getElementById('carousel_element_mobile');
        const slideTimer = setInterval(() => {
            element.scrollLeft -= 52.5;
            scrollAmount += 52.5;
            if (scrollAmount >= 315) {
                window.clearInterval(slideTimer);
            }
        }, 10);
        if (element.scrollLeft === 0) setPosition('left');
        else setPosition('mid');
    };
    return (
        <div className="xl:hidden flex mt-[80px] md:mt-[96px] 2xl:mt-[120px]">
            <Week1DetailMobile
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK1_MODAL_MOBILE,
                        payload: false,
                    });
                }}
                show={Week1CarouselModalMobile}
            />
            <Week2DetailMobile
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK2_MODAL_MOBILE,
                        payload: false,
                    });
                }}
                show={Week2CarouselModalMobile}
            />
            <Week3DetailMobile
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK3_MODAL_MOBILE,
                        payload: false,
                    });
                }}
                show={Week3CarouselModalMobile}
            />
            <Week4DetailMobile
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK4_MODAL_MOBILE,
                        payload: false,
                    });
                }}
                show={Week4CarouselModalMobile}
            />
            <Week5DetailMobile
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK5_MODAL_MOBILE,
                        payload: false,
                    });
                }}
                show={Week5CarouselModalMobile}
            />
            <h1 className="mr-[15px] md:mr-[80px] text-lg md:text-[36px] md:leading-[64.8px] font-bold w-[105px] md:w-[190px]">
                {t('event.2.section.4.title')}
            </h1>
            <div className="w-[calc(100%-120px)] md:w-[calc(100%-268px)] flex items-center relative md:px-5">
                {position !== 'left' && (
                    <button
                        type="button"
                        className="hidden md:flex z-10 absolute left-0 w-10 h-10 bg-black rounded-full border-2 border-blue-500 justify-center items-center"
                        onClick={moveLeft}
                    >
                        <LeftArrowIcon />
                    </button>
                )}
                {(position !== 'right') && (
                    <button
                        type="button"
                        className="hidden md:flex z-10 absolute right-0 w-10 h-10
                    bg-black rounded-full border-2 border-blue-500 justify-center items-center"
                        onClick={moveRight}
                    >
                        <RightArrowIcon />
                    </button>
                )}
                <div className="h-[200px] md:h-[400px] flex overflow-x-auto no-scrollbar space-x-[15px]" id="carousel_element_mobile" onScroll={handleScroll}>
                    <div
                        className="flex-none w-[150px] md:w-[300px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK1_MODAL_MOBILE,
                                payload: true,
                            });
                        }}
                    >
                        <img className="w-[150px] md:w-[300px] h-[200px] md:h-[400px]" src={FirstEvent} alt="First Event" />
                        <div className="w-[150px] md:w-[300px] h-[56px] md:h-[80px] flex p-[10px] bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-end w-[50px] md:w-[72px] text-xs md:text-2xl font-bold text-red-500">{t('event.2.section.4.content.0.week')}</p>
                            <p className="ml-[10px] md:ml-5 w-[70px] md:w-[180px] text-xs font-bold md:text-xl self-end overflow-hidden max-h-8">{t('event.2.section.4.content.0.title')}</p>
                        </div>
                    </div>
                    <div
                        className="flex-none w-[150px] md:w-[300px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK2_MODAL_MOBILE,
                                payload: true,
                            });
                        }}
                    >
                        <img className="w-[150px] md:w-[300px] h-[200px] md:h-[400px]" src={SecondEvent} alt="Second Event" />
                        <div className="w-[150px] md:w-[300px] h-[56px] md:h-[80px] flex p-[10px] bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-end w-[50px] md:w-[72px] text-xs md:text-2xl font-bold text-red-500">{t('event.2.section.4.content.1.week')}</p>
                            <p className="ml-[10px] md:ml-5 w-[70px] md:w-[180px] text-xs font-bold md:text-xl self-end overflow-hidden max-h-8">{t('event.2.section.4.content.1.title')}</p>
                        </div>
                    </div>
                    <div
                        className="flex-none w-[150px] md:w-[300px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK3_MODAL_MOBILE,
                                payload: true,
                            });
                        }}
                    >
                        <img className="w-[150px] md:w-[300px] h-[200px] md:h-[400px]" src={ThirdEvent} alt="Third Event" />
                        <div className="w-[150px] md:w-[300px] h-[56px] md:h-[80px] flex p-[10px] bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-end w-[50px] md:w-[72px] text-xs md:text-2xl font-bold text-red-500">{t('event.2.section.4.content.2.week')}</p>
                            <p className="ml-[10px] md:ml-5 w-[80px] md:w-[180px] text-xs font-bold md:text-xl self-end overflow-hidden max-h-8">
                                {t('event.2.section.4.content.2.title')}
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex-none w-[150px] md:w-[300px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK4_MODAL_MOBILE,
                                payload: true,
                            });
                        }}
                    >
                        <img className="w-[150px] md:w-[300px] h-[200px] md:h-[400px]" src={FourthEvent} alt="Fourth Event" />
                        <div className="w-[150px] md:w-[300px] h-[56px] md:h-[80px] flex p-[10px] bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-end w-[50px] md:w-[72px] text-xs md:text-2xl font-bold text-red-500">{t('event.2.section.4.content.3.week')}</p>
                            <p className="ml-[10px] md:ml-5 w-[70px] md:w-[180px] text-xs font-bold md:text-xl self-end overflow-hidden max-h-8">{t('event.2.section.4.content.3.title')}</p>
                        </div>
                    </div>
                    <div
                        className="flex-none w-[150px] md:w-[300px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK5_MODAL_MOBILE,
                                payload: true,
                            });
                        }}
                    >
                        <img className="w-[150px] md:w-[300px] h-[200px] md:h-[400px]" src={FifthEvent} alt="Fifth Event" />
                        <div className="w-[150px] md:w-[300px] h-[56px] md:h-[80px] flex p-[10px] bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-end w-[50px] md:w-[72px] text-xs md:text-2xl font-bold text-red-500">{t('event.2.section.4.content.4.week')}</p>
                            <p className="ml-[10px] md:ml-5 w-[70px] md:w-[180px] text-xs font-bold md:text-xl self-end overflow-hidden max-h-8">{t('event.2.section.4.content.4.title')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
