import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Host() {
    const { t } = useTranslation();
    return (
        <div className="text-white mt-[30px] md:mt-[163px] flex flex-col md:flex-row justify-center md:space-x-[40px] lg:space-x-[60px]">
            <div className="md:w-[420px]">
                <h1 className="text-lg md:text-[24px] font-medium md:font-bold">{t('home.1.0.title')}</h1>
                <p className="mt-2 md:mt-5 text-[14px] md:text-base font-normal md:font-medium text-text-bg-100">
                    {t('home.1.0.content')}
                </p>
            </div>
            <div className="md:w-[420px] mt-[30px] md:mt-0">
                <h1 className="text-lg md:text-[24px] font-medium md:font-bold">{t('home.1.1.title')}</h1>
                <p className="mt-2 md:mt-5 text-[14px] md:text-base font-normal md:font-medium text-text-bg-100">
                    {t('home.1.1.content')}
                </p>
            </div>
            <div className="md:w-[420px] mt-[30px] md:mt-0">
                <h1 className="text-lg md:text-[24px] font-medium md:font-bold">{t('home.1.2.title')}</h1>
                <p className="mt-2 md:mt-5 text-[14px] md:text-base font-normal md:font-medium text-text-bg-100">
                    {t('home.1.2.content')}
                </p>
            </div>
        </div>
    );
}
