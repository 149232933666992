import { useEffect } from 'react';

export default function useTitle(title, defaultTitle = 'TEDx USSH') {
    useEffect(() => {
        document.title = title || defaultTitle;
        return () => {
            document.title = defaultTitle;
        };
    }, [title]);
}
