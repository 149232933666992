import {
    SHOW_ADVENTURE_CAROUSEL,
    SHOW_SPEAKER_CAROUSEL,
    SHOW_WEEK1_MODAL,
    SHOW_WEEK2_MODAL,
    SHOW_WEEK3_MODAL,
    SHOW_WEEK4_MODAL,
    SHOW_WEEK5_MODAL,
    SHOW_WEEK6_MODAL,
    SHOW_WEEK7_MODAL,
    SHOW_WEEK8_MODAL,
    SHOW_WEEK1_MODAL_MOBILE,
    SHOW_WEEK2_MODAL_MOBILE,
    SHOW_WEEK3_MODAL_MOBILE,
    SHOW_WEEK4_MODAL_MOBILE,
    SHOW_WEEK5_MODAL_MOBILE,
} from 'action-types';

const defaultSetUp = {
    AdventureCarouselModal: false,
    SpeakerCarouselModal: false,
    Week1CarouselModal: false,
    Week2CarouselModal: false,
    Week3CarouselModal: false,
    Week4CarouselModal: false,
    Week5CarouselModal: false,
    Week6CarouselModal: false,
    Week7CarouselModal: false,
    Week8CarouselModal: false,
    Week1CarouselModalMobile: false,
    Week2CarouselModalMobile: false,
    Week3CarouselModalMobile: false,
    Week4CarouselModalMobile: false,
    Week5CarouselModalMobile: false,
};
// eslint-disable-next-line default-param-last
export default (state = defaultSetUp, { type, payload }) => {
    switch (type) {
    case SHOW_ADVENTURE_CAROUSEL:
        return {
            ...state,
            AdventureCarouselModal: payload,
        };
    case SHOW_SPEAKER_CAROUSEL:
        return {
            ...state,
            SpeakerCarouselModal: payload,
        };
    case SHOW_WEEK1_MODAL:
        return {
            ...state,
            Week1CarouselModal: payload,
        };
    case SHOW_WEEK2_MODAL:
        return {
            ...state,
            Week2CarouselModal: payload,
        };
    case SHOW_WEEK3_MODAL:
        return {
            ...state,
            Week3CarouselModal: payload,
        };
    case SHOW_WEEK4_MODAL:
        return {
            ...state,
            Week4CarouselModal: payload,
        };
    case SHOW_WEEK5_MODAL:
        return {
            ...state,
            Week5CarouselModal: payload,
        };
    case SHOW_WEEK6_MODAL:
        return {
            ...state,
            Week6CarouselModal: payload,
        };
    case SHOW_WEEK7_MODAL:
        return {
            ...state,
            Week7CarouselModal: payload,
        };
    case SHOW_WEEK8_MODAL:
        return {
            ...state,
            Week8CarouselModal: payload,
        };
    case SHOW_WEEK1_MODAL_MOBILE:
        return {
            ...state,
            Week1CarouselModalMobile: payload,
        };
    case SHOW_WEEK2_MODAL_MOBILE:
        return {
            ...state,
            Week2CarouselModalMobile: payload,
        };
    case SHOW_WEEK3_MODAL_MOBILE:
        return {
            ...state,
            Week3CarouselModalMobile: payload,
        };
    case SHOW_WEEK4_MODAL_MOBILE:
        return {
            ...state,
            Week4CarouselModalMobile: payload,
        };
    case SHOW_WEEK5_MODAL_MOBILE:
        return {
            ...state,
            Week5CarouselModalMobile: payload,
        };
    default:
        return state;
    }
};
