import React from 'react';
import { useTranslation } from 'react-i18next';

// import ogImage from 'assets/image/og-image.png';
import Footer from 'components/Footer';
import HomepageMobile from 'components/Homepage/HomepageMobile';
import Navbar from 'components/Navbar';
import HomepageParallax from 'components/Parallax';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

import LeanOn from '../../assets/image/galleryFulcrum.png';
import PlayChessAgainstYourself from '../../assets/image/galleryPlayChess.png';

function GalleryLayout(props) {
    const {
        event, title, content, img,
    } = props;
    return (
        <div className="relative flex flex-col w-full items-center justify-start px-[15px] md:px-[116px] lg:px-[120px]">
            <div className="relative text-[20px] w-full flex justify-center items-center text-center font-bold sm:text-[32px] lg:text-[48px]">
                {title}
            </div>
            <div className="relative text-[12px] sm:text-[16px] lg:text-[20px] flex justify-center items-center mt-3 text-justify whitespace-pre-wrap">
                {event}
            </div>
            <img src={img} alt={title} className="w-full relative mt-5 lg:mt-10" />
            <div className="relative text-[12px] sm:text-[14px] lg:text-[16px] flex justify-center items-center mt-10 text-justify whitespace-pre-wrap">
                {content}
            </div>
        </div>
    );
}

export default function GalleryPage() {
    const { t } = useTranslation();

    useTitle(t('nav.gallery'), DEFAULT_TITLE);
    return (
        <div className="bg-text-bg-500 h-full flex flex-col">
            <div className="mb-[40px]">
                <Navbar />
            </div>
            <div className="w-full relative flex flex-col mb-[160px] gap-[160px]">
                <GalleryLayout title={t('gallery.0.title')} event={t('gallery.0.event')} content={t('gallery.0.content')} img={PlayChessAgainstYourself} />
                <GalleryLayout title={t('gallery.1.title')} event={t('gallery.1.event')} content={t('gallery.1.content')} img={LeanOn} />
            </div>
            <Footer />
        </div>
    );
}
