import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AgendaEventPage1() {
    const { t } = useTranslation();

    return (
        <div className="leading-[150%] text-[14px] md:text-[17px] 3xl:text-2xl grid-cols-[60px_5px_5px_minmax(0px,_1fr)] grid md:grid-cols-[90px_5px_5px_minmax(0px,_1fr)] grid-rows-5 gap-y-8 2xl:gap-y-10">
            <span className="h-[76px]">{t('event.2.section.3.content.0.time')}</span>
            <div className="row-span-full col-start-2 h-full w-[5px] bg-epink-500 rounded-full" />
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3">{t('event.2.section.3.content.0.name')}</div>

            <span className="h-[76px]">{t('event.2.section.3.content.1.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3">{t('event.2.section.3.content.1.name')}</div>

            <span className="h-[76px]">{t('event.2.section.3.content.2.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3">{t('event.2.section.3.content.2.name')}</div>

            <span className="h-[76px]">{t('event.2.section.3.content.3.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3">{t('event.2.section.3.content.3.name')}</div>

            <span className="h-[76px]">{t('event.2.section.3.content.4.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3">{t('event.2.section.3.content.4.name')}</div>
        </div>

    );
}
