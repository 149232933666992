import { collection } from '@firebase/firestore';
import {
    getStorage, ref, listAll, getDownloadURL,
} from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BichNgoc from 'assets/image/BichNgoc.jpeg';
import { ReactComponent as BigQuotaion } from 'assets/image/bigquotation.svg';
import BinhNghi from 'assets/image/BinhNghi.jpeg';
import ChauHoangCatTuong from 'assets/image/ChauHoangCatTuong.jpg';
import DinhQuang from 'assets/image/DinhQuang.jpg';
import DoKhanhChi from 'assets/image/DoKhanhChi.jpg';
import TuyetNhi from 'assets/image/duong_doan_tuyet_nhi.JPG';
import HongThien from 'assets/image/HongThien.jpg';
import HoSongThuong from 'assets/image/HoSongThuong.jpg';
import HuynhTrang from 'assets/image/HuynhTrang.jpg';
import KhanhLinh from 'assets/image/KhanhLinh.jpg';
import LeTuAnh from 'assets/image/le_tu_anh.jpg';
import LeQuangChien from 'assets/image/LeQuangChien.jpeg';
import LETHANHVY from 'assets/image/LETHANHVY.png';
import LuThanhNen from 'assets/image/LuThanhNen.png';
import MaiTrinh from 'assets/image/MaiTrinh.jpg';
import MinhVy from 'assets/image/MinhVy.jpeg';
import NghiemPhamVyNghi from 'assets/image/NghiemPhamVyNghi.png';
import NgocTram from 'assets/image/NgocTram.jpeg';
import NguyenDuy from 'assets/image/NguyenDuy.jpg';
import NguyenHuuMinhTri from 'assets/image/NguyenHuuMinhTri.jpeg';
import NguyenThaiCuong from 'assets/image/NguyenThaiCuong.jpeg';
import NguyenThiAnhNgoc from 'assets/image/NguyenThiAnhNgoc.png';
import NguyenThiPhucHR from 'assets/image/NguyenThiPhucHR.jpeg';
import NguyenToNhan from 'assets/image/NguyenToNhan.jpeg';
import NguyenVoMyTam from 'assets/image/NguyenVoMyTam.jpg';
import NguyenYenNhi from 'assets/image/NguyenYenNhi.jpg';
import NhatThao from 'assets/image/NhatThao.jpg';
import NhuThao from 'assets/image/NhuThao.jpg';
import PhamNguyenTuanAn from 'assets/image/PhamNguyenTuanAn.jpg';
import PhanHuynhKhanhXuan from 'assets/image/PhanHuynhKhanhXuan.png';
import PhuongNhi from 'assets/image/PhuongNhi.jpg';
import PhuongThanh from 'assets/image/PhuongThanh.jpeg';
import QuiBinh from 'assets/image/QuiBinh.jpeg';
import ThuyDoan from 'assets/image/ThuyDoan.jpg';
import TranDucTri from 'assets/image/tran_duc_tri.png';
import TranNgocDangKhoa from 'assets/image/TranNgocDangKhoa.jpeg';
import TranThiYenNhi from 'assets/image/TranThiYenNhi.png';
import TruongDuyen from 'assets/image/TruongDuyen.jpeg';
import VuongTuVy from 'assets/image/VuongTuVyBTC.png';
import BTCMember from 'components/BTC/BTCMember';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

export default function BTC() {
    const { t } = useTranslation();

    const [imgList, setImgList] = useState([]);
    const storage = getStorage();
    const img = ref(storage, '/btc');

    useEffect(() => {
        listAll(img).then((response) => {
            console.log(Object.values(response));
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImgList((prev) => [...prev, url]);
                });
            });
        });
    }, []);

    useTitle(t('nav.btc'), DEFAULT_TITLE);

    return (
        <div className="bg-text-bg-500 h-full flex flex-col">
            <div className="mb-[40px]">
                <Navbar />
            </div>
            <h1 className="text-[20px] md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[54px] leading-[30px] text-center font-bold">{t('org.0.header')}</h1>
            <div className="bg-tranparent py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center">
                <div className="w-[345px] sm:w-[465px] md:w-[565px] lg:w-[790px] xl:w-[1060px]">
                    <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  leading-[27px] text-center font-bold mb-[15px] md:mb-10">{t('org.1.team')}</h2>
                    <div className="flex flex-wrap justify-center gap-[15px] md:gap-[20px]">
                        <BTCMember name={t('org.1.member.0.name')} position={t('org.1.member.0.role')} imgRef={LeTuAnh} />
                        <BTCMember name={t('org.1.member.1.name')} position={t('org.1.member.1.role')} imgRef={NguyenThiPhucHR} />
                        <BTCMember name={t('org.1.member.2.name')} position={t('org.1.member.2.role')} imgRef={HuynhTrang} />
                        <BTCMember name={t('org.1.member.3.name')} position={t('org.1.member.3.role')} imgRef={ThuyDoan} />
                        <BTCMember name={t('org.1.member.4.name')} position={t('org.1.member.4.role')} imgRef={TuyetNhi} />
                        <BTCMember name={t('org.1.member.5.name')} position={t('org.1.member.5.role')} imgRef={TranDucTri} />
                        {/* <div className="w-full lg:w-[250px] lg:h-[228px] p-5 border-2 border-text-bg-300 rounded-[15px] flex fl
                            <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                            <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar">{t('contact.btc.quote.0')}</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="bg-text-bg-600 py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center">
                <div className="w-[345px] sm:w-[465px] md:w-[565px] lg:w-[790px] xl:w-[1060px]">
                    <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  leading-[27px] text-center font-bold mb-[15px] md:mb-10">{t('org.2.team')}</h2>
                    <div className="flex flex-wrap justify-center gap-[15px] md:gap-[20px]">
                        <BTCMember name={t('org.2.member.0.name')} position={t('org.2.member.0.role')} imgRef={NguyenThaiCuong} />
                        <BTCMember name={t('org.2.member.1.name')} position={t('org.2.member.1.role')} imgRef={TruongDuyen} />
                        <BTCMember name={t('org.2.member.2.name')} position={t('org.2.member.2.role')} imgRef={NguyenThiAnhNgoc} />
                        <BTCMember name={t('org.2.member.3.name')} position={t('org.2.member.3.role')} imgRef={PhamNguyenTuanAn} />
                        <BTCMember name={t('org.2.member.4.name')} position={t('org.2.member.4.role')} imgRef={NguyenYenNhi} />
                        {/* <div className="w-[105px] md:w-[175px] lg:w-[250px] lg:h-[228px] p-5 border-2 border-text-bg-300 rounded-[15px] flex flex-col items-center h-[155px] md:h-[232px]">
                            <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                            <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar">{t('contact.btc.quote.0')}</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="bg-transparent py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center">
                <div className="w-[345px] sm:w-[465px] md:w-[565px] lg:w-[790px] xl:w-[1060px]">
                    <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  leading-[27px] text-center font-bold mb-[15px] md:mb-10">{t('org.3.team')}</h2>
                    <div className="flex flex-wrap justify-center gap-[15px] md:gap-[20px]">
                        {/* <BTCMember name="Nguyễn Hoàng Mai Trinh" position="Member" imgRef={MaiTrinh} /> */}
                        <BTCMember name={t('org.3.member.1.name')} position={t('org.3.member.1.role')} imgRef={QuiBinh} />
                        <BTCMember name={t('org.3.member.2.name')} position={t('org.3.member.2.role')} imgRef={LeQuangChien} />
                        <BTCMember name={t('org.3.member.3.name')} position={t('org.3.member.3.role')} imgRef={LuThanhNen} />
                        <BTCMember name={t('org.3.member.4.name')} position={t('org.3.member.4.role')} imgRef={NhatThao} />
                        <BTCMember name={t('org.3.member.5.name')} position={t('org.3.member.5.role')} imgRef={NguyenDuy} />
                        <BTCMember name={t('org.3.member.6.name')} position={t('org.3.member.6.role')} imgRef={NgocTram} />
                        <BTCMember name={t('org.3.member.7.name')} position={t('org.3.member.0.role')} imgRef={PhuongNhi} />
                        {/* <BTCMember name="Trần Hồng Thiện" position="Member" imgRef={HongThien} /> */}
                        {/* <div className="w-full lg:w-[250px] lg:h-[228px] p-5 border-2 border-text-bg-300 rounded-[15px] flex flex-col items-center h-[155px]">
                            <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                            <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar">{t('contact.btc.quote.0')}</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="bg-text-bg-600 py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center">
                <div className="w-[345px] sm:w-[465px] md:w-[565px] lg:w-[790px] xl:w-[1060px]">
                    <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  leading-[27px] text-center font-bold mb-[15px] md:mb-10">{t('org.4.team')}</h2>
                    <div className="flex flex-wrap justify-center gap-[15px] md:gap-[20px]">
                        <BTCMember name={t('org.4.member.0.name')} position={t('org.4.member.0.role')} imgRef={TranThiYenNhi} />
                        <BTCMember name={t('org.4.member.1.name')} position={t('org.4.member.1.role')} imgRef={BichNgoc} />
                        <BTCMember name={t('org.4.member.2.name')} position={t('org.4.member.2.role')} imgRef={LETHANHVY} />
                        <BTCMember name={t('org.4.member.3.name')} position={t('org.4.member.3.role')} imgRef={DinhQuang} />
                        <BTCMember name={t('org.4.member.4.name')} position={t('org.4.member.4.role')} imgRef={PhanHuynhKhanhXuan} />
                        <BTCMember name={t('org.4.member.5.name')} position={t('org.4.member.5.role')} imgRef={DoKhanhChi} />
                        <BTCMember name={t('org.4.member.6.name')} position={t('org.4.member.6.role')} imgRef={BinhNghi} />
                        <BTCMember name={t('org.4.member.7.name')} position={t('org.4.member.7.role')} imgRef={NguyenHuuMinhTri} />
                        <BTCMember name={t('org.4.member.8.name')} position={t('org.4.member.8.role')} imgRef={VuongTuVy} />
                        {/* <div className="w-[105px] md:w-[175px] lg:w-[250px] lg:h-[228px] p-5 role-2 border-text-bg-300 rounded-[15px] flex flex-col items-center h-[155px] md:h-[232px]">
                            <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                            <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar">{t('contact.btc.quote.0')}</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="bg-transparent py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center">
                <div className="w-[345px] sm:w-[465px] md:w-[565px] lg:w-[790px] xl:w-[1060px]">
                    <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  leading-[27px] text-center font-bold mb-[15px] md:mb-10">{t('org.5.team')}</h2>
                    <div className="flex flex-wrap justify-center gap-[15px] md:gap-[20px] w-fit">
                        <BTCMember name={t('org.5.member.0.name')} position={t('org.5.member.0.role')} imgRef={HoSongThuong} />
                        <BTCMember name={t('org.5.member.1.name')} position={t('org.5.member.1.role')} imgRef={NhuThao} />
                        <BTCMember name={t('org.5.member.2.name')} position={t('org.5.member.2.role')} imgRef={PhuongThanh} />
                        <BTCMember name={t('org.5.member.3.name')} position={t('org.5.member.3.role')} imgRef={MinhVy} />
                        <BTCMember name={t('org.5.member.4.name')} position={t('org.5.member.4.role')} imgRef={ChauHoangCatTuong} />
                        <BTCMember name={t('org.5.member.5.name')} position={t('org.5.member.5.role')} imgRef={KhanhLinh} />
                        {/* <div className="w-full lg:w-[250px] lg:h-[228px] p-5 border-2 border-text-bg-300 rounded-[15px] flex flex-col items-center h-[155px]">
                            <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                            <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar">{t('contact.btc.quote.0')}</span>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <div className="bg-text-bg-600 py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center">
                <div className="w-[345px] sm:w-[465px] md:w-[565px] lg:w-[790px] xl:w-[1060px]">
                    <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  leading-[27px] text-center font-bold mb-[15px] md:mb-10">Team Guest</h2>
                    <div className="flex flex-wrap justify-center lg:justify-start gap-[15px] md:gap-[20px] w-fit">
                        <BTCMember name="Trần Minh Vy" position="Member" imgRef={MinhVy} />
                        <BTCMember name="Châu Hoàng Cát Tường" position="Member" imgRef={ChauHoangCatTuong} />
                        <BTCMember name="Nguyễn Ngọc Khánh Linh" position="Member" imgRef={KhanhLinh} />
                        <div className="w-full lg:w-[250px] lg:h-[228px] p-5 border-2 border-text-bg-300 rounded-[15px] flex flex-col items-center h-[155px]">
                            <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                            <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar">{t('contact.btc.quote.0')}</span>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="bg-transparent py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center">
                <div className="w-[345px] sm:w-[465px] md:w-[565px] lg:w-[790px] xl:w-[1060px]">
                    <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  leading-[27px] text-center font-bold mb-[15px] md:mb-10">{t('org.6.team')}</h2>
                    <div className="flex flex-wrap justify-center gap-[15px] md:gap-[20px]">
                        <BTCMember name={t('org.6.member.0.name')} position={t('org.6.member.0.role')} imgRef={NguyenVoMyTam} />
                        <BTCMember name={t('org.6.member.1.name')} position={t('org.6.member.1.role')} imgRef={NguyenToNhan} />
                        {/* <div className="w-[105px] md:w-[175px] lg:w-[250px] lg:h-[228px] p-5 border-2 border-text-bg-300 rounded-[15px] flex flex-col items-center h-[155px] md:h-[232px]">
                            <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                            <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar">{t('contact.btc.quote.0')}</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="bg-blue-700 py-[30px] md:py-[40px] lg:py-[52px] xl:py-[64px] 2xl:py-[80px] px-[15px] flex flex-col items-center mb-[120px]">
                <div className="w-[345px] sm:w-[465px] md:w-[565px] lg:w-[790px] xl:w-[1060px]">
                    <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl  leading-[27px] text-center font-bold mb-[15px] md:mb-10">{t('org.7.team')}</h2>
                    <div className="w-full px-28 mb-[80px] rounded-[15px] flex flex-col items-center ">
                        <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                        <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar flex justify-center items-center text-center">{t('contact.btc.quote.0')}</span>
                    </div>
                    <div className="flex flex-wrap justify-center gap-[15px] md:gap-[20px]">
                        <BTCMember name={t('org.7.member.0.name')} position={t('org.7.member.0.role')} imgRef="https://firebasestorage.googleapis.com/v0/b/gdsc-recruitment.appspot.com/o/co_lead.webp?alt=media&token=3448d9f5-2d3e-41d3-87fa-4e9ddc09b35e" />
                        <BTCMember name={t('org.7.member.1.name')} position={t('org.7.member.1.role')} imgRef={TranNgocDangKhoa} />
                        <BTCMember name={t('org.7.member.2.name')} position={t('org.7.member.2.role')} imgRef="https://firebasestorage.googleapis.com/v0/b/gdsc-recruitment.appspot.com/o/head_dev.webp?alt=media&token=8f71de0e-c3d4-4620-9f4a-f78cccd156d2" />
                        {/* <BTCMember name={t('org.7.member.3.name')} position={t('org.7.member.3.role')} imgRef={imgList[0]} /> */}
                        {/* <BTCMember name={t('org.7.member.4.name')} position={t('org.7.member.4.role')} imgRef="https://firebasestorage.googleapis.com/v0/b/gdsc-recruitment.appspot.com/o/head_er.jfif?alt=media&token=01f5be1d-3b93-4bde-83f9-0a7ad5091a58" /> */}
                        <BTCMember name={t('org.7.member.5.name')} position={t('org.7.member.5.role')} imgRef={NghiemPhamVyNghi} />
                        {/* <div className="w-[105px] md:w-[175px] lg:w-[250px] lg:h-[228px] p-5 border-2 border-text-bg-300 rounded-[15px] flex flex-col items-center h-[155px] md:h-[232px]">
                            <BigQuotaion className="w-3 md:w-[27px] aspect-square" />
                            <span className="text-[10px] md:text-base leading-[15px] w-full overflow-y-scroll no-scrollbar">{t('contact.btc.quote.0')}</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
