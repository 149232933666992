import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from './Title';

export default function Speaker() {
    const { t } = useTranslation();
    return (
        <div className="gioithieu sticky left-0 w-screen flex flex-col space-x-5 xl:space-x-0 justify-around align-start bg-[#1D1C1C] mt-0 pt-0 px-[15px] md:px-[40px] lg:px-[120px] xl:px-[160px] 3xl:px-[240px] z-20">
            <div className="w-[600px] mt-[30px] tracking-wide z-0">
                <h1 className="2xl:text-4xl xl:text-3xl lg:text-2xl text-xl font-bold capitalize">{t('home.0.title')}</h1>
                <p className="xl:text-lg text-base w-[100%] mt-[24px] text-text-bg-100 xl:font-sm 2xl:font-light">
                    {t('home.0.content')}
                </p>
                <button type="button" onClick={() => {}} className="bg-blue-500 hover:bg-blue-700 text-white text-base lg:text-lg xl:text-xl 2xl:text-2xl font-bold py-3 px-6 rounded-[48px] cursor-pointer leading-6 text-center mt-[20px]">
                    {t('title.par')}
                </button>
            </div>
        </div>
    );
}
