import React from 'react';
import { useTranslation } from 'react-i18next';

import adventureBNTD from 'assets/image/adventureBNTD.png';
import adventureGTTH from 'assets/image/adventureGTTH.png';
import adventureKLYC from 'assets/image/adventureKLYC.png';
import aventureKud from 'assets/image/adventureKud.png';
import adventureM11 from 'assets/image/adventureM11.png';
import adventureNTDT from 'assets/image/adventureNTDT.png';
import adventureTDHD from 'assets/image/adventureTDHD.png';
import adventureVhVst from 'assets/image/adventureVhVSt.png';
import ArenaLogo from 'assets/image/arena_logo.png';
import { ReactComponent as BigQuotaion } from 'assets/image/bigquotation.svg';
import BrandsLogo from 'assets/image/BrandsLogo2.png';
import BrowniesLogo from 'assets/image/brownies_logo.png';
import CanonLogo from 'assets/image/canon_logo.png';
import Edu2ReviewLogo2 from 'assets/image/Edu2Review2.png';
import GDSCLogo2 from 'assets/image/GDSCLogo2.png';
import GiangMinhPhuong from 'assets/image/giangminhphuong.png';
import GianTuTrung from 'assets/image/giantutrung.jpg';
import HaLoc from 'assets/image/haloc.png';
import IlseZoreb from 'assets/image/ilsezoerb.png';
import IVolunteerLogo from 'assets/image/ivolunteer_logo.png';
import KhoaChim from 'assets/image/khoachim.png';
import KoikeyaLogo from 'assets/image/koikeya_logo.png';
import LeAn from 'assets/image/lean.png';
import MIR from 'assets/image/mirclub.jpg';
import MrBrownLogo from 'assets/image/mrbrown_logo.png';
import NguyenMinhHau from 'assets/image/nguyenminhhau.png';
import NguyenPhuongNhuY from 'assets/image/nguyenphuongnhuy.jpg';
import NguyenTranThaoChi from 'assets/image/nguyentranthaochi.png';
import NguyenNhuPhong from 'assets/image/nhuphong.png';
import PhuongThao from 'assets/image/phuongthao.jpg';
import RomanoLogo from 'assets/image/romano_logo.png';
import DaoDuongCongToai from 'assets/image/toi.png';
import NguyenToNi from 'assets/image/toni.png';
import TranThanhCanh from 'assets/image/tranthanhcanh.jpg';
import TranThuHa from 'assets/image/tranthuha.jpg';
import UniformLogo from 'assets/image/uniform_logo.png';
import UsshLogo from 'assets/image/ussh_logo.png';
import VedetteLogo from 'assets/image/vedette_logo.png';
import VuongTuVy from 'assets/image/vuongtuvy.png';
import YBoxLogo2 from 'assets/image/YboxLogo2.png';
import AgendaEventPage2 from 'components/Agenda/AgendaEventPage2';
import EventCarousel from 'components/EventCarousel';
import AdventureCarousel from 'components/EventCarousel/AdventureCarousel';
import CarouselElement from 'components/EventCarousel/CarouselElement';
import SpeakerCarousel from 'components/EventCarousel/SpeakerCarousel';
import EventTimeLine from 'components/EventTimeline';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import './page2.css';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

export default function EventPage2() {
    const { t } = useTranslation();
    useTitle(t('event.1.title'), DEFAULT_TITLE);
    return (
        <div className="bg-text-bg-500 flex flex-col">
            <div className="relative h-[160px] xs:h-[240px] sm:h-[320px] md:h-[480px] xl:h-[640px] 2xl:h-[800px] bg-page2Background bg-cover bg-opacity-100 flex flex-col">
                <Navbar />
                <p className="max-w-[800px] absolute bottom-[15px] md:bottom-[96px] 2xl:bottom-[120px] font-bold md:font-medium p-5 md:p-0 md:text-[19.2px] 2xl:text-2xl mx-[15px] md:mx-[116px] lg:mx-[200px] 2xl:mx-[250px]">
                    {t('event.1.intro')}
                </p>
            </div>
            <div className="px-[15px] md:px-[40px] lg:px-[116px] xl:px-[200px] 2xl:px-[250px] mb-[96px] 2xl:mb-[120px] ">
                <EventTimeLine numPage={2} />
                <div className="mt-[96px] 2xl:mt-[120px] flex outline outline-2 outline-epink-500 lg:outline-offset-[40px] p-[40px] lg:p-0 rounded-[15px]">
                    <span className="font-bold text-lg md:text-[36px] 2xl:text-[40px] min-w-[120px] md:min-w-[258px] lg:min-w-[300px] xl:min-w-[480px] leading-[120%] mr-[10px]">{t('event.1.section.0.title')}</span>
                    <p className="w-full text-[14px] md:text-xl 2xl:text-[20px] whitespace-pre-line">{t('event.1.section.0.content')}</p>
                </div>
                <EventCarousel name={t('event.1.section.1.title')} ID="adventureTobe">
                    <CarouselElement
                        title={t('event.1.section.1.content.0.name')}
                        description={t('event.1.section.1.content.0.content')}
                        shortDescription=""
                        clickable
                        img={aventureKud}
                    />
                    <CarouselElement
                        title={t('event.1.section.1.content.1.name')}
                        description={t('event.1.section.1.content.1.content')}
                        shortDescription=""
                        clickable
                        img={adventureM11}
                    />
                    <CarouselElement
                        title={t('event.1.section.1.content.2.name')}
                        description={t('event.1.section.1.content.2.content')}
                        shortDescription=""
                        clickable
                        img={adventureVhVst}
                    />
                </EventCarousel>
                <EventCarousel name={t('event.1.section.2.title')} ID="adventureTalk">
                    <CarouselElement
                        title={t('event.1.section.2.content.0.name')}
                        description={t('event.1.section.2.content.0.content')}
                        shortDescription=""
                        clickable
                        img={adventureKLYC}
                    />
                    <CarouselElement
                        title={t('event.1.section.2.content.1.name')}
                        description={t('event.1.section.2.content.1.content')}
                        shortDescription=""
                        clickable
                        img={adventureGTTH}
                    />
                    <CarouselElement
                        title={t('event.1.section.2.content.2.name')}
                        description={t('event.1.section.2.content.2.content')}
                        shortDescription=""
                        clickable
                        img={adventureBNTD}
                    />
                    <CarouselElement
                        title={t('event.1.section.2.content.3.name')}
                        description={t('event.1.section.2.content.3.content')}
                        shortDescription=""
                        clickable
                        img={adventureNTDT}
                    />
                    <CarouselElement
                        title={t('event.1.section.2.content.4.name')}
                        description={t('event.1.section.2.content.4.content')}
                        shortDescription=""
                        clickable
                        img={adventureTDHD}
                    />
                </EventCarousel>
                <div className="mt-[160px] flex">
                    <span className="font-bold text-lg md:text-[36px] 2xl:text-[40px] min-w-[120px] md:min-w-[258px] lg:min-w-[300px] xl:min-w-[480px] leading-[120%] mr-[10px]">{t('event.1.section.3.title')}</span>
                    <AgendaEventPage2 />
                </div>
                <div className="mb-[15px] md:mb-[16px] lg:mb-[28px] xl:mb-[40px] mt-[80px] md:mt-[96px]">
                    <h1 className="text-lg md:text-[36px] 2xl:text-[40px] md:leading-[64.8px] font-medium md:font-bold w-[100%]">
                        {t('event.1.section.4.title')}
                    </h1>
                </div>
                <SpeakerCarousel name={t('event.1.section.4.content.0.title')} ID="to_be">
                    <CarouselElement
                        title={t('event.1.section.4.content.0.content.0.name')}
                        shortDescription={t('event.1.section.4.content.0.content.0.title')}
                        session={t('event.1.section.4.content.0.content.0.session')}
                        description={t('event.1.section.4.content.0.content.0.description')}
                        clickable
                        img={LeAn}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.0.content.1.name')}
                        shortDescription={t('event.1.section.4.content.0.content.1.title')}
                        session={t('event.1.section.4.content.0.content.1.session')}
                        description={t('event.1.section.4.content.0.content.1.description')}
                        clickable
                        img={IlseZoreb}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.0.content.2.name')}
                        shortDescription={t('event.1.section.4.content.0.content.2.title')}
                        session={t('event.1.section.4.content.0.content.2.session')}
                        description={t('event.1.section.4.content.0.content.2.description')}
                        clickable
                        img={NguyenToNi}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.0.content.3.name')}
                        shortDescription={t('event.1.section.4.content.0.content.3.title')}
                        session={t('event.1.section.4.content.0.content.3.session')}
                        description={t('event.1.section.4.content.0.content.3.description')}
                        clickable
                        img={GiangMinhPhuong}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.0.content.4.name')}
                        shortDescription={t('event.1.section.4.content.0.content.4.title')}
                        session={t('event.1.section.4.content.0.content.4.session')}
                        description={t('event.1.section.4.content.0.content.4.description')}
                        clickable
                        img={NguyenTranThaoChi}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.0.content.5.name')}
                        shortDescription={t('event.1.section.4.content.0.content.5.title')}
                        session={t('event.1.section.4.content.0.content.5.session')}
                        description={t('event.1.section.4.content.0.content.5.description')}
                        clickable
                        img={KhoaChim}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.0.content.6.name')}
                        shortDescription={t('event.1.section.4.content.0.content.6.title')}
                        session={t('event.1.section.4.content.0.content.6.session')}
                        description={t('event.1.section.4.content.0.content.6.description')}
                        clickable
                        img={NguyenNhuPhong}
                    />
                </SpeakerCarousel>
                <SpeakerCarousel name={t('event.1.section.4.content.1.title')} ID="be_us">
                    <CarouselElement
                        title={t('event.1.section.4.content.1.content.0.name')}
                        shortDescription={t('event.1.section.4.content.1.content.0.title')}
                        session={t('event.1.section.4.content.1.content.0.session')}
                        description={t('event.1.section.4.content.1.content.0.description')}
                        clickable
                        img={TranThuHa}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.1.content.1.name')}
                        shortDescription={t('event.1.section.4.content.1.content.1.title')}
                        session={t('event.1.section.4.content.1.content.1.session')}
                        description={t('event.1.section.4.content.1.content.1.description')}
                        clickable
                        img={NguyenPhuongNhuY}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.1.content.2.name')}
                        shortDescription={t('event.1.section.4.content.1.content.2.title')}
                        session={t('event.1.section.4.content.1.content.2.session')}
                        description={t('event.1.section.4.content.1.content.2.description')}
                        clickable
                        img={PhuongThao}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.1.content.3.name')}
                        shortDescription={t('event.1.section.4.content.1.content.3.title')}
                        session={t('event.1.section.4.content.1.content.3.session')}
                        description={t('event.1.section.4.content.1.content.3.description')}
                        clickable
                        img={TranThanhCanh}
                    />
                    <CarouselElement
                        title={t('event.1.section.4.content.1.content.4.name')}
                        shortDescription={t('event.1.section.4.content.1.content.4.title')}
                        session={t('event.1.section.4.content.1.content.4.session')}
                        description={t('event.1.section.4.content.1.content.4.description')}
                        clickable
                        img={GianTuTrung}
                    />
                </SpeakerCarousel>
                <SpeakerCarousel name={t('event.1.section.5.title')} ID="host">
                    <CarouselElement
                        title={t('event.1.section.5.content.0.name')}
                        shortDescription={t('event.1.section.5.content.0.title')}
                        session={t('event.1.section.5.content.0.session')}
                        description={t('event.1.section.5.content.0.description')}
                        clickable
                        img={NguyenMinhHau}
                    />
                    <CarouselElement
                        title={t('event.1.section.5.content.1.name')}
                        shortDescription={t('event.1.section.5.content.1.title')}
                        session={t('event.1.section.5.content.1.session')}
                        description={t('event.1.section.5.content.1.description')}
                        clickable
                        img={VuongTuVy}
                    />
                </SpeakerCarousel>
                <SpeakerCarousel name={t('event.1.section.6.title')} ID="performer">
                    <CarouselElement
                        title={t('event.1.section.6.content.0.name')}
                        shortDescription={t('event.1.section.6.content.0.title')}
                        session={t('event.1.section.6.content.0.session')}
                        description={t('event.1.section.6.content.0.description')}
                        clickable
                        img={DaoDuongCongToai}
                    />
                    <CarouselElement
                        title={t('event.1.section.6.content.1.name')}
                        shortDescription={t('event.1.section.6.content.1.title')}
                        session={t('event.1.section.6.content.1.session')}
                        description={t('event.1.section.6.content.1.description')}
                        clickable
                        img={HaLoc}
                    />
                    <CarouselElement
                        title={t('event.1.section.6.content.2.name')}
                        shortDescription={t('event.1.section.6.content.2.title')}
                        session={t('event.1.section.6.content.2.session')}
                        description={t('event.1.section.6.content.2.description')}
                        clickable
                        img={NguyenPhuongNhuY}
                    />
                    <CarouselElement
                        title={t('event.1.section.6.content.3.name')}
                        shortDescription={t('event.1.section.6.content.3.title')}
                        session={t('event.1.section.6.content.3.session')}
                        description={t('event.1.section.6.content.3.description')}
                        img={MIR}
                    />
                </SpeakerCarousel>
            </div>
            <div className="slideShow bg-white xl:mt-[120px]">
                <div className="slideTrack">
                    <img className="slide w-[120px] object-contain" src={UsshLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={ArenaLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={RomanoLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={BrowniesLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={MrBrownLogo} alt="LogoSponsor" />
                    <div className="slide w-[275px]"><img className="w-[275px]" src={CanonLogo} alt="LogoSponsor" /></div>
                    <img className="slide w-[120px] object-contain" src={KoikeyaLogo} alt="LogoSponsor" />
                    <img className="slide w-[180px] object-contain" src={GDSCLogo2} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={VedetteLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={IVolunteerLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={YBoxLogo2} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={Edu2ReviewLogo2} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={BrandsLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={UniformLogo} alt="LogoSponsor" />

                </div>
                <div className="slideTrackReplicate hidden">
                    <img className="slide w-[120px] object-contain" src={UsshLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={ArenaLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={RomanoLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={BrowniesLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={MrBrownLogo} alt="LogoSponsor" />
                    <div className="slide w-[275px]"><img className="w-[275px]" src={CanonLogo} alt="LogoSponsor" /></div>
                    <img className="slide w-[120px] object-contain" src={KoikeyaLogo} alt="LogoSponsor" />
                    <img className="slide w-[180px] object-contain" src={GDSCLogo2} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={VedetteLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={IVolunteerLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={YBoxLogo2} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={Edu2ReviewLogo2} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={BrandsLogo} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={UniformLogo} alt="LogoSponsor" />
                </div>
            </div>
            <div className="mb-[96px] 2xl:mb-[120px] " />
            <Footer />
        </div>
    );
}
