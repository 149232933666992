import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function NavbarDesktopMenu({ mainRoute }) {
    const { t, i18n } = useTranslation();
    return (
        <div className="hidden xl:flex space-x-7 2xl:space-x-9 items-center w-fit h-12">
            <div className="flex justify-between items-center space-x-[20px] 2xl:space-x-10 px-5 py-[8px] 2xl:py-[10px] border-solid border-2 border-blue-500 bg-text-bg-500 rounded-full">
                <Link to="/event" className={`text-white hover:text-blue-500 ${mainRoute === 'event' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.event')}</Link>
                <Link to="/learn" className={`text-white hover:text-blue-500 ${mainRoute === 'learn' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.learn')}</Link>
                <a href="/podcast" className={`text-white hover:text-blue-500 ${mainRoute === 'podcast' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.podcast')}</a>
                <a href="/sponsor" className={`text-white hover:text-blue-500 ${mainRoute === 'sponsor' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.sponsor')}</a>
                <Link to="/contact" className={`text-white hover:text-blue-500 ${mainRoute === 'contact' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.contact')}</Link>
                <Link to="/gallery" className={`text-white hover:text-blue-500 ${mainRoute === 'gallery' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.gallery')}</Link>
                {/* <a href="/" className={`text-white hover:text-blue-500 ${mainRoute === 'book' && 'text-blue-500'} transition duration-150 ease-linear`}>{t('nav.book')}</a> */}
            </div>
            <div className="flex space-x-[10px]">
                <button
                    className={`cursor-pointer flex justify-center items-center border-solid border-2 border-white rounded-full xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 text-white ${
                        i18n.resolvedLanguage !== 'vn' && 'opacity-40'
                    }`}
                    onClick={() => i18n.changeLanguage('vn')}
                    type="button"
                >
                    vn
                </button>
                <button
                    className={`cursor-pointer flex justify-center items-center border-solid border-2 border-white rounded-full xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 text-white ${
                        i18n.resolvedLanguage !== 'en' && 'opacity-40'
                    }`}
                    onClick={() => i18n.changeLanguage('en')}
                    type="button"
                >
                    en
                </button>
            </div>
        </div>
    );
}

NavbarDesktopMenu.propTypes = {
    mainRoute: PropTypes.string,
};

NavbarDesktopMenu.defaultProps = {
    mainRoute: '',
};
