import PropTypes from 'prop-types';
import React from 'react';

export default function BTCMember({ name, position, imgRef = 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg' }) {
    return (
        <div className="pt-[15px] w-[120px] md:w-[200px] lg:w-[280px] flex flex-col items-center">
            <img className="w-[80px] md:w-[150px] 2xl:w-[240px] mx-[12.5px] lg:mx-[50px]  aspect-square rounded-full mb-[15px] object-cover" src={imgRef} alt="ran" />
            <div className="text-[10px] md:text-base 2xl:text-2xl leading-[150%] w-full text-white text-center font-bold">{name}</div>
            <div className="text-[10px] md:text-base leading-[150%] w-full text-text-bg-300 text-center whitespace-pre-wrap">{position}</div>
        </div>
    );
}

BTCMember.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
};
