import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SHOW_SPEAKER_CAROUSEL } from 'action-types';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow_right.svg';
import CarouselModal from 'components/CarouselModal';

export default function EventCarousel({ name, ID, children }) {
    const [position, setPosition] = useState('left');
    const [width, setWidth] = useState(window.innerWidth);
    const [currPos, setCurrPos] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const content = React.Children.map(children, (element) => element.props);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    let slideDistance = 320;
    let slideInterval = 80;
    if (width >= 1280) {
        slideDistance = 360;
        slideInterval = 90;
    }

    const handleScroll = (e) => {
        const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
        if (scrollLeft === 0) setPosition('left');
        else if (scrollLeft + clientWidth === scrollWidth) {
            setPosition('right');
        } else setPosition('mid');
    };
    const moveRight = () => {
        let scrollAmount = 0;
        const element = document.getElementById(ID);
        const slideTimer = setInterval(() => {
            element.scrollLeft += slideInterval;
            scrollAmount += slideInterval;
            if (scrollAmount >= slideDistance) {
                window.clearInterval(slideTimer);
            }
        }, 25);
        if (element.scrollLeft + element.clientWidth === element.scrollWidth) {
            setPosition('right');
        } else setPosition('mid');
    };
    const moveLeft = () => {
        let scrollAmount = 0;
        const element = document.getElementById(ID);
        const slideTimer = setInterval(() => {
            element.scrollLeft -= slideInterval;
            scrollAmount += slideInterval;
            if (scrollAmount >= slideDistance) {
                window.clearInterval(slideTimer);
            }
        }, 25);
        if (element.scrollLeft === 0) setPosition('left');
        else setPosition('mid');
    };
    return (
        <div className="flex mt-[120px]">
            <CarouselModal
                show={openModal}
                onClose={() => setOpenModal(false)}
                title={content[currPos].title}
                shortDescription={content[currPos].shortDescription}
                description={content[currPos].description}
                img={content[currPos].img}
            />
            <div className="mr-[15px] md:mr-[80px] lg:mr-[100px] xl:mr-[100px]">
                <h1 className="text-lg md:text-[36px] 2xl:text-[40px] md:leading-[64.8px] font-medium md:font-bold w-[105px] md:w-[190px] xl:w-[340px]">
                    {name}
                </h1>
            </div>
            <div className="w-[calc(100%-120px)] md:w-[calc(100%-270px)] lg:w-[calc(100%-290px)] xl:w-[calc(100%-480px)] relative flex items-center md:px-5">
                {position !== 'left' && (
                    <button type="button" className="hidden md:flex absolute left-0 w-12 h-12 bg-black rounded-full border-2 border-blue-500 justify-center items-center" onClick={moveLeft}>
                        <LeftArrowIcon />
                    </button>
                )}
                {(position !== 'right') && (
                    <button type="button" className="hidden md:flex absolute right-0 w-12 h-12 bg-black rounded-full border-2 border-blue-500 justify-center items-center" onClick={moveRight}>
                        <RightArrowIcon />
                    </button>
                )}
                <div className="h-[200px] md:h-[466px] flex overflow-x-auto no-scrollbar" id={ID} onScroll={handleScroll}>
                    {React.Children.map(children, (element, idx) => {
                        const newProps = { ...element.props, click: () => setCurrPos(idx), openModal: () => setOpenModal(true) };

                        const newElement = { ...element, props: newProps };
                        return newElement;
                    })}
                </div>
            </div>
        </div>
    );
}
EventCarousel.propTypes = {
    name: PropTypes.string.isRequired,
    ID: PropTypes.string.isRequired,
};
