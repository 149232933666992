import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Footer from 'components/Footer';
import FilterBar from 'components/LearnPageDesktop/FilterBar';
import Navbar from 'components/Navbar';

import BlogSection from './BlogSection';
import VideoSection from './VideoSection';

export default function LearnPageDesktop() {
    const { t } = useTranslation();
    const [type, setType] = useState('Blog');
    const [year, setYear] = useState('');
    const [genre, setGenre] = useState('');
    const [sortType, setSortType] = useState('');

    const resetYear = () => {
        setYear('');
    };
    const resetType = () => {
        setGenre('');
    };
    const resetAll = () => {
        setYear('');
        setGenre('');
    };

    return (
        <div className="hidden md:flex flex-col bg-text-bg-500">
            <Navbar />
            <div className="h-full px-[15px] md:px-[40px] lg:px-[116px] xl:px-[200px] 2xl:px-[250px] mb-[168px]">
                <div className="w-full flex flex-row mx-auto max-w-[1420px]">
                    <FilterBar sortType={sortType} year={year} genre={genre} setYear={setYear} setSortType={setSortType} setGenre={setGenre} />
                    <div className="flex flex-col items-end w-full md:space-y-8 2xl:space-y-10">
                        <div className="md:space-x-2 xl:space-x-4 2xl:space-x-5">
                            <button
                                className={`md:w-[64px] xl:w-[80px] 2xl:w-[100px] aspect-[25/12] font-bold md:text-md xl:text-xl 2xl:text-2xl md:rounded-[6.4px] xl:rounded-[8px] 2xl:rounded-[10px ${type === 'Blog' && 'bg-blue-500'}`}
                                type="button"
                                onClick={() => setType('Blog')}
                            >
                                {t('learn.blog.title')}
                            </button>
                            <button
                                className={`md:w-[64px] xl:w-[80px] 2xl:w-[100px] aspect-[25/12] font-bold md:text-md xl:text-xl 2xl:text-2xl md:rounded-[6.4px] xl:rounded-[8px] 2xl:rounded-[10px] ${type === 'Video' && 'bg-blue-500'}`}
                                type="button"
                                onClick={() => setType('Video')}
                            >
                                {t('learn.video.title')}
                            </button>
                        </div>
                        <div className="w-full md:space-y-[76.8px] xl:space-y-[96px] 2xl:space-y-[120px]">
                            { type === 'Blog' && (
                                <BlogSection
                                    year={year}
                                    genre={genre}
                                    sortType={sortType}
                                    resetYear={resetYear}
                                    resetType={resetType}
                                    resetAll={resetAll}
                                />
                            ) }
                            { type === 'Video' && (
                                <VideoSection
                                    year={year}
                                    genre={genre}
                                    sortType={sortType}
                                    resetYear={resetYear}
                                    resetType={resetType}
                                    resetAll={resetAll}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
