import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import React, { useMemo } from 'react';

export default function Map() {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBzKWzv05BesJzTrMWaXCVsX6iH6LXLTF4',
    });
    const center = useMemo(() => ({ lat: 10.7858607, lng: 106.7027086 }), []);
    if (!isLoaded) return <div>Loading...</div>;
    return (
        // <GoogleMap zoom={15} center={center} mapContainerClassName="border-solid border-2 border-white h-[145px] md:h-[220px] mb-8 rounded-[15px] md:w-[688px] lg:w-[624px] xl:w-[510px] 3xl:w-[700px]">
        //     <Marker position={center} />
        // </GoogleMap>
        <div className="border-solid border-2 border-white h-[145px] md:h-[220px] mb-8 rounded-[15px] md:w-[688px] lg:w-[624px] xl:w-[510px] 3xl:w-[700px]">
            <iframe
                title="HCMUSSH"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31355.27562600261!2d106.66556857017943!3d10.779918958417483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f4baa197517%3A0x8872da8526a26f7c!2zVHLGsOG7nW5nIMSQ4bqhaSBo4buNYyBLaG9hIGjhu41jIFjDoyBo4buZaSB2w6AgTmjDom4gdsSDbiAoSENNVVNTSCkgLSDEkOG6oWkgaOG7jWMgUXXhu5FjIGdpYSBUUC5IQ00!5e0!3m2!1svi!2s!4v1664166875388!5m2!1svi!2s"
                className="border-none rounded-[15px] w-full md:w-[688px] lg:w-[624px] xl:w-[510px] 3xl:w-[700px] h-[145px] md:h-[220px]"
                allowFullScreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            />
        </div>
    );
}
