import React from 'react';
import { useTranslation } from 'react-i18next';

import AgendaEventPage3 from 'components/Agenda/AgendaEventPage3';
import EventCarousel from 'components/EventCarousel';
import CarouselElement from 'components/EventCarousel/CarouselElement';
import SpeakerCarousel from 'components/EventCarousel/SpeakerCarousel';
import EventTimeLine from 'components/EventTimeline';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

import adventureCD from '../../assets/image/adventureCD.jpg';
import adventureCLTH from '../../assets/image/adventureCLTH.jpg';
import adventureCPNT from '../../assets/image/adventureCPTN.jpg';
import adventureHGCM from '../../assets/image/adventureHGCM.jpg';
import adventureKTXH from '../../assets/image/adventureKTXH.jpg';
import adventureMTTH from '../../assets/image/adventureMTTH.jpg';
import adventureNTCD from '../../assets/image/adventureNTCD.jpg';
import adventurePQDH from '../../assets/image/adventurePQDH.jpg';
import adventureYTVT from '../../assets/image/adventureYTVT.jpg';
import amazingGroup from '../../assets/image/amazinggroup.png';
import BuiTranPhuong from '../../assets/image/buitranphuong.jpg';
import cafeVanKhoa from '../../assets/image/cafevankhoa.png';
import Edu2ReviewLogo2 from '../../assets/image/Edu2Review2.png';
import educationMalaysia from '../../assets/image/educationmalaysia.jpg';
import GermerTeam from '../../assets/image/germerteam.jpg';
import HangMai from '../../assets/image/hangmai.jpg';
import hoiSinhVien from '../../assets/image/hoisinhvien.png';
import LeonQuangLe from '../../assets/image/leonquangle.jpg';
import lienChiHoi from '../../assets/image/lienchihoi.png';
import MarcusManhCuongVu from '../../assets/image/marcusmanhcuongvu.png';
import myHanh from '../../assets/image/myhanh.jpg';
import NamPhuong from '../../assets/image/namphuong.png';
import ncn from '../../assets/image/ncn.png';
import NguyenDinhPhuocThinh from '../../assets/image/nguyendinhphuocthinh.jpg';
import NguyenPhiVan from '../../assets/image/nguyenphivan.png';
import PreetemRai from '../../assets/image/preetamrai.jpg';
import summit from '../../assets/image/summit.png';
import theEnglish from '../../assets/image/theenglish.png';
import TheLyricist from '../../assets/image/thelyricist.png';
import TruongDoanHuynhLong from '../../assets/image/truongdoanhuynhlong.jpg';
import VuThanhTuAnh from '../../assets/image/vuthanhtuanh.jpg';
import DinhLocXuanThao from '../../assets/image/xuanthaodinhloc.jpg';
import YBoxLogo2 from '../../assets/image/YboxLogo2.png';

export default function EventPage3() {
    const { t } = useTranslation();

    useTitle(t('event.0.title'), DEFAULT_TITLE);

    return (
        <div className="bg-text-bg-500 flex flex-col">
            <div className="relative h-[160px] xs:h-[240px] sm:h-[320px] md:h-[480px] xl:h-[640px] 2xl:h-[800px] bg-page3Background bg-contain bg-opacity-100 flex flex-col bg-no-repeat">
                <Navbar />
                <p className="max-w-[800px] absolute bottom-[15px] md:bottom-[96px] 2xl:bottom-[120px] font-bold md:font-medium p-5 md:p-0 md:text-[19.2px] 2xl:text-2xl mx-[15px] md:mx-[116px] lg:mx-[200px] 2xl:mx-[250px]">
                    {t('event.0.intro')}
                </p>
            </div>
            <div className="px-[15px] md:px-[40px] lg:px-[116px] xl:px-[200px] 2xl:px-[250px]">
                <EventTimeLine numPage={3} />
                <div className="mt-[80px] md:mt-[96px] 2xl:mt-[120px]  flex">
                    <span className="font-bold text-lg md:text-[36px] 2xl:text-[40px] min-w-[120px] md:min-w-[258px] lg:min-w-[300px] xl:min-w-[440px] leading-[120%] mr-[0px]">{t('event.0.section.0.title')}</span>
                    <p className="w-full text-[14px] md:text-[19px] 2xl:text-2xl whitespace-pre-line">{t('event.0.section.0.content')}</p>
                </div>
                <div className="mt-[160px] flex">
                    <span className="font-bold text-lg md:text-[36px] 2xl:text-[40px] min-w-[120px] md:min-w-[258px] lg:min-w-[280px] xl:min-w-[440px] leading-[120%] mr-[0px]">{t('event.1.section.3.title')}</span>
                    <AgendaEventPage3 />
                </div>
                <EventCarousel name={t('event.0.section.2.title')} ID="adventureExp">
                    <CarouselElement
                        title={t('event.0.section.2.content.0.name')}
                        description={t('event.0.section.2.content.0.content')}
                        shortDescription=""
                        clickable
                        img={adventureYTVT}
                    />
                    <CarouselElement
                        title={t('event.0.section.2.content.1.name')}
                        description={t('event.0.section.2.content.1.content')}
                        shortDescription=""
                        clickable
                        img={adventureMTTH}
                    />
                    <CarouselElement
                        title={t('event.0.section.2.content.2.name')}
                        description={t('event.0.section.2.content.2.content')}
                        shortDescription=""
                        clickable
                        img={adventureCPNT}
                    />
                </EventCarousel>
                <EventCarousel name={t('event.0.section.3.title')} ID="adventureTalkEqu">
                    <CarouselElement
                        title={t('event.0.section.3.content.0.name')}
                        description={t('event.0.section.3.content.0.content')}
                        shortDescription=""
                        clickable
                        img={adventureCD}
                    />
                    <CarouselElement
                        title={t('event.0.section.3.content.1.name')}
                        description={t('event.0.section.3.content.1.content')}
                        shortDescription=""
                        clickable
                        img={adventurePQDH}
                    />
                    <CarouselElement
                        title={t('event.0.section.3.content.2.name')}
                        description={t('event.0.section.3.content.2.content')}
                        shortDescription=""
                        clickable
                        img={adventureHGCM}
                    />
                    <CarouselElement
                        title={t('event.0.section.3.content.3.name')}
                        description={t('event.0.section.3.content.3.content')}
                        shortDescription=""
                        clickable
                        img={adventureCLTH}
                    />
                    <CarouselElement
                        title={t('event.0.section.3.content.4.name')}
                        description={t('event.0.section.3.content.4.content')}
                        shortDescription=""
                        clickable
                        img={adventureKTXH}
                    />
                    <CarouselElement
                        title={t('event.0.section.3.content.5.name')}
                        description={t('event.0.section.3.content.5.content')}
                        shortDescription=""
                        clickable
                        img={adventureNTCD}
                    />
                </EventCarousel>
                <SpeakerCarousel name={t('event.0.section.4.title')} ID="guest_speaker">
                    <CarouselElement
                        title={t('event.0.section.4.content.0.name')}
                        shortDescription={t('event.0.section.4.content.0.title')}
                        session={t('event.0.section.4.content.0.session')}
                        description={t('event.0.section.4.content.0.description')}
                        clickable
                        img={BuiTranPhuong}
                    />
                    <CarouselElement
                        title={t('event.0.section.4.content.1.name')}
                        shortDescription={t('event.0.section.4.content.1.title')}
                        session={t('event.0.section.4.content.1.session')}
                        description={t('event.0.section.4.content.1.description')}
                        clickable
                        img={NamPhuong}
                    />
                    <CarouselElement
                        title={t('event.0.section.4.content.2.name')}
                        shortDescription={t('event.0.section.4.content.2.title')}
                        session={t('event.0.section.4.content.2.session')}
                        description={t('event.0.section.4.content.2.description')}
                        clickable
                        img={VuThanhTuAnh}
                    />
                    <CarouselElement
                        title={t('event.0.section.4.content.3.name')}
                        shortDescription={t('event.0.section.4.content.3.title')}
                        session={t('event.0.section.4.content.3.session')}
                        description={t('event.0.section.4.content.3.description')}
                        clickable
                        img={LeonQuangLe}
                    />
                    <CarouselElement
                        title={t('event.0.section.4.content.4.name')}
                        shortDescription={t('event.0.section.4.content.4.title')}
                        session={t('event.0.section.4.content.4.session')}
                        description={t('event.0.section.4.content.4.description')}
                        clickable
                        img={MarcusManhCuongVu}
                    />
                    <CarouselElement
                        title={t('event.0.section.4.content.5.name')}
                        shortDescription={t('event.0.section.4.content.5.title')}
                        session={t('event.0.section.4.content.5.session')}
                        description={t('event.0.section.4.content.5.description')}
                        clickable
                        img={HangMai}
                    />
                </SpeakerCarousel>
                <SpeakerCarousel name={t('event.0.section.5.title')} ID="host">
                    <CarouselElement
                        title={t('event.0.section.5.content.0.name')}
                        shortDescription={t('event.0.section.5.content.0.title')}
                        session={t('event.0.section.5.content.0.session')}
                        description={t('event.0.section.5.content.0.description')}
                        img={TruongDoanHuynhLong}
                    />
                    <CarouselElement
                        title={t('event.0.section.5.content.1.name')}
                        shortDescription={t('event.0.section.5.content.1.title')}
                        session={t('event.0.section.5.content.1.session')}
                        description={t('event.0.section.5.content.1.description')}
                        img={PreetemRai}
                    />
                    <CarouselElement
                        title={t('event.0.section.5.content.2.name')}
                        shortDescription={t('event.0.section.5.content.2.title')}
                        session={t('event.0.section.5.content.2.session')}
                        description={t('event.0.section.5.content.2.description')}
                    />
                </SpeakerCarousel>
                <SpeakerCarousel name={t('event.0.section.6.title')} ID="performer">
                    <CarouselElement
                        title={t('event.0.section.6.content.0.name')}
                        shortDescription={t('event.0.section.6.content.0.title')}
                        session={t('event.0.section.6.content.0.session')}
                        description={t('event.0.section.6.content.0.description')}
                        img={TheLyricist}
                    />
                    <CarouselElement
                        title={t('event.0.section.6.content.1.name')}
                        shortDescription={t('event.0.section.6.content.1.title')}
                        session={t('event.0.section.6.content.1.session')}
                        description={t('event.0.section.6.content.1.description')}
                        img={DinhLocXuanThao}
                    />
                    <CarouselElement
                        title={t('event.0.section.6.content.2.name')}
                        shortDescription={t('event.0.section.6.content.2.title')}
                        session={t('event.0.section.6.content.2.session')}
                        description={t('event.0.section.6.content.2.description')}
                        img={NguyenDinhPhuocThinh}
                    />
                    <CarouselElement
                        title={t('event.0.section.6.content.3.name')}
                        shortDescription={t('event.0.section.6.content.3.title')}
                        session={t('event.0.section.6.content.3.session')}
                        description={t('event.0.section.6.content.3.description')}
                        img={GermerTeam}
                    />
                    {/* <CarouselElement
                        title={t('event.0.section.6.content.4.name')}
                        shortDescription={t('event.0.section.6.content.4.title')}
                        session={t('event.0.section.6.content.4.session')}
                        description={t('event.0.section.6.content.4.description')}
                        img={NguyenPhiVan}
                    /> */}
                </SpeakerCarousel>
                <SpeakerCarousel name={t('event.0.section.7.title')} ID="ambassador">
                    <CarouselElement
                        title={t('event.0.section.7.content.0.name')}
                        shortDescription={t('event.0.section.7.content.0.title')}
                        session={t('event.0.section.7.content.0.session')}
                        description={t('event.0.section.7.content.0.description')}
                        img={NguyenPhiVan}
                    />
                </SpeakerCarousel>
                <div className="mt-[96px] 2xl:mt-[120px] flex mb-[120px]">
                    <div className="mr-[15px] md:mr-[80px] lg:mr-[100px] xl:mr-[140px]">
                        <h1 className="text-sm md:text-[36px] 2xl:text-[40px] md:leading-[64.8px] font-medium md:font-bold w-[105px] md:w-[190px] xl:w-[340px]">
                            {t('event.0.section.8.title')}
                        </h1>
                    </div>
                    <div className="w-[calc(100%-120px)] md:w-[calc(100%-270px)] lg:w-[calc(100%-290px)] xl:w-[calc(100%-480px)] h-full flex flex-col gap-5">
                        <div className="flex gap-5 flex-col 3xl:flex-row">
                            <div className="shrink-0 3xl:w-[340px] h-[416px] 3xl:h-[460px] bg-text-bg-800 rounded-[15px] p-5">
                                <div className="h-full overflow-y-hidden lg:overflow-y-scroll no-scrollbar">
                                    <div>
                                        <a href="https://www.facebook.com/tedxhcmussh/videos/807266466320746/" className="cursor-pointer font-bold text-sm md:text-2xl">{t('event.0.section.8.content.0.title')}</a>
                                        <p className="font-medium text-sm md:text-base text-[#a4a4a4] mt-[10px] md:mt-5 min-h-fit">{t('event.0.section.8.content.0.description')}</p>
                                    </div>
                                    <div className="my-10 w-full h-[5px] bg-text-bg-600 rounded-full" />
                                    <div>
                                        <a href="https://www.facebook.com/tedxhcmussh/posts/pfbid02YTv9HV3RY1tSeXXAPaEzpbG6qrjjH4RkwQBtk9uw1qSu8YrGozpSRnwYN4ZwXF8ul" className="cursor-pointer font-bold text-sm md:text-2xl">{t('event.0.section.8.content.1.title')}</a>
                                        <p className="font-medium text-sm md:text-base text-[#a4a4a4] mt-[10px] md:mt-5 min-h-fit">{t('event.0.section.8.content.1.description')}</p>
                                    </div>
                                    <div>
                                        <a href="https://www.facebook.com/tedxhcmussh/posts/pfbid0QosNqRYrNkF8gP9XvuoXVLKGct4CpLntnNtbhzxDSf65Da3rLHs3x9hgwA4YPT5fl" className="cursor-pointer font-bold text-sm md:text-2xl">{t('event.0.section.8.content.2.title')}</a>
                                        <p className="font-medium text-sm md:text-base text-[#a4a4a4] mt-[10px] md:mt-5 min-h-fit">{t('event.0.section.8.content.2.description')}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className="3xl:w-[580px] h-[512px] sm:h-[416px] 3xl:h-[460px] bg-text-bg-800 rounded-[15px] p-5 leading-[150%]"
                            >
                                <img className="cursor-pointer w-full h-[200px] 3xl:h-[256px] rounded-[15px]" src="https://picsum.photos/200/300" alt="img" />
                                <h3 className="cursor-pointer font-bold text-sm md:text-2xl mt-[10px] md:mt-5 overflow-hidden text-ellipsis">{t('event.2018event.content.0.title')}</h3>
                                <p className="font-medium text-sm md:text-base text-[#a4a4a4] mt-[10px] md:mt-5 h-[180px] md:h-[48px] 3xl:h-[72px] overflow-hidden text-ellipsis">{t('event.2018event.content.0.description')}</p>
                            </div> */}
                        </div>
                        {/* <div className="w-full flex gap-5 flex-col 3xl:flex-row">
                            <div className="3xl:w-[460px] h-[512px] sm:h-[416px] bg-text-bg-800 rounded-[15px] p-5">
                                <img className="cursor-pointer w-full h-[200px] rounded-[15px]" src="https://picsum.photos/200/300" alt="img" />
                                <h3 className="cursor-pointer font-bold text-sm md:text-2xl mt-[10px] md:mt-5">{t('event.2018event.content.0.title')}</h3>
                                <p className="font-medium text-sm md:text-base text-[#a4a4a4] mt-[10px] md:mt-5 h-[180px] md:h-[48px] overflow-hidden text-ellipsis">{t('event.2018event.content.0.description')}</p>
                            </div>
                            <div
                                className="3xl:w-[460px] h-[512px] sm:h-[416px] bg-text-bg-800 rounded-[15px] leading-[150%] p-5"
                            >
                                <img className="cursor-pointer w-full h-[200px] rounded-[15px]" src="https://picsum.photos/200/300" alt="img" />
                                <h3 className="cursor-pointer font-bold text-sm md:text-2xl mt-[10px] md:mt-5">{t('event.2018event.content.0.title')}</h3>
                                <p className="font-medium text-sm md:text-base text-[#a4a4a4] mt-[10px] md:mt-5 h-[180px] md:h-[48px] overflow-hidden text-ellipsis">{t('event.2018event.content.0.description')}</p>
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>
            <div className="slideShow bg-white xl:mt-[120px] mb-[96px] 2xl:mb-[120px] ">
                <div className="slideTrack">
                    <img className="slide w-[120px] object-contain" src={ncn} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={theEnglish} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={hoiSinhVien} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={lienChiHoi} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={amazingGroup} alt="LogoSponsor" />
                    {/* <div className="slide w-[275px]"><img className="w-[275px]" src={CanonLogo} alt="LogoSponsor" /></div> */}
                    <img className="slide w-[120px] object-contain" src={summit} alt="LogoSponsor" />
                    <img className="slide w-[180px] object-contain" src={cafeVanKhoa} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={educationMalaysia} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={myHanh} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={Edu2ReviewLogo2} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={YBoxLogo2} alt="LogoSponsor" />
                </div>
                <div className="slideTrackReplicate hidden">
                    <img className="slide w-[120px] object-contain" src={ncn} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={theEnglish} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={hoiSinhVien} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={lienChiHoi} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={amazingGroup} alt="LogoSponsor" />
                    {/* <div className="slide w-[275px]"><img className="w-[275px]" src={CanonLogo} alt="LogoSponsor" /></div> */}
                    <img className="slide w-[120px] object-contain" src={summit} alt="LogoSponsor" />
                    <img className="slide w-[180px] object-contain" src={cafeVanKhoa} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={educationMalaysia} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={myHanh} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={Edu2ReviewLogo2} alt="LogoSponsor" />
                    <img className="slide w-[120px] object-contain" src={YBoxLogo2} alt="LogoSponsor" />
                </div>
            </div>
            <Footer />
        </div>
    );
}
