import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import KoikeyaLogo from 'assets/image/koikeya_logo.png';
import NguyenTracLogo from 'assets/image/nguyentrac_logo.png';
import UACLogo from 'assets/image/uac_logo.png';
import AgendaEventPage1 from 'components/Agenda/AgendaEventPage1';
import DiscussionCarouselDesktop from 'components/EventCarousel/DiscussionCarouselDesktop';
import DiscussionCarouselMobile from 'components/EventCarousel/DiscussionCarouselMobile';
import EventTimeLine from 'components/EventTimeline';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { DEFAULT_TITLE } from 'config';
import useTitle from 'hooks/useTitle';

export default function EventPage1() {
    const {
        AdventureCarouselModal,
    } = useSelector((state) => state.carouselModal);

    const { t } = useTranslation();

    useTitle(t('event.2.title'), DEFAULT_TITLE);

    return (
        <div className="bg-text-bg-500 flex flex-col">
            <Navbar />
            <div className="px-[15px] md:px-[40px] lg:px-[116px] xl:px-[200px] 3xl:px-[250px] relative mb-[120px]">
                <EventTimeLine numPage={1} />
                <div className="mt-[80px] md:mt-[96px] 3xl:mt-[120px] flex">
                    <span className="font-bold text-lg md:text-[32px] 3xl:text-[40px] min-w-[200px] md:min-w-[240px] lg:min-w-[360px] xl:min-w-[480px] leading-[120%] mr-[10px]">{t('event.2.section.0.title')}</span>
                    <p className="w-full text-[14px] md:text-xl 3xl:text-[20px] whitespace-pre-line">{t('event.2.section.0.content')}</p>
                </div>
                <div className="mt-[80px] md:mt-[96px] 3xl:mt-[120px] flex">
                    <span className="font-bold text-lg md:text-[32px] 3xl:text-[40px] min-w-[200px] md:min-w-[258px] lg:min-w-[360px] xl:min-w-[480px] leading-[120%] mr-[10px]">{t('event.2.section.1.title')}</span>
                    <p className="w-full text-[14px] md:text-xl 3xl:text-[20px] whitespace-pre-line">{t('event.2.section.1.content')}</p>
                </div>
                <div className="mt-[80px] md:mt-[96px] 3xl:mt-[120px] flex outline outline-2 outline-epink-500 lg:outline-offset-[40px] p-[20px] lg:p-0 rounded-[15px]">
                    <span className="font-bold text-lg md:text-[32px] 3xl:text-[40px] min-w-[200px] lg:min-w-[360px] 3xl:min-w-[480px] leading-[120%] mr-[10px]">{t('event.2.section.2.title')}</span>
                    <p className="w-full text-[14px] md:text-xl 3xl:text-[20px] whitespace-pre-line">{t('event.2.section.2.content')}</p>
                </div>
                <div className="mt-[80px] md:mt-[96px] 3xl:mt-[120px] flex">
                    <span className="font-bold text-lg md:text-[32px] 3xl:text-[52px] min-w-[200px] md:min-w-[240px] lg:min-w-[360px] xl:min-w-[480px] leading-[120%] mr-[10px]">{t('event.2.section.3.title')}</span>
                    <AgendaEventPage1 />
                </div>
                <DiscussionCarouselDesktop />
                <DiscussionCarouselMobile />
            </div>
            <div className="relative bg-white flex flex-row justify-between w-100% h-[240px] items-center px-[15px] md:px-[40px] lg:px-[116px] xl:px-[200px] 3xl:px-[250px] mb-[120px]">
                <img className="h-[80px] object-contain" src={UACLogo} alt="University Access Centre Logo" />
                <img className="h-[120px] object-contain" src={KoikeyaLogo} alt="Koikeya Logo" />
                <img className="h-[120px] object-contain" src={NguyenTracLogo} alt="Nguyen Trac Logo" />
            </div>
            <Footer />
        </div>
    );
}
