import PropTypes from 'prop-types';
import React from 'react';

export default function IntroBoxMobile({
    name, description, url, image,
}) {
    return (
        <div className="w-full mt-[30px] border-2 border-text-bg-100 rounded-[15px] p-[15px] flex flex-col items-center">
            {image && <img src={url} alt="Intro" className="w-[240px] h-[240px] bg-white rounded-full" />}
            <h1 className="mt-4 text-lg font-medium">{name}</h1>
            <p className="mt-4 text-sm font-normal leading-[21px] text-text-bg-100">
                {description}
            </p>
        </div>
    );
}
IntroBoxMobile.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};
