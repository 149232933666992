/* eslint-disable max-len */
import blogBCLA from '../../../assets/image/blogBCLA.jpg';
import blogBVCN from '../../../assets/image/blogBVCN.jpg';
import blogDGGD from '../../../assets/image/blogDGGD.png';
import blogNVNT from '../../../assets/image/blogNVNT.png';
import blogQHTN from '../../../assets/image/blogQHTN.png';
import blogXHLT from '../../../assets/image/blogXHLT.png';

import blog1 from './blog/1.md';
import blog10 from './blog/10.md';
import blog11 from './blog/11.md';
import blog12 from './blog/12.md';
import blog13 from './blog/13.md';
import blog14 from './blog/14.md';
import blog15 from './blog/15.md';
import blog16 from './blog/16.md';
import blog17 from './blog/17.md';
import blog2 from './blog/2.md';
import blog3 from './blog/3.md';
import blog4 from './blog/4.md';
import blog5 from './blog/5.md';
import blog6 from './blog/6.md';
import blog7 from './blog/7.md';
import blog8 from './blog/8.md';
import blog9 from './blog/9.md';

export const VideoContent = [
    {
        genre: 'Cop Mac',
        genreId: 'copmac',
        title: 'Balance from Within: On Mindfulness',
        date: new Date('2019-02-11'),
        author: 'By TedXUSSH',
        src: 'a7--cJD7k0Q',
    },
    {
        genre: 'Cop Mac',
        genreId: 'copmac',
        title: 'Assessment in Education: When Testing is No Longer Optimal',
        date: new Date('2019-03-17'),
        author: 'By TedXUSSH',
        src: 'Pj6_UkhfRB4',
    },
    {
        genre: 'Cop Mac',
        genreId: 'copmac',
        title: 'INTRODUCING PROGRAM AMBASSADORS',
        date: new Date('2019-03-18'),
        author: 'By TedXUSSH',
        src: 'Na7wqlXcVdg',
    },
    {
        genre: 'Cop Mac',
        genreId: 'copmac',
        title: 'Who Do You Choose to Be in the Responsibility of Contributing to Society?',
        date: new Date('2019-03-31'),
        author: 'By TedXUSSH',
        src: 'vsMKk5P0vVs',
    },
    {
        genre: 'Cop Mac',
        genreId: 'copmac',
        title: 'Utopia: The knowledge that constructs an ideal society',
        date: new Date('2019-05-23'),
        author: 'By TedXUSSH',
        src: '_l079QZ7jek',
    },
];

export const BlogContent = [
    {
        genre: 'Cop Mac',
        genreId: 'copmac',
        blogNum: 1,
        blog: blog1,
        title: 'The young “resurrect” Vietnamese traditional costumes: the future lightening from the past!',
        date: new Date('2022-07-10'),
        author: 'By TEDxUSSH',
        description: 'Although efforts to research and restore ancient and traditional Vietnamese costumes have been around for a long time, it is getting into exquisite bloom, alluring a lot of participants, especially the young recently. It is the young that lightens a bright future for Vietnamese traditional costumes of our culture.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 2,
        blog: blog2,
        title: 'WILL THE NEW CODE OF CONDUCT MAKE COLLECTORS MORE ETHICAL?',
        date: new Date('2022-07-10'),
        author: 'By The Art Newspaper',
        description: 'In any industry, the things which you buy will belong to you and you can use them as you wish. If you want to decorate a new house, a car, or a washing machine with pictures, or neon lights, don’t hesitate and just do what you want. This may affect their resale value, but it cannot prevent you from buying another new house, a car, or a washing machine in the future.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 3,
        blog: blog3,
        title: '“The Joy of Getting Lost and Finding Your Way”',
        date: new Date('2022-07-10'),
        author: 'By Alex Williams',
        description: 'I’m not so unusual: I have Google Calendar to remind me where I’m supposed to be at any given hour of any given day, Google Maps to tell me how to get there, and Google notifications to pester me, so I don’t show up late.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 4,
        blog: blog4,
        title: 'JOMO - When missing out is a joy',
        date: new Date('2022-07-10'),
        author: 'By TEDxUSSH',
        description: 'After a hard-working day, between coming home and hanging out with friends, you always choose the second option. Sometimes, that choice may not come from your needs. It is just because of your fear of missing out (FOMO). Within you, there is always the thought that you will miss out on the fun, or things happening in that moment, then unintentionally separate yourself from everyone else. You chase after gossip, frequent updates in headlines, do what your friends are doing, know what your friends know... all with the hope of keeping up with the majority. Etty Hillesum once said: “The fear of missing out (FOMO) is the one that interferes with you. ',
    },
    {
        genre: 'Cop Mac',
        genreId: 'copmac',
        blogNum: 5,
        blog: blog5,
        title: 'FOMO - It is not all about negative things',
        date: new Date('2022-07-10'),
        author: 'By TEDxUSSH',
        description: 'This content is for those FOMO sapiens who are always in the state of obsessing because of FOMO. FOMO stands for “Fear of Missing Out” which means fear of getting lost or missing out any chances. People suffer from this syndrome and often fear that others will be happy and sufficient than them. Especially, FOMO commonly happens in people between the ages of 18 to 33. Furthermore, a survey conducted in Australia shows that approximately ⅔ of people at these ages admit to having gone through FOMO regularly. Despite having a lot of dark sides revolving around this syndrome, such as usually being worried, stressed, losing self-awareness about identity, or the worst thing is depression, then considering FOMO from another perspective, more “tolerant”, it also brings positive values.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 6,
        blog: blog6,
        title: '“Can seaweed solve the world\'s plastic waste crisis?”',
        date: new Date('2022-07-10'),
        author: 'By CNN',
        description: 'Notpla’s founders, Rodrigo García González and Pierre Paslie, initially looked to seaweed as the solution to the world’s plastic problem because it is abundant, grows quickly, doesn’t compete with land crops, and sequesters carbon from the air, Sibbel explained. There are also many different seaweed species, and it can be harvested or farmed.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 7,
        blog: blog7,
        title: '“How is music good for our mood?”',
        date: new Date('2022-07-10'),
        author: 'By TEDxUSSH',
        description: 'Marilyn Manson once said, “Music is the strongest miracle.” He described music through one word “Magic” Music has its power. Music has its strength and can reach the deepest place in our imagination which we call “paradise”.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 8,
        blog: blog8,
        title: 'FUTURE DESIGN: What ‘living’ clothes can do to absorb carbon emissions',
        date: new Date('2022-07-10'),
        author: 'By MATTHEW PONSFORD',
        description: 'Canadian-Iranian designer Roya Aghighi wants you to imagine that your shirt is alive. Far from dreaming up a horror movie script, Aghighi hopes we can develop a more intimate relationship with fashion – by treating clothes as living beings that need our help to survive. “You’re not going to throw your clothes in the corner of your closet or into the washing machine,” she said over the phone from Vancouver. “It’s immediately going to shift the way you think about your clothing.”',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 9,
        blog: blog9,
        title: 'In the long-term battle to protect the environment, will "sustainable fashion" win?',
        date: new Date('2022-07-10'),
        author: 'By TedXUSSH',
        description: 'In recent years, the situation of the environment is in danger and has reached an alarming level, but the majority are still indifferent and act as if they are crimeless in front of the call for help from nature. The ice in the poles keeps melting and the wildfires are constantly going on. We are always discussing this many times, but how many people do honestly stand up and take action? Fortunately, when the fashion industry has considerable concerns about this issue, they make “sustainable fashion” become the core value in their strategy in recent years.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 10,
        blog: blog10,
        title: 'Beauty behind bars: Why makeup matters for prisoners',
        date: new Date('2022-07-10'),
        author: 'By CNN',
        description: '2020 was a rough one for Joyce Pequeno, a 28-year-old inmate at Coffee Creek Correctional Facility in Wilsonville, Oregon. Social distancing was rare, she said, and prisoners were dying. Her clemency hearing was postponed. Still, most days she dabbed on foundation, swirled eyeshadow across her lids and outlined her eyes with kohl. “It makes me feel good, like a real human being – not just a number,” she said over email.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 11,
        blog: blog11,
        title: 'The art of the steal: When does a copyright go too far?',
        date: new Date('2022-07-10'),
        author: 'By TedXUSSH',
        description: 'If you want to riff on the “Mona Lisa,” go ahead. Scratch a biro mustache on her. Give her a full beard if you like. Don’t go drawing facial hair directly on the original, of course; that’s the physical property of the Louvre, and the museum’s conservators are likely to get very angry with you. But otherwise feel free to do your best or worst with Leonardo’s portrait of Lady Lisa.',
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 12,
        blog: blog12,
        title: 'CÂN BẰNG TỪ BÊN TRONG - BÀN VỀ CHÁNH NIỆM',
        date: new Date('2019-07-10'),
        author: 'By Phuong Huyen, Minh Thu',
        description: 'Chẳng ai sống ở đời mà không biết về đau khổ. 350 triệu người trên thế giới đang chịu đựng trầm cảm, WHO đã liệt kê trầm cảm vào một trong hai căn bệnh có sức tàn phá một người nặng nề nhất. Thật kỳ lạ là thay vì cố gắng chữa lành nó như những căn bệnh khác, hầu hết mọi người lại nghi ngờ và phủ nhận sự tồn tại của nó.',
        img: blogBVCN,
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 13,
        blog: blog13,
        title: 'VIEWPOINTS ABOUT THE YOUNG - THE STORY BETWEEN TRADITION AND INTEGRATION',
        date: new Date('2019-07-10'),
        author: 'By Huynh Thanh Thao Nguyen',
        description: 'Referring to the Vietnamese young generation in the era of globalization, it is not hard to find out dark sides that are more challenging than opportunity. From the government perspectives, in the “The today influence of foreign culture on Vietnamese culture” of  Communist Magazine 2013 mainly addressed negative manifestation of the young when absorbing foreign culture such as “living together before marriage”, “living hustle”, “wasteful spending”, “materialistic pursuit”, and “follow up weird trends”. Another perspective was published by Center for Theoretical and Applied Cultural Studies - HCM National University in 2015, the article “Some issues about culture of the young” dug out three specific sides “music, fashion, and life” of the young in globalization. However, it is obvious to identify negative effects and the most common access is how to resolve these changes rather than gives it an objective perspective. ',
        img: blogNVNT,
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 14,
        blog: blog14,
        title: 'WHO ARE YOU IN THE RESPONSIBITY CONTRIBUTING TO SOCIETY?',
        date: new Date('2019-07-10'),
        author: 'By Thao Nguyen Huynh Thanh',
        description: 'Human beings are always born as a part, a result created from the resources of society. Hence, we need to have responsibility in contributing to society. This responsibility often creates invisible pressure on us when the country\'s media tends to "exaggerate" the contributions of other individuals. Directing attention to those stories does not make us define what we should do within the assigned responsibility. Only when we actively open our mind, listen and experience do we allow ourselves to understand and pick up for us a suitable position to contribute. When your mind is ready, let’s start off the search.  ',
        img: blogBCLA,
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 15,
        blog: blog15,
        title: 'WHERE IS THE LIMITATION OF HUMAN’S SUBJUGATION TO NATURE?',
        date: new Date('2019-07-10'),
        author: 'By Phuong Huyen Nguyen Le',
        description: 'Life originated on this planet 4,6 billion years ago. Through many generations, nature has raised its voice from shelled seeds, like a miracle still quietly growing.Human beings were born from one of those seeds, which is ripe fruit that is born after a life sprout has blossomed. A series of physical, biological, chemical effects has taken for a long time in the core of the Earth in order to make up the existence of human beings that come from minor cells. Over the past 6 million years, we developed into primate, putting all efforts on going beyond our limit. We classify ourselves with other creatures, name ourselves as a specific case, grant ourselves for having enough responsibility and taking the right to run the great machine of life. Human beings, together with their dynamic weapon of wisdom, have escaped the harsh rule of eradication, in order to shape civilizations, and transform the world. ',
        img: blogQHTN,
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 16,
        blog: blog16,
        title: 'ASSESSMENT IN EDUCATION - WHEN EXAMINATION IS NO LONGER OPTIMAL',
        date: new Date('2019-07-10'),
        author: 'By Ngoc Quynh Nguyen',
        description: 'The process of learning often goes with assessment, even at school, or free courses, online courses. Conducting assessment is necessary for both learners, instructors and program designers. In most people’s mind, assessment mainly goes with examination, test, score, classification. In other words, assessment is tied to metric, and often causes more or less pressure for learners. In practice, this is just the tip of the iceberg of assessment. ',
        img: blogDGGD,
    },
    {
        genre: 'Collection',
        genreId: 'collection',
        blogNum: 17,
        blog: blog17,
        title: 'UTOPIA - IDEAL SOCIAL CONSTRUCTIVIST THEORIES',
        date: new Date('2019-07-10'),
        author: 'By Ngoc Quynh Nguyen',
        description: 'The 17 sustainable development goals of the United Nation. have proposed and made their attempts to achieve in 2030 reflect 17 considerable challenges that the world is facing: poverty, diseases, inequality, pollution,...These above issues are the mirror exposing our social models are under dispute these days.',
        img: blogXHLT,
    },
];
