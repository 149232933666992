import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    SHOW_WEEK1_MODAL,
    SHOW_WEEK2_MODAL,
    SHOW_WEEK3_MODAL,
    SHOW_WEEK4_MODAL,
    SHOW_WEEK5_MODAL,
    SHOW_WEEK6_MODAL,
    SHOW_WEEK7_MODAL,
    SHOW_WEEK8_MODAL,
} from 'action-types';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow_right.svg';
import FirstEvent from 'assets/image/tuan1.svg';
import SecondEvent from 'assets/image/tuan2.svg';
import ThirdEvent from 'assets/image/tuan3.svg';
import FourthEvent from 'assets/image/tuan4.svg';
import FifthEvent from 'assets/image/tuan5.svg';
import SixthEvent from 'assets/image/tuan6.svg';
import SeventhEvent from 'assets/image/tuan7.svg';
import EighthEvent from 'assets/image/tuan8.svg';
import Week1Detail from 'components/CarouselModal/Week1Modal';
import Week2Detail from 'components/CarouselModal/Week2Modal';
import Week3Detail from 'components/CarouselModal/Week3Modal';
import Week4Detail from 'components/CarouselModal/Week4Modal';
import Week5Detail from 'components/CarouselModal/Week5Modal';

export default function DiscussionCarouselDesktop() {
    const [position, setPosition] = useState('left');
    const [width, setWidth] = useState(window.innerWidth);
    const {
        Week1CarouselModal,
        Week2CarouselModal,
        Week3CarouselModal,
        Week4CarouselModal,
        Week5CarouselModal,
        Week6CarouselModal,
        Week7CarouselModal,
        Week8CarouselModal,
    } = useSelector((state) => state.carouselModal);
    const dispatch = useDispatch();
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const smallScreen = width < 1280;
    let slideDistance = 960;
    let slideInterval = 80;
    if (smallScreen) {
        slideDistance = 480;
        slideInterval = 48;
    }
    const handleScroll = (e) => {
        const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
        if (scrollLeft === 0) setPosition('left');
        else if (scrollLeft + clientWidth === scrollWidth) {
            setPosition('right');
        } else setPosition('mid');
    };
    const moveRight = () => {
        let scrollAmount = 0;
        const element = document.getElementById('carousel_element');
        const slideTimer = setInterval(() => {
            element.scrollLeft += slideInterval;
            scrollAmount += slideInterval;
            if (scrollAmount >= slideDistance) {
                window.clearInterval(slideTimer);
            }
        }, 10);
        if (element.scrollLeft + element.clientWidth === element.scrollWidth) {
            setPosition('right');
        } else setPosition('mid');
    };
    const moveLeft = () => {
        let scrollAmount = 0;
        const element = document.getElementById('carousel_element');
        const slideTimer = setInterval(() => {
            element.scrollLeft -= slideInterval;
            scrollAmount += slideInterval;
            if (scrollAmount >= slideDistance) {
                window.clearInterval(slideTimer);
            }
        }, 10);
        if (element.scrollLeft === 0) setPosition('left');
        else setPosition('mid');
    };
    return (
        <div className="hidden xl:flex mt-[80px] md:mt-[96px] 2xl:mt-[120px]">
            <Week1Detail
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK1_MODAL,
                        payload: false,
                    });
                }}
                show={Week1CarouselModal}
            />
            <Week2Detail
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK2_MODAL,
                        payload: false,
                    });
                }}
                show={Week2CarouselModal}
            />
            <Week3Detail
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK3_MODAL,
                        payload: false,
                    });
                }}
                show={Week3CarouselModal}
            />
            <Week4Detail
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK4_MODAL,
                        payload: false,
                    });
                }}
                show={Week4CarouselModal}
            />
            <Week5Detail
                title="Adventure Carousel"
                onClose={() => {
                    dispatch({
                        type: SHOW_WEEK5_MODAL,
                        payload: false,
                    });
                }}
                show={Week5CarouselModal}
            />
            <h1 className="mr-[5px] text-lg md:text-[36px] 2xl:text-[54px] font-bold min-w-[120px] md:min-w-[258px] lg:min-w-[300px] xl:min-w-[480px] leading-[120%] whitespace-pre-wrap">
                {t('event.2.section.4.title')}
            </h1>
            <div className="w-[calc(100%-360px)] relative flex items-center px-5">
                {position !== 'left' && (
                    <button type="button" className="z-10 absolute left-0 w-10 h-10 lg:w-12 lg:h-12 bg-black rounded-full border-2 border-blue-500 flex justify-center items-center" onClick={moveLeft}>
                        <LeftArrowIcon />
                    </button>
                )}
                {(position !== 'right') && (
                    <button
                        type="button"
                        className="z-10 absolute right-0 w-10 h-10 lg:w-12 lg:h-12
                    bg-black rounded-full border-2 border-blue-500 flex justify-center items-center"
                        onClick={moveRight}
                    >
                        <RightArrowIcon />
                    </button>
                )}
                <div className="h-[760px] flex overflow-x-auto no-scrollbar space-x-5" id="carousel_element" onScroll={handleScroll}>
                    <div
                        className="flex-none w-[460px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK1_MODAL,
                                payload: true,
                            });
                        }}
                    >
                        <img src={FirstEvent} alt="First Event" />
                        <div className="w-[460px] h-[148px] flex p-5 bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-center lg:self-end w-[83px] text-xl 2xl:text-2xl font-bold text-red-500">{t('event.2.section.4.content.0.week')}</p>
                            <p className="ml-[10px] w-[360px] text-xl lg:text-2xl font-bold self-center lg:self-end">{t('event.2.section.4.content.0.title')}</p>
                        </div>
                    </div>
                    <div
                        className="flex-none w-[460px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK2_MODAL,
                                payload: true,
                            });
                        }}
                    >
                        <img src={SecondEvent} alt="Second Event" />
                        <div className="w-[460px] h-[148px] flex p-5 bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-center lg:self-end w-[83px] text-xl 2xl:text-2xl font-bold text-red-500">{t('event.2.section.4.content.1.week')}</p>
                            <p className="ml-[10px] w-[360px] text-xl lg:text-2xl font-bold self-center lg:self-end">{t('event.2.section.4.content.1.title')}</p>
                        </div>
                    </div>
                    <div
                        className="flex-none w-[460px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK3_MODAL,
                                payload: true,
                            });
                        }}
                    >
                        <img src={ThirdEvent} alt="Third Event" />
                        <div className="w-[460px] h-[148px] flex p-5 bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-center lg:self-end w-[83px] text-xl 2xl:text-2xl font-bold text-red-500">{t('event.2.section.4.content.2.week')}</p>
                            <p className="ml-[10px] w-[360px] text-xl lg:text-2xl font-bold self-center lg:self-end">{t('event.2.section.4.content.2.title')}</p>
                        </div>
                    </div>
                    <div
                        className="flex-none w-[460px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK4_MODAL,
                                payload: true,
                            });
                        }}
                    >
                        <img src={FourthEvent} alt="Fourth Event" />
                        <div className="w-[460px] h-[148px] flex p-5 bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-center lg:self-end w-[83px] text-xl 2xl:text-2xl font-bold text-red-500">{t('event.2.section.4.content.3.week')}</p>
                            <p className="ml-[10px] w-[360px] text-xl lg:text-2xl font-bold self-center lg:self-end">{t('event.2.section.4.content.3.title')}</p>
                        </div>
                    </div>
                    <div
                        className="flex-none w-[460px] rounded-[15px] relative cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                            dispatch({
                                type: SHOW_WEEK5_MODAL,
                                payload: true,
                            });
                        }}
                    >
                        <img src={FifthEvent} alt="Fifth Event" className="object-cover" />
                        <div className="w-[460px] h-[148px] flex p-5 bg-black absolute bottom-0 border-yellow-500 border-2 rounded-b-[20px]">
                            <p className="self-center lg:self-end w-[83px] text-xl 2xl:text-2xl font-bold text-red-500">{t('event.2.section.4.content.4.week')}</p>
                            <p className="ml-[10px] w-[360px] text-xl lg:text-2xl font-bold self-center lg:self-end">{t('event.2.section.4.content.4.title')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
