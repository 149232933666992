import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AgendaEventPage3() {
    const { t } = useTranslation();

    return (
        <div className="leading-[150%] text-[14px] md:text-[15px] 2xl:text-[16px] grid grid-cols-[112px_5px_5px_minmax(0px,_1fr)] grid-rows-8 gap-y-8 2xl:gap-y-10">
            <span className="h-[76px]">{t('event.0.section.1.content.0.time')}</span>
            <div className="row-span-full col-start-2 h-full w-[5px] bg-epink-500 rounded-full" />
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3 whitespace-pre-wrap ">{t('event.0.section.1.content.0.name')}</div>
            <span className="h-[76px]">{t('event.0.section.1.content.1.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3 whitespace-pre-wrap">{t('event.0.section.1.content.1.name')}</div>
            <span className="h-[76px]">{t('event.0.section.1.content.2.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3 whitespace-pre-wrap">{t('event.0.section.1.content.2.name')}</div>
            <span className="h-[76px]">{t('event.0.section.1.content.3.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3 whitespace-pre-wrap">{t('event.0.section.1.content.3.name')}</div>
            <span className="h-[76px]">{t('event.0.section.1.content.4.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3 whitespace-pre-wrap">{t('event.0.section.1.content.4.name')}</div>
            <span className="h-[76px]">{t('event.0.section.1.content.5.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3 whitespace-pre-wrap">{t('event.0.section.1.content.5.name')}</div>
            <span className="h-[76px]">{t('event.0.section.1.content.6.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3 whitespace-pre-wrap">{t('event.0.section.1.content.6.name')}</div>
            <span className="h-[76px]">{t('event.0.section.1.content.7.time')}</span>
            <div className="inline-block relative top-6 w-[5px] h-[5px] bg-epink-500 rounded-r-full" />
            <div className="ml-3 whitespace-pre-wrap">{t('event.0.section.1.content.7.name')}</div>
        </div>

    );
}
