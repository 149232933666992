import React from 'react';
import { useTranslation } from 'react-i18next';

// import Connect from 'assets/image/connect.png';
import BuiThiThuyDoan from 'assets/image/BuiThiThuyDoan.jpg';
import TuyetNhi from 'assets/image/duong_doan_tuyet_nhi.JPG';
// import KhaiPhong from 'assets/image/khai_phong.png';
import LeTuAnh from 'assets/image/le_tu_anh.jpg';
import TranDucTri from 'assets/image/tran_duc_tri.png';
// import VisionImg from 'assets/image/vision.png';
import Footer from 'components/Footer';
import Host from 'components/HostIntroduction/Host';
import Navbar from 'components/Navbar';

import HomepageEvent from './HomepageEvent';
import HomepagePodcast from './HomepagePodcast';
import HomepageVideoMobile from './HomepageVideoMobile';
import IntroBoxMobile from './IntroBoxMobile';
import MainIntroMobile from './MainIntroMobile';

export default function HomepageMobile() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col bg-text-bg-500">
            <Navbar />
            <MainIntroMobile />
            <div className="px-[15px] mt-[76px]">
                <h1 className="text-xl font-bold leading-[30px]">{t('title.introduction')}</h1>
                <Host />
            </div>
            <div className="px-[15px] mt-[60px]">
                <h1 className="text-xl font-bold leading-[30px]">{t('title.vision')}</h1>
                <IntroBoxMobile name={t('home.2.0.title')} url="" description={t('home.2.0.content')} image={false} />
                <IntroBoxMobile name={t('home.2.1.title')} url="" description={t('home.2.1.content')} image={false} />
                <IntroBoxMobile name={t('home.2.2.title')} url="" description={t('home.2.2.content')} image={false} />
            </div>
            <HomepageEvent />
            <HomepageVideoMobile />
            <HomepagePodcast />
            <div className="px-[15px] my-[60px]">
                <h1 className="text-xl font-bold leading-[30px]">{t('title.speaker')}</h1>
                <IntroBoxMobile name={t('home.5.0.name')} url={LeTuAnh} description={t('home.5.0.speech')} image />
                <IntroBoxMobile name={t('home.5.1.name')} url={TranDucTri} description={t('home.5.1.speech')} image />
                <IntroBoxMobile name={t('home.5.2.name')} url={TuyetNhi} description={t('home.5.2.speech')} image />
                <IntroBoxMobile name={t('home.5.3.name')} url={BuiThiThuyDoan} description={t('home.5.3.speech')} image />
            </div>
            <Footer />
        </div>
    );
}
