import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { SHOW_SPEAKER_CAROUSEL } from 'action-types';
import Tmp from 'assets/image/tmp.png';

export default function CarouselElement({
    title, shortDescription, session, img, last, click, openModal, clickable, ...props
}) {
    const dispatch = useDispatch();
    return (
        <div
            className={`flex-none w-[150px] md:w-[300px] xl:w-[340px] h-full bg-text-bg-50 p-3 md:p-5
            rounded-[15px] flex flex-col cursor-pointer ${last ? '' : 'mr-[15px] md:mr-5'} overflow-y-hidden`}
            aria-hidden="true"
            onClick={() => {
                if (clickable) {
                    openModal();
                    click();
                }
            }}
        >
            <img alt="tmp" src={img || Tmp} className="min-h-[100px] max-h-[100px] md:min-h-[312px] md:max-h-[312px] bg-white rounded-[15px] object-cover object-top" />
            <h1 className="mt-[10px] md:mt-5 text-xs md:text-xl font-bold text-black">{title}</h1>
            <p className="md:mt-[10px] text-[10px] md:text-base font-medium text-text-bg-700 truncate">{shortDescription}</p>
            <p className="md:mt-[10px] text-[10px] md:text-base font-medium text-text-bg-700 truncate">{session}</p>
        </div>
    );
}
CarouselElement.propTypes = {
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    last: PropTypes.bool,
    clickable: PropTypes.bool,
};
CarouselElement.defaultProps = {
    last: false,
    clickable: false,
};
